import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
  buildOutrosAviamentos,
  buildOutrosTecidos,
} from '../../../template/UseGetMateriais';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import AjusteNecessidadeMPQuantidadeStep from './AjusteNecessidadeMPQuantidadeStep';

export const AjuesteNecessidadeMP: TipoDivergenciaComponent = ({ onSetStep, onBack }) => {
  const { loading, materiais } = useGetMateriais();
  const newMateriais = [
    ...materiais,
    buildOutrosTecidos(),
    buildOutrosAviamentos(),
  ];

  const stepNumber = '2';
  const stepSubTitle = 'Selecione o material.';
  const stepTitle = 'Qual material será ajustado? 1';
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={AjusteNecessidadeMPQuantidadeStep as TipoDivergenciaComponent}
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey='materiais'
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};
