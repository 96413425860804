import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
} from '@material-ui/core';
import fileSize from 'filesize.js';
import React, { FunctionComponent } from 'react';
import { CustomFile } from './ChatInput';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    fileName: {
      maxWidth: '70%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
);

const ChatFileList: FunctionComponent<{
  files: Array<CustomFile>;
  onRemoveFile: (fileIndex: number) => void;
}> = ({ files, onRemoveFile }) => {
  const classes = useStyles();

  return (
    <div>
      <Divider></Divider>
      <div className={`${classes.container} caption medium neutral500 mt16`}>
        <span>Arquivos anexados</span>
        <span>{files.length} itens</span>
      </div>
      <div className='mt16'>
        {files.map((file, index) => (
          <div key={`file-${index}`} className='d-flex align-item-center'>
            <span className={`${classes.fileName} body2 medium neutral700`}>
              {file.file.name}
            </span>
            <span className='body2 medium neutral300 ml8'>
              {fileSize(file.file.size, 1, 'si')}
            </span>
            <Button
              className='mlAuto overline bold primary500'
              disabled={file.loading}
              onClick={() => {
                onRemoveFile(index);
              }}
            >
              {file.loading ? (
                <span>
                  <CircularProgress color='inherit' size={15} />
                </span>
              ) : (
                'REMOVER'
              )}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatFileList;
