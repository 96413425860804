import { Menu, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { useLoggedUser } from '../../UseLoggedUser';
import TopbarMenuButton from './TopbarMenuButton';
import TopbarMenuItem from './TopbarMenuItem';

const TopbarUserMenu: FunctionComponent<{ urlPlm: string }> = ({ urlPlm }) => {
  const { loading, data } = useLoggedUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {loading ? (
        <Skeleton width={125} height={32}></Skeleton>
      ) : (
        <TopbarMenuButton
          handleClick={handleClick}
          label={data?.getLoggedUser.login || ''}
        ></TopbarMenuButton>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            window.open(`${urlPlm}/#/perfil`);
          }}
        >
          <TopbarMenuItem label='Perfil'></TopbarMenuItem>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            localStorage.removeItem('auth');
            localStorage.removeItem('user');
            window.location.assign('/login');
          }}
        >
          <TopbarMenuItem label='Sair'></TopbarMenuItem>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TopbarUserMenu;
