import { gql, useMutation } from '@apollo/client';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { default as React, FunctionComponent } from 'react';
import { loaderTheme } from '../../../support/Utils';
import { EquipeType } from '../tabs/Equipes';
import EquipeUserRow from './EquipeUserRow';
import NewEquipeUser from './NewEquipeUser';

const deleteMutation = gql`
  mutation deleteEquipe($idEquipe: Int!) {
    deleteOnePLM_Colaborativo_Equipe(where: { id_equipe: $idEquipe }) {
      id_equipe
    }
  }
`;

const EquipeRow: FunctionComponent<{
  equipe: EquipeType;
  onUpdate: Function;
}> = ({ equipe, onUpdate }) => {
  const [deleteEquipe, { loading: loadingDelete }] = useMutation(
    deleteMutation
  );
  const disableDelete =
    loadingDelete || equipe.PLM_Colaborativo_Usuario_Equipe.length >= 1;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
      >
        <Typography>{equipe.nome_equipe}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          {equipe.PLM_Colaborativo_Usuario_Equipe.map((user) => {
            return (
              <EquipeUserRow
                equipeUser={user}
                onUpdate={() => {
                  onUpdate();
                }}
              ></EquipeUserRow>
            );
          })}
          <div style={{ width: '100%' }}>
            <NewEquipeUser
              equipeId={equipe.id_equipe}
              onUpdate={() => {
                onUpdate();
              }}
            ></NewEquipeUser>
          </div>
        </div>
      </AccordionDetails>
      <Divider></Divider>
      <AccordionActions>
        <Tooltip
          title={
            disableDelete ? 'Remova os usuários antes de deletar a equipe.' : ''
          }
          placement='right'
          arrow
        >
          <span>
            <Button
              size='small'
              color='default'
              disabled={disableDelete}
              onClick={() => {
                deleteEquipe({
                  variables: {
                    idEquipe: equipe.id_equipe,
                  },
                }).then(() => {
                  onUpdate();
                });
              }}
            >
              {loadingDelete ? (
                <ThemeProvider theme={loaderTheme}>
                  <CircularProgress
                    size={25}
                    color='primary'
                  ></CircularProgress>
                </ThemeProvider>
              ) : (
                'Excluir'
              )}
            </Button>
          </span>
        </Tooltip>
      </AccordionActions>
    </Accordion>
  );
};
export default EquipeRow;
