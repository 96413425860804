import { useQuery } from '@apollo/client';
import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { gql } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useQueryString } from '../support/Utils';

const authQueryStringKey = 'jwt_token';
const idPpopQueryStringKey = 'id_producao_ppp_op';

const query = gql`
  query getPPOPAndProgProdIds($idPpop: Float!) {
    findOneProducao_PPP_OP(where: { id_producao_ppp_op: $idPpop }) {
      id_producao_ppp_op
      id_producao_prog_prod
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 99999,
      color: '#fff',
    },
  })
);

const Home: FunctionComponent = () => {
  const classes = useStyles();
  const queryString = useQueryString();
  const showUsers = queryString.get('showUsers');
  const auth = queryString.get(authQueryStringKey);
  const idPpop = Number(queryString.get(idPpopQueryStringKey));

  const { loading, data, error } = useQuery<{
    findOneProducao_PPP_OP: {
      id_producao_ppp_op: string;
      id_producao_prog_prod: string;
    };
  }>(query, {
    skip: !idPpop,
    variables: {
      idPpop,
    },
  });

  if (showUsers) {
    return <ShowUsers></ShowUsers>;
  }

  if (auth) {
    localStorage.setItem('auth', atob(auth));
    window.location.replace(
      idPpop ? `/?${idPpopQueryStringKey}=${idPpop}` : '/'
    );
    return <div></div>;
  }

  if (error?.message === 'Unauthorized' && !showUsers) {
    return (
      <Backdrop className={classes.backdrop} open>
        <Typography variant='h4'>Sem autorização</Typography>
      </Backdrop>
    );
  }

  if (!idPpop && !showUsers) {
    return redirectToProductList();
  }

  if (data) {
    const product = data.findOneProducao_PPP_OP;

    if (!product) {
      return redirectToProductList();
    }

    return (
      <Redirect
        to={`/produtos/${product.id_producao_prog_prod}/${product.id_producao_ppp_op}/divergencias`}
      ></Redirect>
    );
  }
  if (!showUsers) {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return <ShowUsers></ShowUsers>;
};

export default Home;
function redirectToProductList(): React.ReactElement<any, any> | null {
  return <Redirect to='/produtos'></Redirect>;
}

const ShowUsers: FunctionComponent = () => (
  <div>
    <h1>Olá mundo</h1>
    <ul>
      <li>
        <Link to='/produtos'>Lista de produtos</Link>
      </li>
      <li>
        <Link to='/produtos/103919577/59839505/divergencias'>Produto 1</Link>
      </li>
    </ul>
    <div>
      <Button
        onClick={() => {
          localStorage.setItem(
            'auth',
            'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c3VhcmlvIjozNjg2LCJpZF9tYXJjYV9ncnVwbyI6bnVsbCwibG9naW4iOiJ1ZHMuY2FybG9zLnAiLCJpZF9tb2RlbGlzdGEiOm51bGwsImFkbWluIjoxLCJzdXBlcl91c2VyIjowLCJhdGFjYWRvIjpudWxsLCJlbWFpbCI6ImNhcmxvcy5wQHVkcy5jb20uYnIiLCJpZF9hc3Npc3RlbnRlX2Z0IjpudWxsLCJpZF9hc3Npc3RlbnRlX2NhZCI6OSwiYXNzaXN0ZW50ZV9wY3AiOm51bGwsImlkX2dydXBvIjpudWxsLCJpZF9lc3RpbGlzdGEiOm51bGwsImlkX21hcmNhX2VzdGlsbyI6bnVsbCwic2hvcHBpbmdfY3JlZGl0cyI6bnVsbCwiZGF0YV9jcmlhY2FvIjpudWxsLCJ1bHRpbWFfYWx0ZXJhY2FvIjoiMjAyMC0wNy0xNlQxNDoxNjo0MS4wMDBaIiwiZGV2aWNlX3Rva2VuIjpudWxsLCJjb21wcmFkb3JhIjoiQklBTkNBLkFORE9MUEhJIiwiY2FkX2V4dGVybm8iOm51bGwsInRvZG9zX29zX2VuY2FpeGVzIjpudWxsLCJtYXJjYXNfbWFyY2FfZ3J1cG8iOm51bGwsImFyZWFzIjp7fSwibWFyY2FzIjpbNyw1LDM2MTE1Myw2LDgsMzYxMTUxLDE4MjAsMTIsMzYxMTUyLDM2MTE0MSwxMywzNjExNDAsOTQ2OCw5NDcyLDM2MTE1NSwzNjExNTddLCJpZF9ldGlxdWV0YXMiOltdfQ.o-opIIW9dYJIEjsi7gkiSCzTDiPgvtUj-XdOiVQRABI'
          );
          window.location.reload();
        }}
      >
        User carlos
      </Button>
      <Button
        onClick={() => {
          localStorage.setItem(
            'auth',
            'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c3VhcmlvIjozOTY3LCJpZF9tYXJjYV9ncnVwbyI6bnVsbCwibG9naW4iOiJmb3JuZWNlZG9yLnVkcyIsImlkX21vZGVsaXN0YSI6bnVsbCwiYWRtaW4iOm51bGwsInN1cGVyX3VzZXIiOjAsImF0YWNhZG8iOm51bGwsImVtYWlsIjoiY2FybG9zLmZpb3JpQHlhaG9vLmNvbSIsImlkX2Fzc2lzdGVudGVfZnQiOm51bGwsImlkX2Fzc2lzdGVudGVfY2FkIjpudWxsLCJhc3Npc3RlbnRlX3BjcCI6bnVsbCwiaWRfZ3J1cG8iOm51bGwsImlkX2VzdGlsaXN0YSI6bnVsbCwiaWRfbWFyY2FfZXN0aWxvIjpudWxsLCJzaG9wcGluZ19jcmVkaXRzIjpudWxsLCJkYXRhX2NyaWFjYW8iOm51bGwsInVsdGltYV9hbHRlcmFjYW8iOm51bGwsImRldmljZV90b2tlbiI6bnVsbCwiY29tcHJhZG9yYSI6bnVsbCwiY2FkX2V4dGVybm8iOm51bGwsInRvZG9zX29zX2VuY2FpeGVzIjpudWxsLCJtYXJjYXNfbWFyY2FfZ3J1cG8iOm51bGwsImFyZWFzIjp7fSwibWFyY2FzIjpbNyw1LDM2MTE1Myw2LDgsMzYxMTUxLDE4MjAsMTIsMzYxMTUyLDM2MTE0MSwxMywzNjExNDAsOTQ2OCw5NDcyLDM2MTE1NSwzNjExNTddLCJpZF9ldGlxdWV0YXMiOltdfQ.CC2jwvL2r2fG8-Bxu8UC4bc5KnEAb3oq6HghcBkXjwA'
          );
          window.location.reload();
        }}
      >
        User fornecedor
      </Button>
    </div>
  </div>
);
