import { Button, Divider } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { DivergenciaInfo } from "../DivergenciaDetailTypes";
import MaterialInfoComponent from "./MaterialInfoComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
    },
  })
);

const MateriaisPopover: FunctionComponent<{ materiais: DivergenciaInfo[] }> = ({
  materiais,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        className="backgroundPrimary500"
        onClick={handlePopoverOpen}
      >
        <span className="text-transform-initial neutral000 bold">
          Ver materiais
        </span>
      </Button>
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id="mouse-over-popover"
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {
          materiais?.map((material, index) => {
            return (
              <div key={`materialinfo-${index}`} style={{ minHeight: "59px" }}>
                <MaterialInfoComponent infos={[material]}></MaterialInfoComponent>
                <Divider className="mb8 mt8"></Divider>
              </div>
            );
          })}
      </Popover>
    </div>
  );
};

export default MateriaisPopover;
