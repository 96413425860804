import { Button } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { FunctionComponent, MouseEventHandler } from 'react';

const TopbarMenuButton: FunctionComponent<{
  handleClick: MouseEventHandler;
  label: string;
  showIcon?: boolean;
}> = ({ handleClick, label, showIcon = true }) => (
  <Button
    aria-controls='simple-menu'
    aria-haspopup='true'
    className='body2 semi-bold neutral500'
    onClick={handleClick}
  >
    <div className='d-flex align-item-center'>
      <span className='text-transform-initial'>{label}</span>
      {showIcon && (
        <KeyboardArrowDownIcon
          className='iconNeutral300'
          style={{ width: '20px' }}
        ></KeyboardArrowDownIcon>
      )}
    </div>
  </Button>
);

export default TopbarMenuButton;
