import React from 'react';
import CopyIcon from '../../../assets/icons/copy.svg';

type copyToClipboardProps = {
  value: string;
};

export default class extends React.Component<copyToClipboardProps> {
  constructor(props: copyToClipboardProps) {
    super(props);

    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard() {
    var textArea = document.createElement('textarea');
    textArea.value = this.props.value;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  render() {
    return (
      <img
        className='pointer'
        src={CopyIcon}
        alt='copy'
        onClick={this.copyToClipboard}
      />
    );
  }
}
