import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import ContinueOrBackActions from '../../../components/ContinueOrBackActions';
import NovaDivergenciaProgressBar from '../../../components/NovaDivergenciaProgressBar';
import StepperHeader from '../../../components/StepperHeader';
import {
  TipoDivergenciaComponent
} from '../../../TiposDeDivergencias';
import CancelamentoOPFaltaMPFinalStep from './CancelamentoOPFaltaMPFinalStep';
import LabelValueBase from '../../../components/LabelValueBase';
import { CancelamentoOPFaltaMP } from './CancelamentoOPFaltaMP'
import { PayloadMateriaisProblemProps } from '../substituicao-mp/SubstituicaoMPObservacaoStep';
import {
  DivergenceContext,
  DivergenceContextType
} from '../../../../../../../context'

export const CancelamentoOPFaltaMPObservacaoStep: TipoDivergenciaComponent<PayloadMateriaisProblemProps> = ({
  onSetStep,
  onBack,
  payload
}) => {
  const [problemDescription, setProblemDescription] = useState('');
  const { setAviamentos } = useContext<DivergenceContextType>(DivergenceContext)

  const isDisabledButton = () => !problemDescription;
  const handleContinue = () => {
    const nextPayload = {
      problemDescription: problemDescription,
      materiais: payload.materiais,
    };
    setAviamentos(null);
    setAviamentos({ aviamentos: nextPayload.materiais });
    onSetStep({
      component: CancelamentoOPFaltaMPFinalStep as TipoDivergenciaComponent,
      payload: nextPayload,
    });
  };

  const onBackHandler = () => {
    onBack({
      component: CancelamentoOPFaltaMP,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProblemDescription(event.target.value);
  };

  const stepNumber = '3';
  const stepSubTitle = 'Digite abaixo o motivo do cancelamento';
  const stepTitle = 'Qual é o motido do cancelamento?';

  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={66}></NovaDivergenciaProgressBar>
      <DialogContent
        className='backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth'
        style={{
          padding: '0 32px',
        }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div className='px16'>
          <form
            autoComplete='off'
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}>
            <TextField
              fullWidth
              type='text'
              name='text'
              multiline
              rows={4}
              onChange={handleChange}
              placeholder='Digite seu texto aqui...'
              variant='outlined'
            />
          </form>
          <div className='mt32'>
            <LabelValueBase label='Material selecionado'>
              <div>
                <span className='mt8 body2 semi-bold neutral700'>
                  {payload.materiais.map((item) => item.text).join(', ')}
                </span>
                <Button onClick={onBackHandler}>
                  <span className='caption bold neutral300'>ALTERAR</span>
                </Button>
              </div>
            </LabelValueBase>
          </div>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className='divergenciaMaxWidth'
        style={{ padding: '30px 32px' }}
      >
        <ContinueOrBackActions
          continueMessage='Continuar'
          disabled={isDisabledButton()}
          disabledMessage='Continuar'
          onBack={onBack}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  )

};
