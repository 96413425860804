import { Box, ThemeProvider } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import PaginationEnum from '../enums/PaginationEnum';
import { loaderTheme, useQueryString } from '../Utils';

const PaginationDefault: FunctionComponent<{
  totalRows: number;
  pushToPathname: string;
}> = ({ totalRows = 0, pushToPathname }) => {
  let history = useHistory();
  let queryString = useQueryString();
  let page = parseInt(queryString.get('page') || '1', 10);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    queryString.set('page', value.toString());
    history.push({
      pathname: pushToPathname,
      search: queryString.toString(),
    });
  };
  return (
    <Box display='flex' justifyContent='center' width='100%' marginTop={5}>
      <ThemeProvider theme={loaderTheme}>
        <Pagination
          count={Math.ceil(totalRows / PaginationEnum.perPage)}
          shape='rounded'
          color='primary'
          page={page}
          onChange={handleChange}
        />
      </ThemeProvider>
    </Box>
  );
};

export default PaginationDefault;
