import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { loader } from "graphql.macro";
import React, { FunctionComponent, useRef, useState } from "react";
import { ReactComponent as Attachment } from "../../../../../../assets/icons/attachment.svg";
import { ReactComponent as Email } from "../../../../../../assets/icons/email.svg";
import Mentions from "../../../../../components/Mentions";
import IconFileInput from "../../../../../support/components/inputs/IconFileInput";
import { buildUserName } from "../../../../../support/Utils";
import ChatFileList from "./ChatFileList";

const hasAttachment = true;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      alignItems: "center",
      background: "white",
      border: "2px solid #F4F3F3",
      borderRadius: "4px",
      display: "flex",
      padding: "16px 24px",
      width: "calc(100% - 12)",
    },
    focusContainer: {
      background: "white",
      border: "2px solid white",
      borderRadius: "4px",
      boxShadow: "0px 16px 24px -8px rgba(0, 0, 0, 0.08)",
      boxSizing: "border-box",
      maxWidth: "584px",
      padding: "16px 24px",
    },
    focusActions: {
      alignItems: "center",
      display: "flex",
    },
  })
);

const InitialComponent: FunctionComponent<{ onClick: Function }> = ({
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.container} body2 semi-bold neutral300 pointer`}
      onClick={() => onClick()}
    >
      <span className="flexGrow1">Escreva aqui seu comentário...</span>
      {hasAttachment && <Attachment className="iconNeutral800"></Attachment>}
      <Email className="ml22 iconNeutral800"></Email>
    </div>
  );
};

const mutation = loader("../../../../../support/queries/UploadFile.gql");

const queryMentions = loader(
  "../../../../../support/queries/GetUserForMentionsOnlyActive.gql"
);

export type CustomFile = {
  file: File;
  loading: boolean;
  fileId?: string;
};

const Input: FunctionComponent<{
  showSaveButton: boolean;
  onSaveMessage: (message: string, files: Array<CustomFile>) => void;
  loading?: boolean;
}> = ({ onSaveMessage, showSaveButton = false, loading = false }) => {
  const classes = useStyles();
  const [save] = useMutation<{
    uploadFile: {
      filename: string;
    };
  }>(mutation);
  const [value, setValue] = useState("");
  const [files, setFiles] = useState<CustomFile[]>([]);
  const [listKey, setListKey] = useState<number>(0);

  const hasFileLoading = !!files.filter((file) => file.loading).length;
  const isLoading = loading || hasFileLoading;

  const handleNewFiles = (newFiles: Array<File>) => {
    const newCustomFiles = newFiles.map(
      (item) =>
        ({
          file: item,
          loading: true,
        } as CustomFile)
    );

    const allFiles = [...files, ...newCustomFiles];
    setFiles(allFiles);

    newCustomFiles.forEach((file) => {
      const variables = { variables: { file: file.file } };

      save(variables)
        .then(({ data }) => {
          file.loading = false;
          file.fileId = data?.uploadFile.filename;
          setListKey(Math.random());
          setFiles(allFiles);
          if (!showSaveButton) {
            onSaveMessage(value, allFiles);
          }
        })
        .catch((error) => console.error(error));
    });
  };

  const removeFile = (fileIndex: number) => {
    setFiles(files.filter((_, index) => fileIndex !== index));
    if (!showSaveButton) {
      onSaveMessage(value, files);
    }
  };

  const inputRef = useRef<HTMLTextAreaElement>();

  const onType = (newValue: string) => {
    setValue(newValue);
    if (!showSaveButton) {
      onSaveMessage(newValue, files);
    }
  };

  const { data: dataMentions = { findManyUsuario: [] } } = useQuery<{
    findManyUsuario: { id_usuario: number; login: string; inativo: number }[];
  }>(queryMentions);
  const disableButton = value === "" && files.length === 0;
  return (
    <div className={classes.focusContainer}>
      <Mentions
        autoFocus
        data={dataMentions.findManyUsuario.map((item) => ({
          id: item.login,
          display: buildUserName(item.login),
        }))}
        inputRef={inputRef}
        setValue={onType}
        value={value}
      ></Mentions>
      {Boolean(files.length) && (
        <ChatFileList
          key={`listKey${listKey}`}
          files={files}
          onRemoveFile={removeFile}
        ></ChatFileList>
      )}
      <div
        className={`${classes.focusActions} d-flex align-item-center body2 semi-bold neutral300 mt16`}
      >
        {showSaveButton && (
          <Button
            className="backgroundPrimary500"
            style={{ padding: "8px 16px" }}
            disabled={disableButton}
            onClick={() => {
              if (!isLoading) {
                onSaveMessage(value, files);
              }
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit"></CircularProgress>
            ) : (
              <span className="text-transform-initial neutral000 bold">
                Salvar
              </span>
            )}
          </Button>
        )}
        {hasAttachment && (
          <IconFileInput onNewFiles={handleNewFiles}></IconFileInput>
        )}
        <Email
          className="ml22 iconNeutral800 pointer "
          onClick={() => {
            setValue(`${value} @`);
            inputRef.current?.focus();
          }}
        />
      </div>
    </div>
  );
};

const ChatInput: FunctionComponent<{
  showSaveButton?: boolean;
  onSaveMessage: (message: string, files: Array<CustomFile>) => void;
  loading?: boolean;
}> = ({ onSaveMessage, showSaveButton = false, loading = false }) => {
  const [hasFocus, setHasFocus] = useState(false);
  const handleClick = () => {
    setHasFocus(!hasFocus);
  };

  return (
    <div style={{ width: "100%" }}>
      {!hasFocus ? (
        <InitialComponent onClick={handleClick}></InitialComponent>
      ) : (
        <Input
          onSaveMessage={onSaveMessage}
          showSaveButton={showSaveButton}
          loading={loading}
        ></Input>
      )}
    </div>
  );
};

export default ChatInput;
