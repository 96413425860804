import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { ReactComponent as DoneGreen } from '../../../../../assets/icons/done-green.svg';
import { buildDivergenciaNumber } from '../../../../support/Utils';
import NovaDivergenciaProgressBar from './components/NovaDivergenciaProgressBar';
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from './TiposDeDivergencias';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '672px',
    },
  })
);

interface DivergenciaRegistradaProps extends TipoDivergenciaComponentProps {
  payload: { idDivergencia: number };
}

const DivergenciaRegistrada: TipoDivergenciaComponent<DivergenciaRegistradaProps> = ({
  onClose,
  payload,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={100}></NovaDivergenciaProgressBar>
      <DialogContent
        className={`${classes.container} backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth`}
        style={{ padding: '32px' }}
      >
        <div className='flexGrow1'></div>
        <DoneGreen></DoneGreen>
        <div className='h6 headline medium primary500 mt32'>
          Divergência registrada com sucesso!
        </div>
        <div
          className='body2 semi-bold neutral500 text-center mt24'
          style={{ padding: '0 50px' }}
        >
          O colaborador responsavel foi notificado.Você também será notificado
          por e-mail assim que uma resposta for publicada.
        </div>
        <div className='flexGrow1'></div>
        <div
          className='caption bold neutral500'
          style={{ marginBottom: '56px' }}
        >
          {buildDivergenciaNumber(payload.idDivergencia)}
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions style={{ padding: '30px 36px' }}>
        <Button
          className='backgroundPrimary500'
          fullWidth
          style={{ padding: '16px', height: '60px' }}
          onClick={() => {
            onClose();
          }}
        >
          <span className='text-transform-initial neutral000 bold'>OK</span>
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
export default DivergenciaRegistrada;
