import React from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import LabelValueComponent from '../../../components/LabelValueComponent';
import { SelectItem } from '../../../components/SelectStep';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from '../../../TiposDeDivergencias';
import FaltaPecasLarguraStep from './FaltaPecasLarguraStep';


interface FaltaPecasProps extends TipoDivergenciaComponentProps {
  payload: { tecidos: SelectItem[]; largura: string };
}

const FaltaPecasFinalStep: TipoDivergenciaComponent<FaltaPecasProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = () => {
    onBack({
      component: FaltaPecasLarguraStep as TipoDivergenciaComponent,
      payload: {
        tecidos: payload.tecidos,
      },
    });
  };

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.CORTE,
      'Falta de peças'
    ),
    <LabelValueComponent
      label='Tecido'
      value={payload.tecidos.map((item) => item.text).join(', ')}
    />,
    <LabelValueComponent
      label='Quantidade de peças pendentes'
      value={`${payload.largura}`}
    />,
  ];

  const key = TipoDivergenciasKey.FALTA_DE_PECAS;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(step, [
          ...payload.tecidos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.TECIDO,
            value: item.id.toString(),
          })),
          {
            tipo: TipoDivergenciasInfoKey.LARGURA_TECIDO,
            value: payload.largura,
          },
        ]);
      }}
      progressValue={90}
      stepNumber={'4'}
      infoComponents={infoComponents}
    >
    </FinalStepTemplate>
  );
};

export default FaltaPecasFinalStep;
