import { useEffect } from 'react';
import {
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import LoadingConfig from "../components/LoadingConfig";
import { TicketItem } from '../components/TicketItem';
import { SearchTypeTicket } from '../components/SearchTypeTicket';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
  customBox: {
    backgroundColor: '#F9F9F9',
    borderRadius: '16px',
    padding: '16px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
});

const GET_DIVERGENCE_TYPE = gql`
  query getDivergenceType($tipo: String!, $isZendesk: Boolean) {
    divergenceTypeInformationGQL(tipo: $tipo, isZendesk: $isZendesk) {
      area
      id_tipo_divergencia
      tipo,
      is_zendesk
    }
  }
`;

export const ManagementZendeskTicket = () => {
  const classes = useStyles();
  const [searchField, setSearchField] = useState({ data: [], tipo: '', isZendesk: null });
  const [loadDivergenceType, { loading, data }] = useLazyQuery(GET_DIVERGENCE_TYPE, { fetchPolicy: 'network-only' });

  const handleSearch = (tipo: string, isZendesk: boolean | null) => {
    loadDivergenceType({ variables: { tipo, isZendesk } });
  };

  useEffect(() => {
    handleSearch(searchField.tipo, searchField.isZendesk || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setSearchField(prev => ({ ...prev, data: data.divergenceTypeInformationGQL }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  return (
      <div>
          <>
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                draggable
                pauseOnHover={false}
            />
            <SearchTypeTicket onSearch={handleSearch} isLoading={loading} />
            <div style={{ marginTop: '40px', width: '80%' }}>
              <Box className={classes.customBox}>
                <Typography variant="h6" style={{ textTransform: 'uppercase' }} gutterBottom>
                  Tipos de chamados
                </Typography>
                <div style={{ width: '100%', marginTop: '50px' }}>
                  {
                    loading ? (<LoadingConfig/>) : (
                    data?.divergenceTypeInformationGQL.map((divergencia: any) => {
                      return (
                          <TicketItem
                              isZendesk={divergencia.is_zendesk}
                              divergenceId={divergencia.id_tipo_divergencia}
                              title={divergencia.tipo}
                          />
                      )
                    })
                    )
                  }
                </div>
              </Box>
            </div>
          </>
      </div>
  );
};
