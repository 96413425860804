import {
  Box,
  Card,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ConfiguracoesKeys } from '../../support/enums/Configuracoes';
import { useConfiguracaoByKey } from '../../support/UseConfiguracao';
import DivergenciaListMenu from './components/DivergenciaListMenu';

const useStyles = makeStyles(() =>
  createStyles({
    indicadores: {
      height: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const DivergenciaIndicadores: FunctionComponent = () => {
  const classes = useStyles();
  const { loading, data } = useConfiguracaoByKey(
    ConfiguracoesKeys.URL_INDICADOR_DIVERGENCIAS
  );

  return (
    <div className='backgroundNeutral50 fillHeightWithTopbar'>
      <DivergenciaListMenu></DivergenciaListMenu>

      <Box className='backgroundNeutral50' paddingX={2}>
        <Card>
          {loading ? (
            <div className={classes.indicadores}>
              <CircularProgress color='inherit'></CircularProgress>
            </div>
          ) : (
            <div className={classes.indicadores}>
              <iframe
                style={{ border: 'none' }}
                width='100%'
                height='100%'
                src={data?.findOnePLM_Colaborativo_Configuracoes.valor}
                title='Indicadores'
              ></iframe>
            </div>
          )}
        </Card>
      </Box>
    </div>
  );
};

export default DivergenciaIndicadores;
