import { debounce } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { FunctionComponent, useState } from 'react';
import { LoggedUser } from './app/support/types/LoggedUser';
import { useLoggedUser } from './app/support/UseLoggedUser';
import { useApolloNetworkStatus } from './networkStatusNotifier';

const showNotifications = debounce(
  (notifications: string[], enqueueSnackbar) => {
    notifications.forEach((element) => {
      enqueueSnackbar(element, {
        variant: 'error',
        preventDuplicate: true,
      });
    });
  },
  1000
);
const ErrorHandler: FunctionComponent = () => {
  const status = useApolloNetworkStatus();
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState<string[]>([]);
  const { data: dataUser } = useLoggedUser();
  const [hasUser, setHasUser] = useState(false);

  if (!hasUser) {
    const has = setUser(dataUser);
    if (has) {
      setHasUser(true);
    }
  }

  showNotifications(notifications, enqueueSnackbar);

  if (status.queryError) {
    const error = status.queryError;
    if (error.networkError) {
      status.queryError = undefined;
      const message = 'Falha ao se conectar ao servidor.';
      setNotifications([...notifications, message]);
      Sentry.captureMessage(message, {
        level: Sentry.Severity.Critical,
      });
    }
    if (error.graphQLErrors) {
      let sendToSentry = true;
      let message = 'Falha no servidor.';
      if (
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].message === 'Unauthorized'
      ) {
        message = 'Não autenticado.';
        sendToSentry = false;
        const pathname = window.location.pathname;
        if (pathname !== '/login' && pathname !== '/') {
          localStorage.removeItem('auth');
          localStorage.setItem(
            'redirectBack',
            `${pathname}${window.location.search}`
          );
          window.location.reload();
        }
      }
      setNotifications([...notifications, message]);

      if (sendToSentry) {
        const errorMessages = error.graphQLErrors.map(
          (item) => `${item.message} -> ${item?.path?.join(',')}`
        );
        const errorString = errorMessages.join(',');
        const errorMessage = `${message}: ${errorString}`;
        Sentry.captureMessage(errorMessage, {
          level: Sentry.Severity.Critical,
        });
      }
      status.queryError = undefined;
    }
  }
  return null;
};

export default ErrorHandler;

function setUser(
  dataUser:
    | {
        getLoggedUser: LoggedUser;
      }
    | undefined
) {
  if (dataUser?.getLoggedUser) {
    const user = dataUser.getLoggedUser;
    sentrySetUser(user);

    localStorage.setItem(
      'user',
      JSON.stringify({
        idUsuario: user.idUsuario,
        login: user.login,
        email: user.email,
      })
    );
    return true;
  }

  const localStorageUser = localStorage.getItem('user');
  if (!dataUser?.getLoggedUser && localStorageUser) {
    const user = JSON.parse(localStorageUser);
    sentrySetUser(user);
    return true;
  }
  return false;
}

function sentrySetUser(user: any) {
  Sentry.setUser({
    id: user.idUsuario.toString(),
    username: user.login,
    email: user.email,
  });
}
