import React, { FunctionComponent } from 'react';

const LabelValueBase: FunctionComponent<{
  label: string;
  hideLabel?: boolean;
  hideChildren?: boolean;
}> = ({ hideLabel, children, label, hideChildren }) => (
  <React.Fragment>
    {!hideLabel && <div className='caption medium neutral500 mb8'>{label}</div>}
    {!hideChildren && children}
  </React.Fragment>
);

export default LabelValueBase;
