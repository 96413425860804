import React from "react";
import SelectStepTemplate from "../../../template/SelectStepTemplate";
import useGetMateriais, {
  buildOutrosAviamentos,
} from "../../../template/UseGetMateriais";
import { TipoDivergenciaComponent } from "../../../TiposDeDivergencias";
import SaldoFiscalAviamentoObservacaoStep from "./SaldoFiscalAviamentoObservacaoStep";
import TipoDivergenciasInfoKey from "../../../TipoDivergenciasInfoKey.";

const SaldoFiscalAviamento: TipoDivergenciaComponent = ({
  onSetStep,
  onBack,
}) => {
  const { loading, materiais } = useGetMateriais(
    TipoDivergenciasInfoKey.AVIAMENTO
  );
  const newMateriais = [...materiais, buildOutrosAviamentos()];

  const stepNumber = "2";
  const stepSubTitle = "Selecione o material";
  const stepTitle = "Qual material será ajustado?";
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={
        SaldoFiscalAviamentoObservacaoStep as TipoDivergenciaComponent
      }
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey="materiais"
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};

export default SaldoFiscalAviamento;
