import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
    buildOutrosTecidos,
} from '../../../template/UseGetMateriais';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import DivergenciaEtiquetaxFisicoFinalStep from './DivergenciaEtiquetaxFisicoFinalStep';

const DivergenciaEtiquetaxFisico: TipoDivergenciaComponent = ({ onSetStep, onBack }) => {
    const { loading, materiais } = useGetMateriais(
        TipoDivergenciasInfoKey.TECIDO
    );
    const newMateriais = [...materiais, buildOutrosTecidos()];

    const stepNumber = '2';
    const stepSubTitle = 'Selecione o tecido com a etiqueta divergente do físico.';
    const stepTitle = 'Qual tecido está com a etiqueta divergente do físico?';
    return (
        <SelectStepTemplate
            data={newMateriais}
            loading={loading}
            nextStepComponent={DivergenciaEtiquetaxFisicoFinalStep as TipoDivergenciaComponent}
            onBack={onBack}
            onSetStep={onSetStep}
            payloadKey='tecidos'
            stepNumber={stepNumber}
            stepSubTitle={stepSubTitle}
            stepTitle={stepTitle}
        />
    );
};

export default DivergenciaEtiquetaxFisico;
