import { createStyles, LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    determinate: {
      background: '#F4F3F3',
    },
    colorPrimary: {
      background: '#97877A',
    },
  })
);

export default (props: { value: number }) => {
  const classes = useStyles();

  return (
    <LinearProgress
      classes={{
        barColorPrimary: classes.colorPrimary,
        determinate: classes.determinate,
      }}
      variant='determinate'
      value={props.value}
    />
  );
};
