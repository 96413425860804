import { useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import DivergenciaStatusEnum from '../../../../../support/enums/DivergenciaStatusEnum';
//import { buildUserName } from '../../../../../support/Utils';
import LabelValueBase from '../../nova-divergencia/components/LabelValueBase';
import { DivergenciaType } from '../DivergenciaDetailTypes';

const situacaoDivergenciaQuery = loader(
  '../../queries/GetListSituacoesDivergencia.gql'
);

const updateSituacaoDivergenciaMutation = loader(
  '../../queries/UpdateSituacaoDivergencia.gql'
);

const SituacaoDivergencia: FunctionComponent<{
  divergencia: DivergenciaType;
  onStatusChange: Function;
}> = ({ divergencia, onStatusChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [save, { loading: loadingSave }] = useMutation(
    updateSituacaoDivergenciaMutation
  );

  const [loadingId, setLoadingId] = useState(0);

  const changeSituacao = (idNovaSituacao: number) => {
    if (loadingSave) return;
    setLoadingId(idNovaSituacao);
    save({
      variables: {
        idDivergencia: divergencia.id_divergencia,
        idNovaSituacao: idNovaSituacao,
      },
    }).then(() => {
      handleClose();
      onStatusChange();
      setLoadingId(0);
    });
  };

  const { loading: loadingSituacao, data: dataSituacao } = useQuery<{
    getListSituacoesDivergencia: {
      id_situacao_divergencia: number;
      situacao: string;
    }[];
  }>(situacaoDivergenciaQuery);

  const statusFinalizado = divergencia.status === DivergenciaStatusEnum.FINALIZADO;

  return (
    <LabelValueBase label='Situação'>
      <div>
        <span className='mt8 body2 semi-bold neutral700'>
          {divergencia.SituacaoDivergencia != null ? divergencia.SituacaoDivergencia.situacao : 'Não definida'}
        </span>

        {!loadingSituacao && !statusFinalizado && (
          <Button onClick={handleClick}>
            <span className='overline bold neutral400'>ALTERAR</span>
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          id='situacao-menu'
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          {dataSituacao?.getListSituacoesDivergencia.map((SituacaoDivergencia) => {
            const situacaoFinalizado = SituacaoDivergencia.situacao === "Finalizado Automaticamente";

            return (
              <MenuItem
                style={situacaoFinalizado ? { display: 'none' } : { display: 'block' }}
                disabled={
                  divergencia.SituacaoDivergencia?.id_situacao_divergencia === SituacaoDivergencia.id_situacao_divergencia
                }
                onClick={() => {
                  changeSituacao(
                    SituacaoDivergencia.id_situacao_divergencia
                  );
                }}
                key={`SituacaoDivergencia${SituacaoDivergencia.id_situacao_divergencia}`}
              >
                {loadingSave &&
                  loadingId === SituacaoDivergencia.id_situacao_divergencia ?
                  (
                    <div style={{ textAlign: 'center', width: '100%' }}>
                      <CircularProgress
                        color='inherit'
                        size={18}
                      ></CircularProgress>
                    </div>
                  ) : (
                    <span>
                      {SituacaoDivergencia.situacao}
                    </span>
                  )
                }
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </LabelValueBase>
  );
};

export default SituacaoDivergencia;
