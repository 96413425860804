import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { Link, matchPath } from 'react-router-dom';
import { SearchComponent } from '../../../components/SearchComponent';

function isActiveRoute(path: string) {
  return matchPath(window.location.pathname, {
    path: path,
    exact: true,
    strict: false,
  });
}

function getRouteClass(path: string): string {
  return clsx({
    body2: true,
    neutral400: !isActiveRoute(path),
    medium: !isActiveRoute(path),
    activeRoute: isActiveRoute(path),
    'semi-bold': isActiveRoute(path),
    neutral800: isActiveRoute(path),
  });
}

const DivergenciaListMenu: FunctionComponent = () => (
  <Box paddingX={2} className='backgroundNeutral00'>
    <Box marginBottom={3} marginTop={1}>
      <Grid container>
        <Grid item xs={9}>
          <span className='h4 headline primary500'>Painel de divergências</span>
        </Grid>
        <SearchComponent></SearchComponent>
      </Grid>
    </Box>
    <Grid container item xs={12}>
      <Box marginRight={3} marginBottom={3}>
        <Grid item>
          <Link to='/divergencias'>
            <span
              style={{
                paddingBottom: 16,
              }}
              className={getRouteClass('/divergencias')}
            >
              Resumo
            </span>
          </Link>
        </Grid>
      </Box>
      <Box marginRight={3} marginBottom={3}>
        <Grid item>
          <Link to='/divergencias/indicadores'>
            <span
              style={{
                paddingBottom: 16,
              }}
              className={getRouteClass('/divergencias/indicadores')}
            >
              Indicadores
            </span>
          </Link>
        </Grid>
      </Box>
    </Grid>
  </Box>
);

export default DivergenciaListMenu;
