import { useQuery } from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import DivergenciaEspelhoRow from '../components/DivergenciaEspelhoRow';
import { User } from '../components/InputUser';
import LoadingConfig from '../components/LoadingConfig';
import NewUserEspelho from '../components/NewUserEspelho';

const queryGetDivergenciaEspelho = loader(
  '../queries/getDivergenciaEspelho.gql'
);

const headerClass = 'caption bold neutral500 uppercase';

export type UsuarioEspelho = {
  id_usuario_divergencia_espelho: number;
  UsuarioOriginal: User;
  UsuarioCopia: User;
};

const DivergenciaEspelho: FunctionComponent = () => {
  const {
    loading,
    data = { findManyPLM_Colaborativo_Usuario_Divergencia_Espelho: [] },
    refetch,
  } = useQuery<{
    findManyPLM_Colaborativo_Usuario_Divergencia_Espelho: UsuarioEspelho[];
  }>(queryGetDivergenciaEspelho);
  const onSave = () => {
    refetch();
  };

  return (
    <div style={{ width: '50%' }}>
      {loading ? (
        <LoadingConfig></LoadingConfig>
      ) : (
        <div>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow className='tableHeader'>
                <TableCell className={headerClass}>Original</TableCell>
                <TableCell className={headerClass}>Cópia</TableCell>
                <TableCell className={headerClass}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='backgroundNeutral50'>
              {data.findManyPLM_Colaborativo_Usuario_Divergencia_Espelho.map(
                (item) => {
                  return (
                    <DivergenciaEspelhoRow
                      key={`idLiderLiderado-${item.id_usuario_divergencia_espelho}`}
                      onDelete={() => {
                        refetch();
                      }}
                      row={item}
                    ></DivergenciaEspelhoRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      )}
      <NewUserEspelho onSave={onSave}></NewUserEspelho>
    </div>
  );
};

export default DivergenciaEspelho;
