import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import _get from 'lodash.get';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

const query = loader('src/app/support/template/queries/ProductInfoQuery.gql');

const Criacao: FunctionComponent = () => {
  const { idProducaoProgProd, idProducaoPppOp } = useParams<{
    idProducaoProgProd: string;
    idProducaoPppOp: string;
  }>();

  const { data } = useQuery(query, {
    variables: {
      idProducaoProgProd: parseInt(idProducaoProgProd, 10),
      idProducaoPppOp: parseInt(idProducaoPppOp, 10),
    },
  });
  const product = _get(data, 'findManyProducao_Prog_Prod[0]');
  return <div>{_get(product, 'Produto_Cor.Produto.desc_produto')}</div>;
};

export default Criacao;
