import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import DivergenciasKpi from '../DivergenciasKpi';

export const DivergenciaKpi: FunctionComponent<{
  icon: React.ReactNode;
  label: string;
  loading?: boolean;
  value?: string;
}> = ({ label, icon, value, loading = false }) => (
  <Box
    alignItems='center'
    display='flex'
    flexDirection='column'
    height='100%'
    justifyContent='center'
    textAlign='center'
  >
    <Box className='h4 headline primary500'>
      {loading ? (
        <Skeleton width={50} height={46}></Skeleton>
      ) : (
        <span>{value}</span>
      )}
    </Box>
    <Box className='body2 medium neutral500'>{label}</Box>
  </Box>
);

export default DivergenciasKpi;
