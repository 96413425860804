import React from 'react';
import MateriaisPopover from '../../../../detail/components/MateriaisPopover';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import {
  transforSelectItemToDivergenciaInfo,
} from '../../../template/utils';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import {
  TipoDivergenciaComponent
} from '../../../TiposDeDivergencias';
import { AjuesteNecessidadeMP } from '../ajuste-necessidade-mp'
import { MateriaisTemplateProps } from '../../../template';


const AjusteNecessidadeMPFinalStep: TipoDivergenciaComponent<MateriaisTemplateProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {

  const onBackHandler = () => {
    onBack({
      component: AjuesteNecessidadeMP as TipoDivergenciaComponent,
    });
  };

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.AJUSTE_MATERIA_PRIMA,
      'Ajuste de necessidade de MP'
    ),
    <MateriaisPopover
      materiais={payload.materiais.map(transforSelectItemToDivergenciaInfo)}
    ></MateriaisPopover>,
  ];

  const key = TipoDivergenciasKey.AJUSTE_DE_NECESSIDADE_DE_MP;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(
          step, [
            ...payload.materiais.map((item) => ({
              tipo: item.tipo,
              value: item.id.toString(),
              adicionalInfo: item.adicionalInfo,
              material: item.material
            }))
          ]
        );
      }}
      progressValue={90}
      stepNumber={'4'}
      infoComponents={infoComponents}
    />
  );
};

export default AjusteNecessidadeMPFinalStep;
