import { createStyles, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ReactComponent as ChartIcon } from '../../../../../../assets/icons/chart.svg';
import { ReactComponent as ExpandIcon } from '../../../../../../assets/icons/expand.svg';
import { ReactComponent as FinancialIcon } from '../../../../../../assets/icons/financial.svg';
import { ReactComponent as PencilIcon } from '../../../../../../assets/icons/pencil-edit.svg';
import { ReactComponent as ProgrammingCodeIcon } from '../../../../../../assets/icons/programming-code.svg';
import { ReactComponent as ScissorsIcon } from '../../../../../../assets/icons/scissors.svg';
import { ReactComponent as StarIcon } from '../../../../../../assets/icons/star.svg';
import LabelValueBase from './LabelValueBase';

const useStyles = makeStyles(() =>
  createStyles({
    circle: {
      alignItems: 'center',
      border: '2px solid #F4F3F3',
      borderRadius: '48px',
      display: 'flex',
      height: '40px',
      justifyContent: 'center',
      width: '40px',
    },
  })
);

const areasIcons: { [key: string]: JSX.Element } = {
  Risco: <PencilIcon className='iconPrimary500' />,
  'Controle PCP': <ProgrammingCodeIcon className='iconPrimary500' />,
  'Controle PCP Corte Externo': <ScissorsIcon className='iconPrimary500' />,
  Piloto: <ExpandIcon className='iconPrimary500' />,
  Corte: <ScissorsIcon className='iconPrimary500' />,
  Financeiro: <FinancialIcon className='iconPrimary500' />,
  Planner: <ChartIcon className='iconPrimary500' />,
  'Qualidade MP': <StarIcon className='iconPrimary500' />,
  Modelagem: <ScissorsIcon className='iconPrimary500' />,
  'Cad Atelier': <ScissorsIcon className='iconPrimary500' />,
  Logistica: <StarIcon className='iconPrimary500' />,
  'Planejamento e Demanda': <ProgrammingCodeIcon className='iconPrimary500' />,
};

const AreaResponsavelComponent: FunctionComponent<{ area: string }> = ({
  area,
}) => {
  const classes = useStyles();
  return (
    <LabelValueBase label='Área responsável'>
      <div className='d-flex align-item-center mt8'>
        <span className={classes.circle}>{areasIcons[area]}</span>
        <span className='ml16 body2 semi-bold neutral700'>{area}</span>
      </div>
    </LabelValueBase>
  );
};

export default AreaResponsavelComponent;
