import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { FunctionComponent } from "react";

const tagsPrecoQuery = loader('../queries/GetTagPreco.gql')

type TagPreco = {
    id_material: number;
    id_linx_mp: string;
    desc_material: string;
};

export const TagPreco: FunctionComponent<{}> = (props) => {
    const { data: dataTagsPreco } = useQuery<{
        getTag_Preco: TagPreco;
    }>(tagsPrecoQuery)

    let tagsPreco = {} as TagPreco

    if (dataTagsPreco) {
        tagsPreco = dataTagsPreco?.getTag_Preco as TagPreco;
    }

    let tagsArray: any = [];

    Object.values(tagsPreco).forEach((value) => {
        let innerObj: any = {}
        innerObj[Object.values(value)[1]] = Object.values(value)[3]
        tagsArray.push(innerObj)
    })
    return tagsArray
}