import { gql } from '@apollo/client'

export const UPSERT_REMINDER = gql`
  mutation UpsertReminder($input: ReminderInput!) {
    upsertReminder(reminder: $input) {
      id_lembrete
      id_divergencia
      data_lembrete
      visualizado
    }
  }
`
