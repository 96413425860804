// libs
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Grid, Box, Drawer, Typography, CircularProgress } from '@material-ui/core'


// services
import { useLazyQuery, useMutation } from '@apollo/client'
import { LIST_NOTIFICATION, ListNotificationData, NotificationsData } from '../../queries'
import { UPDATE_REMINDER_DATE, updateReminderData, updateReminderVars} from './mutations'

// assets
import { ColorsController } from '../../../../config/theme/colorsController'

import { NotificationCard } from './NotificationCard'
import { NotificationsContext } from '../../../context'
import { InformationModal } from '../../../shared'

interface NotificationsDrawerProps {
  open: boolean
  onClose: () => void
}
export function NotificationsDrawer({
  open,
  onClose
}: NotificationsDrawerProps): JSX.Element {
  const { setRefetch, refetch } = useContext(NotificationsContext)

  const [idReminder, setIdReminder] = useState<number | undefined>(undefined)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleOpen = () => setOpenModal(!openModal)
  const handleClose = () => setOpenModal(!openModal)

  const [runQuuery, {data: notificationData, error: notificationError, loading}] =     
  useLazyQuery<ListNotificationData>(LIST_NOTIFICATION, {
      fetchPolicy: 'no-cache'
  })

  useEffect(() => {
      runQuuery()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
      runQuuery()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch])

  const notificationsData = useMemo<NotificationsData[]>(() => {
    if (notificationData && notificationData.getUnviewedRemindersByUser) {
      return notificationData.getUnviewedRemindersByUser
    }

    return []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData, notificationError])

  const [runMutation] = useMutation<updateReminderData, updateReminderVars>(
    UPDATE_REMINDER_DATE,
    {
      onCompleted: () => {
        setRefetch(!refetch)
        handleClose()
      }
    }
  )

  const handleNotificationClick = ({ id_lembrete }: NotificationsData) => {
    handleOpen()
    setIdReminder(id_lembrete)
  }


   const handleUpdateReminder = () => {
    runMutation({
      variables: {
        idReminder: idReminder!,
        visualized: true
      }
    })
  }

  return (
    <>
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        style={{
          width: '600px'
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: '60px',
            borderBottom: `1px solid #dfdfdf`
          }}
        >
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography  
            style={{
                fontSize: 20,
                fontWeight: 600,
                color:`${ColorsController.colors.primary}`
            }} 
            >
              Notificações
            </Typography>
            <Box display="flex" alignItems="center">
              <Box
                width="25px"
                height="25px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor={ColorsController.colors.secondaryLighter}
                borderRadius="50%"
                mr={1}
              >
                <Typography
                style={{
                    fontSize: 14,
                    color: 'white',
                    fontWeight: 600
                 }}
                >
                  { notificationsData.length }
                </Typography>
              </Box>
              <Typography
              style={{
                fontSize: 14,
                fontWeight: 600
              }}
              color="primary" 
              >
                notificações não visualizadas
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} 
        style={{
            borderBottom: `1px solid #dfdfdf`
          }}
        >
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="space-around"
          >
            <Typography  
            style={{
                fontSize: 17,
                fontWeight: 500
            }} 
            color="inherit" 
            >
              Legenda:
            </Typography>
            <Box display="flex" alignItems="center">
              <Box
                width="20px"
                height="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor={ColorsController.colors.success}
                borderRadius="50%"
                mr={1}
              >
              </Box>
              <Typography
              style={{
                fontSize: 14,
                fontWeight: 400
              }}
              color="primary" 
              >
                Lembretes futuros
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                width="20px"
                height="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor={ColorsController.colors.error}
                borderRadius="50%"
                mr={1}
              >
              </Box>
              <Typography
              style={{
                fontSize: 14,
                fontWeight: 400
              }}
              color="primary" 
              >
                Lembretes passados
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            pl={2}
            pr={2}
            pt={2}
            pb={2}
          >
            <Grid container spacing={2}>
              {loading ?
                <Grid item xs={12}>
                  <Box  
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  >
                    <CircularProgress color='inherit' /> 
                  </Box>
                </Grid>
                :
                notificationsData.map((item, key) => (
                  <Grid item xs={12} key={key}>
                    <NotificationCard
                      data={item}
                      onClick={handleNotificationClick}
                      onClose={onClose}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Drawer>
    <InformationModal
        open={openModal}
        onClose={handleClose}
        title="Marcar chamado"
        description={`Quer realmente marcar este chamado como lido?`}
        secondaryBtn={{ label: 'Não', onClick: handleClose }}
        primaryBtn={{ label: 'Sim', onClick: handleUpdateReminder }}
        variant='error'
      />
    </>
  )
}
