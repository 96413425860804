import AreaTipoDivergenciaEnum from '../../AreaTipoDivergenciaEnum';
import { useBuildFinalStepTemplate } from '../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../TiposDeDivergencias';

const FaltouAviamento: TipoDivergenciaComponent = ({
  onBack,
  onSetStep,
}) => {
  const key = TipoDivergenciasKey.FALTOU_AVIAMENTO;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.LOGISTICA,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: 'Recebeu somente corte, faltou aviamento',
  });
};

export default FaltouAviamento;
