import { useMutation, useQuery } from "@apollo/client";
import { Button, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { loader } from "graphql.macro";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import DivergenciaStatusEnum from "../../../../../support/enums/DivergenciaStatusEnum";
import { buildUserName } from "../../../../../support/Utils";
import LabelValueBase from "../../nova-divergencia/components/LabelValueBase";
import { DivergenciaType } from "../DivergenciaDetailTypes";
import { isSupplier } from "../../../../../tools";
import { UserContext, UserContextType } from "../../../../../context";

const updateTipoDivergenciaMutation = loader(
  "../../queries/UpdateTipoDivergencia.gql"
);

const tipoDivegenciaResponsavelQuery = loader(
  "../../queries/GetListTipoDivergenciaResponsavel.gql"
);

const userCanChangeStatusQuery = loader(
  "../../queries/GetLoggedUserCanChangeDivergenciaStatus.gql"
);

const TipoDivergencia: FunctionComponent<{
  divergencia: DivergenciaType;
  onStatusChange: Function;
}> = ({ divergencia, onStatusChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userLogged } = useContext<UserContextType>(UserContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [save, { loading: loadingSave }] = useMutation(
    updateTipoDivergenciaMutation
  );
  const [loadingId, setLoadingId] = useState(0);
  const changeTipo = (idNovoTipo: number) => {
    if (loadingSave) return;

    setLoadingId(idNovoTipo);
    save({
      variables: {
        idDivergencia: divergencia.id_divergencia,
        idNovoTipo: idNovoTipo,
      },
    }).then(() => {
      handleClose();
      onStatusChange();
      setLoadingId(0);
    });
  };

  const {
    loading: loadingCanChangeStatusQuery,
    data: userCanChangeStatus,
    refetch: refetchCanChange,
  } = useQuery<{ canChangeDivergenciaStatus: boolean }>(
    userCanChangeStatusQuery,
    {
      variables: {
        idDivergencia: divergencia.id_divergencia,
      },
    }
  );

  useEffect(() => {
    refetchCanChange();
  }, [divergencia.status, refetchCanChange]);

  const canChangeStatus = () => {
    if (
      (divergencia.status === DivergenciaStatusEnum.NOVO ||
        divergencia.status === DivergenciaStatusEnum.EM_ANDAMENTO) &&
      !isSupplier(userLogged?.id_grupo || 0)
    ) {
      return true;
    }

    if (
      divergencia.status === DivergenciaStatusEnum.AGUARDANDO_FINALIZACAO &&
      !isSupplier(userLogged?.id_grupo || 0)
    ) {
      return true;
    }


    if (
      divergencia.status !== DivergenciaStatusEnum.EM_ANDAMENTO &&
      divergencia.status !== DivergenciaStatusEnum.AGUARDANDO_FINALIZACAO
    ) {
      return false;
    }
    if (loadingCanChangeStatusQuery) {
      return false;
    }
    return userCanChangeStatus?.canChangeDivergenciaStatus || false;
  };

  const { loading: loadingTipos, data: dataTipos } = useQuery<{
    listTipoDivergenciaResponsavel: {
      TipoDivergencia: {
        id_tipo_divergencia: number;
        tipo: string;
        area: string;
      };
      Responsavel: {
        login: string;
        id_usuario: number;
      };
    }[];
  }>(tipoDivegenciaResponsavelQuery, {
    variables: {
      idPpoOp: divergencia.Producao_PPP_OP.id_producao_ppp_op,
    },
    skip: !canChangeStatus(),
  });

  return (
    <LabelValueBase label="Tipo">
      <div>
        <span className="mt8 body2 semi-bold neutral700">
          {divergencia.TipoDivergencia.tipo}
        </span>

        {!loadingTipos && canChangeStatus() && (
          <>
            <Button onClick={handleClick}>
              <span className="overline bold neutral400">ALTERAR</span>
            </Button>
            <div>
              <Alert severity="warning">
                O chamado só poderá ser reclassificado enquanto estiver em
                aberto.
              </Alert>
            </div>
          </>
        )}
        <Menu
          anchorEl={anchorEl}
          id="status-menu"
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          {dataTipos?.listTipoDivergenciaResponsavel.map((tipoDivergencia) => {
            return (
              <MenuItem
                disabled={
                  divergencia.TipoDivergencia.id_tipo_divergencia ===
                  tipoDivergencia.TipoDivergencia.id_tipo_divergencia
                }
                onClick={() => {
                  changeTipo(
                    tipoDivergencia.TipoDivergencia.id_tipo_divergencia
                  );
                }}
                key={`tipoDivergencia${tipoDivergencia.TipoDivergencia.id_tipo_divergencia}`}
              >
                {loadingSave &&
                loadingId ===
                  tipoDivergencia.TipoDivergencia.id_tipo_divergencia ? (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <CircularProgress
                      color="inherit"
                      size={18}
                    ></CircularProgress>
                  </div>
                ) : (
                  <span>
                    {tipoDivergencia.TipoDivergencia.tipo} (
                    {tipoDivergencia.Responsavel?.login &&
                      buildUserName(tipoDivergencia.Responsavel.login)}
                    )
                  </span>
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </LabelValueBase>
  );
};

export default TipoDivergencia;
