import React, { FunctionComponent, useContext, useEffect } from 'react';
import { CustomFile } from '../../components/chat/ChatInput';
import ContinueOrBackActions from '../components/ContinueOrBackActions';
import DivergenciaRegistrada from '../DivergenciaRegistrada';
import { Step } from '../NovaDivergenciaStepHandler';
import { TipoDivergenciaComponent } from '../TiposDeDivergencias';

import { DivergenceContext } from '../../../../../context';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

function isDisabledButton(): boolean {
  return false;
}

const query = loader('../../queries/getFilterDivergencia.gql')

const FinalStepFooter: FunctionComponent<{
  tipoDivergenciaFaltaAviamento?: boolean;
  hasTagPreco?: boolean;
  verificaQuantidade?: boolean;
  onBack: (step?: Step) => void;
  onSetStep: (step: Step) => void;
  loading?: boolean;
  addPayload?: { message: string; files: Array<CustomFile> };
}> = (props) => {
  const ctx = useContext(DivergenceContext)

  const {
    data } = useQuery(query, {
    variables: {
      skip: 0,
      tipoDivergencia: ctx.typeOfDivergence,
      idProducaoPppOp: parseInt(ctx.idProducaoPppOp || '')
    },
  });


  useEffect(() => {
    if (data && data?.filterDivergenciaByParams && data?.filterDivergenciaByParams.length > 0) {
      ctx.setPopUpDivergenciaDuplicada({
        open: true,
        handleOpenClosed: () => ctx.popUpDivergenciaDuplicada?.open,
        title: "Atenção!",
        content: `Essa OP já possui ${ data && data?.filterDivergenciaByParams.length} chamado(s) aberto nessa mesma categoria. Tem certeza que deseja abrir um novo chamado?`,
      })

      ctx.popUpDivergenciaDuplicada && ctx.popUpDivergenciaDuplicada?.handleOpenClosed && ctx.popUpDivergenciaDuplicada.handleOpenClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <ContinueOrBackActions
        loading={props.loading}
        continueMessage='Finalizar'
        disabled={(props.tipoDivergenciaFaltaAviamento && props.hasTagPreco) ? !props.verificaQuantidade : isDisabledButton()}
        disabledMessage={(!props.verificaQuantidade) ? `A quantidade dos tamanhos de códigos de barras deverá ser 
        igual a quantidade total selecionada no step anterior` : 'Escreva um comentário para finalizar'}
        onBack={props.onBack}
        onContinue={() => {
          props.onSetStep({
            component: DivergenciaRegistrada as TipoDivergenciaComponent,
            isFinalStep: true,
            payload: props.addPayload,
          })
      }}
    />
  </>
  )
};

export default FinalStepFooter;
