import React from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import {
  buildMultiploMaterialInfoComponent,
  TecidoProps,
} from '../../../template/TecidoQuantidadeStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import { createBackHandler } from '../../../template/utils';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import FaltaDeTecidoInterno from './FaltaDeTecidoInterno';

const FaltaDeTecidoFinalStep: TipoDivergenciaComponent<TecidoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = createBackHandler(onBack, FaltaDeTecidoInterno);

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.PLANEJAMENTO_DEMANDA,
      'Tecido divergente'
    ),
    ...buildMultiploMaterialInfoComponent(payload),
  ];
  const key = TipoDivergenciasKey.TECIDO_DIVERGENTE;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);
  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      infoMessage={
        <span>
          Envie uma foto em anexo para obter uma resposta mais objetiva.
        </span>
      }
      onSetStep={(step) => {
        handleSubmit(
          step,
          payload.tecidos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.TECIDO,
            value: item.id.toString(),
            adicionalInfo: item.adicionalInfo,
            material: item.material
          }))
        );
      }}
      progressValue={90}
      stepNumber={'3'}
      infoComponents={infoComponents}
    />
  );
};

export default FaltaDeTecidoFinalStep;
