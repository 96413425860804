import React from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from '../../../TiposDeDivergencias';
import  {AumentoGradeOPStep } from './AumentoGradeOPStep';
import LabelValueWithSubtitle from '../../../components/LabelValueWithSubtitle';


// Interface compartilhada entre componetes de aumento e reducação
export interface PayloadProblemProps extends TipoDivergenciaComponentProps {
  payload: { problemDescription: string };
}

const AumentoGradeOPFinalStep: TipoDivergenciaComponent<PayloadProblemProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = () => {
    onBack({
      component: AumentoGradeOPStep as TipoDivergenciaComponent,
    });
  };

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.AJUSTE_MATERIA_PRIMA,
      'Aumento de grade em OP'
    ),
    <LabelValueWithSubtitle
      label='Descrição do problema'
      value={`${payload.problemDescription}`}
      subtitle={''}
    />,
  ];

  const key = TipoDivergenciasKey.AUMENTO_DE_GRADE_EM_OP;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(step, [        
          {
            tipo: TipoDivergenciasInfoKey.AJUSTE_MP,
            value: payload.problemDescription,
          },
        ]);
      }}
      progressValue={90}
      stepNumber={'3'}
      infoComponents={infoComponents}
    >
    </FinalStepTemplate>
  );
};

export default AumentoGradeOPFinalStep;
