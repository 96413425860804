import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import ContinueOrBackActions from "../../../components/ContinueOrBackActions";
import NovaDivergenciaProgressBar from "../../../components/NovaDivergenciaProgressBar";
import StepperHeader from "../../../components/StepperHeader";
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from "../../../TiposDeDivergencias";
import OutrosAviamentoFinalStep from "./OutrosAviamentoFinalStep";
import { MaterialSelectItem } from "../../../template/UseGetMateriais";
import { DivergenceContext, DivergenceContextType } from "../../../../../../../context";

export interface AviamentoProps extends TipoDivergenciaComponentProps {
  payload: { materiais: MaterialSelectItem[]; problemDescription: string };
}

const OutrosAviamentoObservacaoStep: TipoDivergenciaComponent<
  AviamentoProps
> = ({ onSetStep, onBack, payload }) => {
  const [problemDescription, setProblemDescription] = useState("");
  const { setAviamentos } = useContext<DivergenceContextType>(DivergenceContext)
  const isDisabledButton = () => !problemDescription;
  const handleContinue = () => {
    const nextPayload = {
      problemDescription: problemDescription,
      materiais: payload.materiais,
    };
    setAviamentos(null);
    setAviamentos({ aviamentos: nextPayload.materiais });
    onSetStep({
      component: OutrosAviamentoFinalStep as TipoDivergenciaComponent,
      payload: nextPayload,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProblemDescription(event.target.value);
  };

  const stepNumber = "3";
  const stepSubTitle = "Descrição de anomalia";
  const stepTitle = "Informe a descrição de anomalia";

  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={66}></NovaDivergenciaProgressBar>
      <DialogContent
        className="backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth"
        style={{
          padding: "0 32px",
        }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div className="px16">
          <form
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}
          >
            <TextField
              fullWidth
              type="text"
              name="text"
              multiline
              rows={4}
              onChange={handleChange}
              placeholder="Informe a sua descrição aqui..."
              variant="outlined"
            />
          </form>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className="divergenciaMaxWidth"
        style={{ padding: "30px 32px" }}
      >
        <ContinueOrBackActions
          continueMessage="Continuar"
          disabled={isDisabledButton()}
          disabledMessage="Continuar"
          onBack={onBack}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  );
};

export default OutrosAviamentoObservacaoStep;
