import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import KpiDivergenciasAguardandoFinalizacao from './kpis/KpiDivergenciasAguardandoFinalizacao';
import KpiDivergenciasEmAndamento from './kpis/KpiDivergenciasEmAndamento';
import KpiNovasDivergencias from './kpis/KpiNovasDivergencias';
import KpiTempoMedioAtendimento from './kpis/KpiTempoMedioAtendimento';
import KpiTempoMedioResolucao from './kpis/KpiTempoMedioResolucao';

const DivergenciasKpi: FunctionComponent = () => (
  <Grid container spacing={4} justify='space-evenly'>
    <Grid item xs='auto'>
      <KpiNovasDivergencias></KpiNovasDivergencias>
    </Grid>
    <Grid item xs='auto'>
      <KpiDivergenciasEmAndamento></KpiDivergenciasEmAndamento>
    </Grid>
    <Grid item xs='auto'>
      <KpiDivergenciasAguardandoFinalizacao></KpiDivergenciasAguardandoFinalizacao>
    </Grid>
    <Grid item xs='auto'>
      <KpiTempoMedioAtendimento></KpiTempoMedioAtendimento>
    </Grid>
    <Grid item xs='auto'>
      <KpiTempoMedioResolucao></KpiTempoMedioResolucao>
    </Grid>
  </Grid>
);

export default DivergenciasKpi;
