// libs
import { useMemo } from 'react'

// services
import { useLazyQuery } from '@apollo/client'
import { GET_PRODUCAO_PPOP } from '../../../product-detail/divergencias/queries'

interface GetDivergence {
    Ordem_Producao: {
        is_pa: boolean
    },
    Producao_Prog_Prod: {
      Produto_Cor: {
        Produto: {
          corte_interno?: boolean,
          dev_interno?: boolean,
          digitalizacao_interna?: boolean
          desenvolvimento?: string
        };
      };
    };
}

export interface GetDivergenceData {
  findOneProducao_PPP_OP: GetDivergence
}

interface GetDivergenceVars {
  idProducaoPppOp: Number;
}

export interface UseGetDivergenceResult {
  divergenceData: GetDivergence | null
  fetchDivergence: () => void
  loading?: boolean
  isError?: boolean
}

export const useGetDivergence = (  idProducaoPppOp  : string): UseGetDivergenceResult => {

  const [runQuery, { data, error, loading }] = useLazyQuery<GetDivergenceData, GetDivergenceVars>(
    GET_PRODUCAO_PPOP,
    { fetchPolicy: 'no-cache' }
  )

  
  const fetchDivergence = () => {
      runQuery({
        variables: {
          idProducaoPppOp: parseInt(idProducaoPppOp, 10)
        }
      })
  }


  const divergenceData = useMemo<GetDivergence | null>(() => {
    if (data && data.findOneProducao_PPP_OP && !error) {
      return  data.findOneProducao_PPP_OP
    }

    return null
  }, [data, error])

  return {
    fetchDivergence,
    divergenceData,
    loading,
    isError: Boolean(error)
  }
}