import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

const produtoQuery = loader('../../../product-detail/divergencias/queries/getProdutoPPOP.gql')

type itemProducaoPPPOPQuery = {
  id_ordem_producao: number;
  Producao_Prog_Prod: {
    Produto_Cor: {
      Produto: {
        id_produto: number;
        produto: string;
        Marca: {
          id_marca: number;
          id_marca_linx: number;
          marca: string;
        }
      };
    };
  };
};

type PropsFindMarcaType = {
  idProducaoPppOp: string;
}


export const FindMarca = (props: PropsFindMarcaType) => {
  const { idProducaoPppOp } = props;

  const {
    data,
  } = useQuery<{
    findOneProducao_PPP_OP: itemProducaoPPPOPQuery;
  }>(produtoQuery, {
    variables: {
      idProducaoPppOp: parseInt(idProducaoPppOp, 10),
    },
  });

  let id_marca_linx = 0;
  if (data) {
    id_marca_linx = data.findOneProducao_PPP_OP.Producao_Prog_Prod?.Produto_Cor?.Produto?.Marca?.id_marca_linx || 0
  }

  return (
    id_marca_linx
  )
}

