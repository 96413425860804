import React from 'react';
import AviamentoQuntidadeStepTemplate, {
  AviamentoProps,
} from '../../../template/AviamentoQuntidadeStepTemplate';
import { createBackHandler } from '../../../template/utils';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import ComplementoDeGradeAviamento from './ComplementoDeGradeAviamento';
import ComplementoDeGradeAviamentoFinalStep from './ComplementoDeGradeAviamentoFinalStep';

const ComplementoDeGradeAviamentoQuantidadeStep: TipoDivergenciaComponent<AviamentoProps> = ({
  onSetStep,
  onBack,
  payload,
  onClose,
}) => {
  const backComponent = ComplementoDeGradeAviamento;
  const nextComponent = ComplementoDeGradeAviamentoFinalStep;

  const onBackHandler = createBackHandler(onBack, backComponent);
  return (
    <AviamentoQuntidadeStepTemplate
      nextStepComponent={nextComponent as TipoDivergenciaComponent}
      onClose={onClose}
      onSetStep={onSetStep}
      onBack={onBackHandler}
      payload={payload}
    ></AviamentoQuntidadeStepTemplate>
  );
};

export default ComplementoDeGradeAviamentoQuantidadeStep;
