import React from 'react';
import { 
  Button, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Dialog, 
  Typography
} from '@material-ui/core';

interface PopupProps {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  title: string;
  content: string;
  loading: boolean;
}

function DialogBox({ open, onClose, onConfirm, title, loading, content }: PopupProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle style={{ backgroundColor: '#F9F9F9' }}>
        <Typography variant="h6" align="center">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          backgroundColor: '#F9F9F9',
          border: '1px solid #97877A',
          borderRadius: '4px',
        }}
      >
        {loading ? (
          <Typography align="center">{content}</Typography>
        ) : (
          <div></div>
        )}
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#F9F9F9' }}>
        <Button onClick={onClose} color="primary">
          Não
        </Button>
        <Button onClick={onConfirm} style={{ backgroundColor: 'green', color: 'white', background: '#97877A' }}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogBox;