import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { LabelValueCell } from './LabelValueCell';

export const LabelDateCell: FunctionComponent<{
  value?: string;
  label: String;
}> = ({ value, label }) => {
  let formattedDate = '';
  if (value) {
    formattedDate = moment.parseZone(value).format('DD/MM/YYYY');
  }
  return <LabelValueCell value={formattedDate} label={label}></LabelValueCell>;
};
