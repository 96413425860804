import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import ContinueOrBackActions from '../../../components/ContinueOrBackActions';
import NovaDivergenciaProgressBar from '../../../components/NovaDivergenciaProgressBar';
import StepperHeader from '../../../components/StepperHeader';
import {
  TipoDivergenciaComponent,
} from '../../../TiposDeDivergencias';
import AumentoGradeOPFinalStep from './AumentoGradeOPFinalStep';


export const AumentoGradeOPStep: TipoDivergenciaComponent = ({
  onSetStep,
  onBack,
}) => {
  const [problemDescription, setProblemDescription] = useState('');
  const isDisabledButton = () => !problemDescription;
  const handleContinue = () => {
    const nextPayload = {
      problemDescription: problemDescription,
    };
    onSetStep({
      component: AumentoGradeOPFinalStep as TipoDivergenciaComponent,
      payload: nextPayload,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProblemDescription(event.target.value);
  };

  const stepNumber = '2';
  const stepSubTitle = 'Digite a grade desejada e o motivo do aumento.';
  const stepTitle = 'Qual é o aumento de grade na ordem de produção?';

  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={66}></NovaDivergenciaProgressBar>
      <DialogContent
        className='backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth'
        style={{
          padding: '0 32px',
        }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div className='px16'>
          <form
            autoComplete='off'
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}>
            <TextField
              fullWidth
              type='text'
              name='text'
              multiline
              rows={4}
              onChange={handleChange}
              placeholder='Digite seu texto aqui...'
              variant='outlined'
            />
          </form>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className='divergenciaMaxWidth'
        style={{ padding: '30px 32px' }}
      >
        <ContinueOrBackActions
          continueMessage='Continuar'
          disabled={isDisabledButton()}
          disabledMessage='Continuar'
          onBack={onBack}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  )

};
