import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import { DivergenciaType } from "../../DivergenciaDetailTypes";
import { gql, useMutation, useQuery } from "@apollo/client";
import SubgruposDivergencias from "../../../../../../../domain/entities/subgruposDivergencias";
import { Alert } from "@material-ui/lab";
import { UserContext, UserContextType } from "./../../../../../../context/user";
import { isSupplier } from "../../../../../../tools";
import { isEmpty } from "../../../../../../../shared/utils";

type FeedbackType = {
  show: boolean;
  message: string;
  type: "error" | "warning" | "info" | "success";
};

type SubgroupSelectProps = {
  divergencia: DivergenciaType;
};

type FindOneDivergenciaType = {
  id_divergencia: number;
  id_subgrupo_divergencia: number;
};

type QueryResultData = {
  getAll_PLM_Colaborativo_Subgrupos_Divergencias: SubgruposDivergencias[];
  findOnePLM_Colaborativo_Divergencias: FindOneDivergenciaType;
};

const INITIAL_VALUE_FOR_FEEDBACK: FeedbackType = {
  message: "",
  show: false,
  type: "info",
};

const SubgroupSelect: React.FC<SubgroupSelectProps> = ({
  divergencia,
}: SubgroupSelectProps) => {
  const { userLogged } = useContext<UserContextType>(UserContext);
  const [subgroup, setSubgroup] = useState<number>();
  const [feedback, setFeedback] = useState<FeedbackType>(
    INITIAL_VALUE_FOR_FEEDBACK
  );

  const handleCloseSnackbar = () => {
    setFeedback(INITIAL_VALUE_FOR_FEEDBACK);
  };

  const GET_ALL_SUBGRUPOS_DIVERGENCIAS_GQL = gql`
    query {
      getAll_PLM_Colaborativo_Subgrupos_Divergencias (inativo: false) {
        id_subgrupo_divergencia
        subgrupo
        area_tipo
        created_at
        updated_at
        tipos_divergencias_ids
      }

      findOnePLM_Colaborativo_Divergencias(where: { id_divergencia: ${divergencia.id_divergencia} }) {
        id_divergencia
        id_subgrupo_divergencia
      }
    }
  `;
  const { data: queryResultData } = useQuery<QueryResultData>(
    GET_ALL_SUBGRUPOS_DIVERGENCIAS_GQL
  );

  const makeUpdateSubgrupoDivergenciaGQL = () => {
    return gql`
      mutation updateSubgrupoDivergencia(
        $idDivergencia: Int!
        $idSubgroup: Int!
        $previousSubgroupId: Int!
      ) {
        updateSubgrupoDivergencia(
          idDivergencia: $idDivergencia
          idSubgroup: $idSubgroup
          previousSubgroupId: $previousSubgroupId
        ) {
          id_divergencia
        }
      }
    `;
  };

  const [updateSubgrupoGQL] = useMutation(makeUpdateSubgrupoDivergenciaGQL());

  const handleChangeSubgroup = (idSubgroup: number) => {
    const { id_subgrupo_divergencia: previousSubgroupId } =
      extractDivergenceInformation(queryResultData);
    updateSubgrupoGQL({
      variables: {
        idDivergencia: divergencia.id_divergencia,
        idSubgroup: idSubgroup,
        previousSubgroupId: previousSubgroupId || 0,
      },
    })
      .then(({ data }) => {
        const { id_divergencia } = data?.updateSubgrupoDivergencia;
        if (id_divergencia) {
          setFeedback({
            message: "Subgrupo alterado com sucesso.",
            show: true,
            type: "success",
          });
        }
        setSubgroup(idSubgroup);
      })
      .catch((e) => {
        setFeedback({
          message: "Não foi possível concluir a operação.",
          show: true,
          type: "error",
        });
      });
  };

  const extractsInformationAccordingToTheAreaOfDivergence = (
    divergencia: DivergenciaType,
    queryResultData?: QueryResultData
  ) => {
    const subgrupos: SubgruposDivergencias[] = [];

    const data =
      queryResultData?.getAll_PLM_Colaborativo_Subgrupos_Divergencias;

    data?.forEach((item) => {
      if (subgroupIsValidForThisDivergence(item, divergencia)) {
        subgrupos.push(item);
      }
    });

    return subgrupos;
  };

  const subgroupIsValidForThisDivergence = (
    item: SubgruposDivergencias,
    divergencia: DivergenciaType
  ) => {
    if (isEmpty(item?.tipos_divergencias_ids)) {
      return checkByAreaOfDivergence(item, divergencia);
    }
    return checksByDivergenceTypeId(item, divergencia);
  };

  const checkByAreaOfDivergence = (
    item: SubgruposDivergencias,
    divergencia: DivergenciaType
  ) => {
    return typesOfAreasAreEqual(
      divergencia.TipoDivergencia.area,
      item?.area_tipo || ""
    );
  };

  const typesOfAreasAreEqual = (
    typeOfDivergenceArea: string,
    subgroupAreaType: string
  ) => {
    return (
      typeOfDivergenceArea.toLowerCase() === subgroupAreaType.toLowerCase()
    );
  };

  const checksByDivergenceTypeId = (
    item: SubgruposDivergencias,
    divergencia: DivergenciaType
  ) => {
    return idOfDivergenciaTypeAreEqual(
      divergencia.TipoDivergencia.id_tipo_divergencia.toString(),
      item?.tipos_divergencias_ids || ""
    );
  };

  const idOfDivergenciaTypeAreEqual = (
    idOfDivergenciaType: string,
    subgroupIdsTypes: string
  ) => {
    if (isEmpty(subgroupIdsTypes)) {
      return true;
    }

    const idsArray = subgroupIdsTypes.split(";");
    const lowercasedId = idOfDivergenciaType.toLowerCase();

    return idsArray.some((id) => id.toLowerCase() === lowercasedId);
  };

  const extractDivergenceInformation = (queryResultData?: QueryResultData) => {
    return {
      id_divergencia:
        queryResultData?.findOnePLM_Colaborativo_Divergencias.id_divergencia,
      id_subgrupo_divergencia:
        queryResultData?.findOnePLM_Colaborativo_Divergencias
          .id_subgrupo_divergencia,
    };
  };

  const subgroupValues = extractsInformationAccordingToTheAreaOfDivergence(
    divergencia,
    queryResultData
  );

  const setInitialValueForSubgroup = (
    previousSubgroup?: number,
    currentSubgroup?: number
  ) => {
    if (previousSubgroup) return previousSubgroup;
    if (currentSubgroup) return currentSubgroup;
    return "";
  };

  useEffect(() => {
    const { id_subgrupo_divergencia: previousSubgroupId } =
      extractDivergenceInformation(queryResultData);
    if (previousSubgroupId) {
      setSubgroup(previousSubgroupId);
    }
  }, [queryResultData]);

  if (subgroupValues.length === 0) return <></>;
  if (isSupplier(userLogged?.id_grupo || null)) return <></>;

  return (
    <>
      <div>
        <FormControl fullWidth>
          <InputLabel id="selectSubgrouptInput">
            Selecione um subgrupo
          </InputLabel>
          <Select
            labelId="selectSubgrouptInput"
            id="selectSubgroupt"
            value={setInitialValueForSubgroup(
              subgroup,
              queryResultData?.findOnePLM_Colaborativo_Divergencias
                .id_subgrupo_divergencia
            )}
            onChange={(event) => {
              const idSubgroup = Number(event.target.value);
              if (idSubgroup) {
                handleChangeSubgroup(idSubgroup);
              }
            }}
          >
            <MenuItem value=""></MenuItem>
            {subgroupValues?.map((item: SubgruposDivergencias) => {
              return (
                <MenuItem
                  key={item.id_subgrupo_divergencia}
                  value={item.id_subgrupo_divergencia}
                >
                  {item.subgrupo}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Snackbar
          open={feedback.show}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={feedback.type}>
            {feedback.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default SubgroupSelect;
