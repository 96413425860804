import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { ReactComponent as Clock } from '../../../../../assets/icons/clock.svg';
import { DivergenciaKpi } from './DivergenciaKpi';

const query = loader('../../queries/GetTempoMedioAtendimento.gql');

const KpiTempoMedioAtendimento: FunctionComponent = () => {
  const { loading, data } = useQuery<{
    getTempoMedioAtendimentoDiasForLoggedUser: number;
  }>(query);
  let value = '--';
  if (data && data.getTempoMedioAtendimentoDiasForLoggedUser) {
    value =
      data?.getTempoMedioAtendimentoDiasForLoggedUser.toPrecision(2) + ' dias';
  }
  return (
    <DivergenciaKpi
      label='Tempo médio de atendimento'
      value={value}
      icon={<Clock className='iconPrimary500' width={48} height={48}></Clock>}
      loading={loading}
    ></DivergenciaKpi>
  );
};

export default KpiTempoMedioAtendimento;
