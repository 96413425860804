import React from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import {
  buildMultiploMaterialInfoComponent,
  TecidoProps,
} from '../../../template/TecidoQuantidadeStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import { createBackHandler } from '../../../template/utils';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import AjusteDeGastoTecido from './AjusteDeGastoTecido';

import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

const produtoQuery = loader('../../../../../../product-detail/divergencias/queries/getProdutoPPOP.gql')

const AjusteDeGastoTecidoFinalStep: TipoDivergenciaComponent<TecidoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = createBackHandler(onBack, AjusteDeGastoTecido);

  const { idProducaoPppOp } = useParams<{ idProducaoPppOp: string }>();

  type itemProducaoPPPOPQuery = {
    id_ordem_producao: number;
    Producao_Prog_Prod: {
      Produto_Cor: {
        Produto: {
          id_produto: number;
          produto: string;
          Marca: {
            id_marca: number;
            id_marca_linx: number;
            marca: string;
          }
        };
      };
    };
  };

  const {
    data,
  } = useQuery<{
    findOneProducao_PPP_OP: itemProducaoPPPOPQuery;
  }>(produtoQuery, {
    variables: {
      idProducaoPppOp: parseInt(idProducaoPppOp, 10),
    },
  });


  let id_marca = 0;
  if (data) {
    id_marca = data.findOneProducao_PPP_OP.Producao_Prog_Prod?.Produto_Cor?.Produto?.Marca?.id_marca_linx || 0

  }



  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      id_marca === 2 ? AreaTipoDivergenciaEnum.CONTROLE_PCP : AreaTipoDivergenciaEnum.PLANNER,
      //AreaTipoDivergenciaEnum.PLANNER,
      'Ajuste de gasto do tecido'
    ),
    ...buildMultiploMaterialInfoComponent(payload),
  ];
  const key = TipoDivergenciasKey.MP_AJUSTE_DE_GASTO_DO_TECIDO;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(
          step,
          payload.tecidos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.TECIDO,
            value: item.id.toString(),
            adicionalInfo: item.adicionalInfo,
          }))
        );
      }}
      progressValue={90}
      stepNumber={'3'}
      infoComponents={infoComponents}
    />
  );
};

export default AjusteDeGastoTecidoFinalStep;
