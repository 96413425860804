// libs
import React, { useState, ReactNode } from 'react'
import { NotificationsContext } from './context'

interface DivergenceContextProps {
  children: ReactNode;
}

function NotificationsResolver({ children }: DivergenceContextProps) {
  const [refetch, setRefetch] = useState<boolean>(false)

  return (
    <NotificationsContext.Provider
      value={{
        refetch,
        setRefetch
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export { NotificationsResolver }
