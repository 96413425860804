import { Button, CircularProgress, ThemeProvider } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { loaderTheme } from '../../../support/Utils';

const InputRow: FunctionComponent<{
  title: string;
  subTitle: string;
  onSave: Function;
  loading?: boolean;
}> = (props) => (
  <div
    style={{
      width: '80%',
      justifyContent: 'space-between',
    }}
    className='d-flex align-item-center'
  >
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <div className='body2 medium neutral800'>{props.title}</div>
      <div
        className='overline bold neutral400'
        style={{
          marginLeft: 2,
        }}
      >
        {props.subTitle}
      </div>
    </div>
    <div
      style={{
        width: '40%',
      }}
    >
      {props.children}
    </div>
    <div className='ml8' style={{ width: '10%' }}>
      <Button
        variant='contained'
        className='backgroundPrimary500'
        onClick={() => props.onSave()}
        disabled={props.loading}
        fullWidth
      >
        {props.loading ? (
          <ThemeProvider theme={loaderTheme}>
            <CircularProgress size={25} color='primary'></CircularProgress>
          </ThemeProvider>
        ) : (
          <span className='text-transform-initial body2 bold primary100'>
            Salvar
          </span>
        )}
      </Button>
    </div>
  </div>
);

export default InputRow;
