import { useMutation, useQuery, gql } from '@apollo/client';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState, useContext } from 'react';
import DivergenciaStatusEnum from '../../../../../support/enums/DivergenciaStatusEnum';
import LabelValueBase from '../../nova-divergencia/components/LabelValueBase';
import { DivergenciaType } from '../DivergenciaDetailTypes';
import { UserContext, UserContextType } from '../../../../../context';
import { isSupplier } from "../../../../../tools";
import { ConfiguracoesKeys } from '../../../../../support/enums/Configuracoes';
import { useConfiguracaoByKey } from '../../../../../support/UseConfiguracao';

const CategoriaAreaRespSecundariaQuery = loader(
    '../../queries/getAllCategoriaAreaRespSecundaria.gql'
);


const makeUpdateCategoriaAreaRespSecundariaGQL = () => {
    return gql`
    mutation updateCategoriaAreaRespSecundariaDivergencia(
        $idDivergencia: Int!
        $novaCategoriaAreaRespSecundariaDivergenciaId: Int!
      ) {
        updateCategoriaAreaRespSecundariaPLM_Colaborativo_Divergencias(
          idDivergencia: $idDivergencia
          novaCategoriaAreaRespSecundariaDivergenciaId: $novaCategoriaAreaRespSecundariaDivergenciaId
        ) {
          id_divergencia
        }
      }
      
    `;
};


const CategoriaAreaRespSecundaria: FunctionComponent<{
    divergencia: DivergenciaType;
    onStatusChange: Function;
}> = ({ divergencia, onStatusChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { userLogged } = useContext<UserContextType>(UserContext);
    const disabledForm = isSupplier(userLogged?.id_grupo || null);
    const { data } = useConfiguracaoByKey(ConfiguracoesKeys.RESPONSAVEL_AREA_SECUNDARIA);

    const checkUserSecondaryResponsibility = (idUsuario?: number): boolean => {
        let usersSecondaryRespArea: number[] = [];

        if (data && data.findOnePLM_Colaborativo_Configuracoes) {
            usersSecondaryRespArea = JSON.parse(
                data.findOnePLM_Colaborativo_Configuracoes.valor
            ) as number[];
        }
        if (idUsuario) {
            return idUsuario ? usersSecondaryRespArea.includes(idUsuario) : false;
        }
        return false;
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [save, { loading: loadingSave }] = useMutation(
        makeUpdateCategoriaAreaRespSecundariaGQL()
    );

    const [loadingId, setLoadingId] = useState(0);

    const changeCategoriaAreaRespSecundaria = (novaCategoriaAreaRespSecundariaDivergenciaId: number) => {
        if (loadingSave) return;
        setLoadingId(novaCategoriaAreaRespSecundariaDivergenciaId);
        save({
            variables: {
                idDivergencia: divergencia.id_divergencia,
                novaCategoriaAreaRespSecundariaDivergenciaId: novaCategoriaAreaRespSecundariaDivergenciaId,
            },
        }).then(() => {
            handleClose();
            onStatusChange();
            setLoadingId(0);
        });
    };

    const { loading: loadingCategoriaAreaRespSecundaria, data: dataCategoriaAreaRespSecundaria } = useQuery<{
        getAll_PLM_Colaborativo_Categoria_Area_Resp_Secundaria: {
            id_categoria: number;
            categoria: string;
        }[];
    }>(CategoriaAreaRespSecundariaQuery);

    const statusFinalizado = divergencia.status === DivergenciaStatusEnum.FINALIZADO;

    return (
        <> {divergencia.AreaRespSecundaria && !disabledForm && checkUserSecondaryResponsibility(userLogged?.idUsuario) &&
            <LabelValueBase label='Categoria Área Responsável Secundária'>
                <div>
                    <span className='mt8 body2 semi-bold neutral700'>
                        {divergencia?.CategoriaAreaRespSecundaria != null ? divergencia?.CategoriaAreaRespSecundaria?.categoria : 'Não definida'}
                    </span>

                    {!loadingCategoriaAreaRespSecundaria && !statusFinalizado && (
                        <Button onClick={handleClick}>
                            <span className='overline bold neutral400'>ALTERAR</span>
                        </Button>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        id='CategoriaAreaRespSecundaria-menu'
                        keepMounted
                        onClose={handleClose}
                        open={Boolean(anchorEl)}
                    >
                        {dataCategoriaAreaRespSecundaria?.getAll_PLM_Colaborativo_Categoria_Area_Resp_Secundaria.map((CategoriaAreaRespSecundaria) => {
                            const CategoriaAreaRespSecundariaFinalizado = CategoriaAreaRespSecundaria.categoria === "Finalizado Automaticamente";

                            return (
                                <MenuItem
                                    style={CategoriaAreaRespSecundariaFinalizado ? { display: 'none' } : { display: 'block' }}
                                    disabled={
                                        divergencia.CategoriaAreaRespSecundaria?.id_categoria === CategoriaAreaRespSecundaria.id_categoria
                                    }
                                    onClick={() => {
                                        changeCategoriaAreaRespSecundaria(
                                            CategoriaAreaRespSecundaria.id_categoria
                                        );
                                    }}
                                    key={`CategoriaAreaRespSecundaria${CategoriaAreaRespSecundaria.id_categoria}`}
                                >
                                    {loadingSave &&
                                        loadingId === CategoriaAreaRespSecundaria.id_categoria ?
                                        (
                                            <div style={{ textAlign: 'center', width: '100%' }}>
                                                <CircularProgress
                                                    color='inherit'
                                                    size={18}
                                                ></CircularProgress>
                                            </div>
                                        ) : (
                                            <span>
                                                {CategoriaAreaRespSecundaria.categoria}
                                            </span>
                                        )
                                    }
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            </LabelValueBase>
        }
        </>
    );
};

export default CategoriaAreaRespSecundaria;
