import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { gql, loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import { loaderTheme } from '../../../support/Utils';
import { User } from '../components/InputUser';
import LoadingConfig from '../components/LoadingConfig';

const getAssistentesCad = gql`
  query getAssistenteCad {
    findManyAssistente_Cad(orderBy: { assistente_cad: asc }) {
      assistente_cad
      Usuario {
        login
        id_usuario
      }
    }
  }
`;
const headerClass = 'caption bold neutral500 uppercase';

const updateUserAssistenteCad = gql`
  mutation updateAssistenteCad($idUsuario: Float!, $responsavel: String!) {
    updateOneAssistente_Cad(
      where: { assistente_cad: $responsavel }
      data: { Usuario: { connect: { id_usuario: $idUsuario } } }
    ) {
      Usuario {
        login
      }
    }
  }
`;

const ResponsavelRiscosRow: FunctionComponent<{
  row: AssistenteCadType;
  users: User[];
}> = ({ row, users }) => {
  const selectedUser =
    users.find((user) => {
      return user.id_usuario === row.Usuario?.id_usuario;
    }) || ({} as User);

  const [value, setValue] = useState<User>(selectedUser);
  const [save, { loading }] = useMutation(updateUserAssistenteCad);
  return (
    <TableRow>
      <TableCell>{row.assistente_cad}</TableCell>
      <TableCell>
        <Autocomplete
          options={users}
          value={value}
          closeIcon={null}
          onChange={(_, newValue) => {
            setValue(newValue || ({} as User));
          }}
          getOptionSelected={(option, value) =>
            option.id_usuario === value.id_usuario
          }
          getOptionLabel={(option) => {
            return option.login || '';
          }}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} variant='outlined' size='small' fullWidth />
          )}
        />
      </TableCell>
      <TableCell>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            save({
              variables: {
                idUsuario: value.id_usuario,
                responsavel: row.assistente_cad,
              },
            });
          }}
          disabled={loading}
          fullWidth
        >
          {loading ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Salvar
            </span>
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

type AssistenteCadType = {
  assistente_cad: string;
  Usuario?: User;
};

const queryUsers = loader('../../../support/queries/GetUserForMentions.gql');

const ResponsavelRiscos: FunctionComponent = () => {
  const {
    loading: loadingAssistentes,
    data = { findManyAssistente_Cad: [] },
  } = useQuery<{
    findManyAssistente_Cad: AssistenteCadType[];
  }>(getAssistentesCad);

  const {
    loading: loadingUsers,
    data: dataUsers = { findManyUsuario: [] },
  } = useQuery<{
    findManyUsuario: User[];
  }>(queryUsers);

  const loading = loadingAssistentes || loadingUsers;
  return (
    <div style={{ width: '50%' }}>
      {loading ? (
        <LoadingConfig></LoadingConfig>
      ) : (
        <div>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow className='tableHeader'>
                <TableCell className={headerClass}>Assistente Cad</TableCell>
                <TableCell className={headerClass}>Usuário</TableCell>
                <TableCell className={headerClass}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='backgroundNeutral50'>
              {data.findManyAssistente_Cad.map((item) => {
                return (
                  <ResponsavelRiscosRow
                    key={`responsavel-${item.assistente_cad}`}
                    row={item}
                    users={dataUsers.findManyUsuario}
                  ></ResponsavelRiscosRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default ResponsavelRiscos;
