import React, { useContext } from "react";
import AreaTipoDivergenciaEnum from "../../../AreaTipoDivergenciaEnum";
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from "../../../template/FinalStepTemplate";
import {
  buildMultiploMaterialInfoComponent,
  TecidoProps,
} from "../../../template/TecidoQuantidadeStepTemplate";
import useBuildSubmitHandler from "../../../template/UseBuildSubmitHandler";
import { createBackHandler } from "../../../template/utils";
import TipoDivergenciasInfoKey from "../../../TipoDivergenciasInfoKey.";
import TipoDivergenciasKey from "../../../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../../../TiposDeDivergencias";

import {
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  PictureAsPdf as PdfIcon,
  InsertDriveFile as FileIcon,
} from "@material-ui/icons";
import {
  DivergenceContext,
  DivergenceContextType,
  FileProps,
} from "../../../../../../../context";
import { DELETE_FILE_MUTATION } from "../../../template/UploadStepTemplate";
import { useMutation } from "@apollo/client";
import { UploadQualidadeTecidoStep } from "../../upload-step";

const QualidadeDoTecidoFinalStep: TipoDivergenciaComponent<TecidoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = createBackHandler(
    onBack,
    UploadQualidadeTecidoStep as TipoDivergenciaComponent
  );

  const {
    files: filesDivergencia,
    truncateString,
    formatFileSize,
    setFiles,
    files,
  } = useContext<DivergenceContextType>(DivergenceContext);
  const [deleteFile] = useMutation(DELETE_FILE_MUTATION);

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.QUALIDADE_MP,
      "Qualidade do tecido"
    ),
    ...buildMultiploMaterialInfoComponent(payload),
    ...filesDivergencia.map((file: FileProps, index) => (
      <ListItem key={index} button>
        <ListItemIcon>
          {file.type === "pdf" ? <PdfIcon /> : <FileIcon />}
        </ListItemIcon>
        <ListItemText
          primary={truncateString(file.name as string, 18)}
          secondary={formatFileSize(file.size ? file.size : 0)}
        />
        <IconButton edge="end">
          <Typography
            variant="caption"
            color="primary"
            onClick={() => {
              const variables = { variables: { filename: file.fileId } };

              deleteFile(variables)
                .then((message) => {
                  setFiles(
                    files.filter((fileOld) => fileOld.path !== file.path)
                  );
                })
                .catch((error) => console.log(error));
            }}
          >
            Deletar
          </Typography>
        </IconButton>
      </ListItem>
    )),
  ];
  const key = TipoDivergenciasKey.MP_QUALIDADE_DO_TECIDO;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      infoMessage={
        <span>
          Envie uma foto em anexo para obter uma resposta mais objetiva.
        </span>
      }
      onSetStep={(step) => {
        handleSubmit(
          step,
          payload.tecidos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.TECIDO,
            value: item.id.toString(),
            filesDivergencia: filesDivergencia,
          }))
        );
      }}
      progressValue={90}
      stepNumber={"3"}
      infoComponents={infoComponents}
    />
  );
};

export default QualidadeDoTecidoFinalStep;
