import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import ContinueOrBackActions from '../../../components/ContinueOrBackActions';
import LabelValueBase from '../../../components/LabelValueBase';
import NovaDivergenciaProgressBar from '../../../components/NovaDivergenciaProgressBar';
import StepperHeader from '../../../components/StepperHeader';
import { MaterialSelectItem } from '../../../template/UseGetMateriais';
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from '../../../TiposDeDivergencias';
import RiscoErrado from './RiscoErrado';
import UploadRiscoErradoStep from './UploadFileStep';

export interface RiscoErradoProps extends TipoDivergenciaComponentProps {
  payload: { tecidos: MaterialSelectItem[], largura: string  };
}
interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      maxLength={10}
      decimalScale={0}
      getInputRef={inputRef}
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name.split('.').join(),
            value: values.value.split('.').join(),
          },
        });
      }}
      suffix=' cm'
    />
  );
}
const RiscoErradoLarguraStep: TipoDivergenciaComponent<RiscoErradoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const [largura, setLargura] = useState('');
  const isDisabledButton = () => !largura;
  const handleContinue = () => {
    const nextPayload = {
      largura: largura,
      tecidos: payload.tecidos,
    };

    onSetStep({
      component: UploadRiscoErradoStep as TipoDivergenciaComponent,
      payload: nextPayload,
    });
  };

  const onBackHandler = () => {
    onBack({
      component: RiscoErrado,
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLargura(event.target.value);
  };
  const stepNumber = '3';
  const stepSubTitle = 'Digite abaixo a largura (em cm) do tecido recebido.';
  const stepTitle = 'Qual a largura do tecido com o risco errado?';
  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={66}></NovaDivergenciaProgressBar>
      <DialogContent
        className='backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth'
        style={{
          padding: '0 32px',
        }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div className='px16'>
          <form
            autoComplete='off'
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom as any
              }}
              name='numberformat'
              onChange={handleChange}
              placeholder='Digite a largura do tecido.'
              variant='outlined'
            />
          </form>
          <div className='mt32'>
            <LabelValueBase label='Tecido selecionado'>
              <div>
                <span className='mt8 body2 semi-bold neutral700'>
                  {payload.tecidos.map((item) => item.text).join(', ')}
                </span>
                <Button onClick={onBackHandler}>
                  <span className='caption bold neutral300'>ALTERAR</span>
                </Button>
              </div>
            </LabelValueBase>
          </div>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className='divergenciaMaxWidth'
        style={{ padding: '30px 32px' }}
      >
        <ContinueOrBackActions
          continueMessage='Continuar'
          disabled={isDisabledButton()}
          disabledMessage='Continuar'
          onBack={onBackHandler}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  );
};

export default RiscoErradoLarguraStep;
