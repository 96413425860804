import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import { loaderTheme } from '../../../support/Utils';
import { User } from './InputUser';

const queryUsers = loader('../../../support/queries/GetUserForMentions.gql');
const createUserMutation = gql`
  mutation createUser(
    $userId: Float!
    $equipeId: Int!
    $isLider: Boolean!
    $recebeNotificacao: Boolean!
  ) {
    createOnePLM_Colaborativo_Usuario_Equipe(
      data: {
        Usuario: { connect: { id_usuario: $userId } }
        Equipe: { connect: { id_equipe: $equipeId } }
        is_lider: $isLider
        recebe_notificao_equipe: $recebeNotificacao
      }
    ) {
      id_usuario_equipe
    }
  }
`;

const NewEquipeUser: FunctionComponent<{
  equipeId: Number;
  onUpdate: Function;
}> = ({ onUpdate, equipeId }) => {
  const {
    loading: loadingUsers,
    data: dataUsers = { findManyUsuario: [] },
  } = useQuery<{
    findManyUsuario: User[];
  }>(queryUsers);

  const [user, setUser] = useState<User>({} as User);
  const [isLider, setIsLider] = useState(false);
  const [recebeNotificacao, setRecebeNotificacao] = useState(false);

  const [save, { loading: loadinSave }] = useMutation(createUserMutation);

  const isLoading = loadingUsers || loadinSave;

  return (
    <div
      className='d-flex align-item-center mt16'
      style={{
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ width: '25%' }}>
        <Autocomplete
          id='user'
          options={dataUsers.findManyUsuario}
          value={user}
          closeIcon={null}
          onChange={(_, newLiderado) => {
            setUser(newLiderado || ({} as User));
          }}
          getOptionSelected={(option, value) =>
            option.id_usuario === value.id_usuario
          }
          getOptionLabel={(option) => {
            return option.login || '';
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              label='Usuário'
              fullWidth
            />
          )}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLider}
              onChange={() => {
                setIsLider(!isLider);
              }}
            />
          }
          label='Lider'
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={recebeNotificacao}
              onChange={() => {
                setRecebeNotificacao(!recebeNotificacao);
              }}
            />
          }
          label='Recebe notificações da equipe'
        />
      </div>
      <div style={{ width: '11%' }}></div>
      <div className='ml8' style={{ width: '10%' }}>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            save({
              variables: {
                userId: user.id_usuario,
                equipeId: equipeId,
                isLider,
                recebeNotificacao,
              },
            }).then(() => {
              setUser({} as User);
              setIsLider(false);
              setRecebeNotificacao(false);
              onUpdate();
            });
          }}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Salvar
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default NewEquipeUser;
