export enum ConfiguracoesKeys {
  RESPONSAVEL_DEFAULT_CONTROLE_PCP = 'RESPONSAVEL_DEFAULT_CONTROLE_PCP',
  RESPONSAVEL_DEFAULT_CONTROLE_PCP_MARCA_ANIMALE = 'RESPONSAVEL_DEFAULT_CONTROLE_PCP_MARCA_ANIMALE',
  RESPONSAVEL_DEFAULT_CONTROLE_PCP_MARCA_FARM = 'RESPONSAVEL_DEFAULT_CONTROLE_PCP_MARCA_FARM',
  URL_INDICADOR_DIVERGENCIAS = 'URL_INDICADOR_DIVERGENCIAS',
  USUARIOS_TESTE = 'USUARIOS_TESTE',
  USUARIOS_ADMINISTRADORES = 'USUARIOS_ADMINISTRADORES',
  URL_PLM = 'URL_PLM',
  RESPONSAVEL_DEFAULT_MODELAGEM_FFF = 'RESPONSAVEL_DEFAULT_MODELAGEM_FFF',
  RESPONSAVEL_DEFAULT_MODELAGEM_AAFM = 'RESPONSAVEL_DEFAULT_MODELAGEM_AAFM',
  RESPONSAVEL_CONTROLE_PCP_CORTE_EXTERNO = 'RESPONSAVEL_CONTROLE_PCP_CORTE_EXTERNO',
  RESPONSAVEL_AREA_SECUNDARIA = 'RESPONSAVEL_AREA_SECUNDARIA'
}

export enum ConfiguracoesTipo {
  STRING = 'STRING',
  INT = 'INT',
  JSON_ARRAY = 'JSON_ARRAY',
}
