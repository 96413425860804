import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import StatusDivergenciaEnum from '../../../../support/enums/DivergenciaStatusEnum';

const query = loader('../../queries/GetCountDIvergenciasKpi.gql');

export const useGetDIvergenciasStatusKpi = (status: StatusDivergenciaEnum) => {
  const { loading, data } = useQuery<{
    getCountDivergenciasKPIForLoggedUser: number;
  }>(query, {
    variables: {
      status: status,
    },
  });

  return {
    loading: loading,
    data,
  };
};
