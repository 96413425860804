import { useMutation } from '@apollo/client';
import { TextField } from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import InputRow from './InputRow';

const updateQuery = loader('../queries/updateConfiguracao.gql');

const InputTextField: FunctionComponent<{
  title: string;
  subTitle: string;
  configKey: string;
  initialValue: string;
}> = ({ title, subTitle, configKey, initialValue }) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const [save, { loading }] = useMutation(updateQuery);

  return (
    <InputRow
      title={title}
      loading={loading}
      subTitle={subTitle}
      onSave={() => {
        save({
          variables: {
            key: configKey,
            novoValor: value,
          },
        });
      }}
    >
      <TextField
        fullWidth
        onChange={handleChange}
        size='small'
        variant='outlined'
        value={value}
      />
    </InputRow>
  );
};

export default InputTextField;
