import { useMutation } from '@apollo/client';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import InputRow from './InputRow';

const updateQuery = loader('../queries/updateConfiguracao.gql');

export type User = {
  id_usuario: number;
  login: string;
};

const InputUser: FunctionComponent<{
  title: string;
  subTitle: string;
  configKey: string;
  initialValue: number;
  users: User[];
}> = ({ title, subTitle, configKey, initialValue, users }) => {
  const selectedUser =
    users.find((user) => user.id_usuario === initialValue) || ({} as User);
  const [value, setValue] = useState<User>(selectedUser);
  const [save, { loading }] = useMutation(updateQuery);

  return (
    <InputRow
      title={title}
      loading={loading}
      subTitle={subTitle}
      onSave={() => {
        save({
          variables: {
            key: configKey,
            novoValor: value.id_usuario.toString(),
          },
        });
      }}
    >
      <Autocomplete
        options={users}
        value={value}
        closeIcon={null}
        onChange={(_, newValue) => {
          setValue(newValue || ({} as User));
        }}
        getOptionSelected={(option, value) =>
          option.id_usuario === value.id_usuario
        }
        getOptionLabel={(option) => {
          return option.login || '';
        }}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} variant='outlined' size='small' fullWidth />
        )}
      />
    </InputRow>
  );
};

export default InputUser;
