import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createUploadLink } from 'apollo-upload-client';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserContextProvider } from './app/context/user';
import ErrorPage from './app/pages/ErrorPage';
import ErrorHandler from './ErrorHandler';
import './firebaseInit';
import './index.css';
import { networkStatusLink } from './networkStatusNotifier';
import * as serviceWorker from './serviceWorker';
import './style/colors.css';
import './style/fonts.css';
import './style/margins.css';
import './style/paddings.css';
import './style/table.css';
import './style/utils.css';

const { version } = require('../package.json');

Sentry.init({
  dsn:
    'https://3c81e532eacc4d90b09802a3d74154f2@o465044.ingest.sentry.io/5476641',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV || 'production',
  release: 'plm-colab-front@' + version,
  enabled: process.env.NODE_ENV !== 'development',
  tracesSampleRate: 1.0,
});

const authDefault = '';
const userToken = localStorage.getItem('auth') || authDefault;

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_GRAPHQL_URL || 'http://localhost:4000/graphql',

  headers: {
    authorization: userToken,
  },
});

const apolloClient = new ApolloClient({
  link: networkStatusLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

const theme = createMuiTheme({
  overrides: {
    MuiListItem: {
      divider: {
        borderBottom: '2px solid #F4F3F3',
      },
    },
    MuiDivider: {
      root: {
        height: '2px',
        background: '#F4F3F3',
        backgroundColor: 'none',
      },
    },
    MuiButton: {
      outlined: {
        '&$disabled': {
          border: '2px solid #F4F3F3',
          borderRadius: '4px',
        },
      },
    },
  },
});

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={({ resetError }) => (
      <ErrorPage
        onReload={() => {
          resetError();
        }}
      ></ErrorPage>
    )}
    showDialog
    dialogOptions={{
      title: 'Parece que estamos tendo problemas.',
      subtitle: 'Nossa equipe foi notificada.',
      subtitle2:
        'Se você gostaria de ajudar, conte-nos o que aconteceu abaixo.',
      labelName: 'Nome',
      labelEmail: 'E-mail',
      labelComments: 'O que aconteceu?',
      labelClose: 'Fechar',
      labelSubmit: 'Enviar',
      errorFormEntry: 'Campos obrigatórios.',
      successMessage: 'Seu feedback foi enviado. Obrigado!',
    }}
  >
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5}>
          <ErrorHandler></ErrorHandler>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
