import React, { FunctionComponent } from 'react';

export const LabelValueCell: FunctionComponent<{
  value?: String | null;
  label: String;
}> = ({ value, label }) => {
  return (
    <div>
      <div className='body2 neutral800'>{value || 'Não informado'}</div>
      <div className='caption medium neutral500'>{label}</div>
    </div>
  );
};
