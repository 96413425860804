import React, { FunctionComponent } from 'react';
import { ReactComponent as Warning } from '../../../../../assets/icons/warning.svg';
import StatusDivergenciaEnum from '../../../../support/enums/DivergenciaStatusEnum';
import { DivergenciaKpi } from './DivergenciaKpi';
import { useGetDIvergenciasStatusKpi } from './UseGetDIvergenciasStatusKpi';

const KpiNovasDivergencias: FunctionComponent = () => {
  const { loading, data } = useGetDIvergenciasStatusKpi(
    StatusDivergenciaEnum.NOVO
  );
  return (
    <DivergenciaKpi
      label='Novas divergências'
      value={data?.getCountDivergenciasKPIForLoggedUser.toString()}
      icon={
        <Warning className='iconPrimary500' width={48} height={48}></Warning>
      }
      loading={loading}
    ></DivergenciaKpi>
  );
};

export default KpiNovasDivergencias;
