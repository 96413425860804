export enum StatusReminder {
 EXPIRED = 'EXPIRED',
 ON_TIME = 'ON_TIME'
}

export interface NotificationsData {
id_lembrete: number
id_divergencia: number
data_lembrete: Date
visualizado: boolean
created_at: Date
statusRemider: StatusReminder
Divergencia: {
    id_divergencia: number
    status: string
    TipoDivergencia: {
        id_tipo_divergencia: number
        tipo: string
    }
}
}

export interface ListNotificationData {
  getUnviewedRemindersByUser: NotificationsData[]
}
