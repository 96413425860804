import { gql } from '@apollo/client'

export const LIST_NOTIFICATION = gql`
  query GetUnviewedRemindersByUser {
    getUnviewedRemindersByUser {
        id_lembrete
        id_divergencia
        data_lembrete
        visualizado
        created_at
        statusRemider
        Divergencia {
            id_divergencia
            status
            TipoDivergencia {
                id_tipo_divergencia
                tipo
            }
        }
    }
  }
`