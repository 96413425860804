import {
  Button,
  DialogActions,
  DialogContent,
  Divider
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import GrupoDivergenciaAccordion from "./components/GrupoDivergenciaAccordion";
import NovaDivergenciaProgressBar from "./components/NovaDivergenciaProgressBar";
import StepperHeader from "./components/StepperHeader";
import {
  gruposTipoDivergencia,
  GrupoTipoDivergencia,
  TipoDivergencia,
  TipoDivergenciaComponent,
} from "./TiposDeDivergencias";
import { useParams } from "react-router-dom";
import { FindMarca } from "./FindMarca";
import { DivergenceContext, DivergenceContextType, UserContext, UserContextType } from "../../../../context";
import { useLoggedUser } from "../../../../support/UseLoggedUser";
import { useGetDivergence } from "../hooks/UseGetDivergence.hook";
import { FeedbackType } from "../../../../shared";
import FeedbackSnackbar from "../../../../shared/components/UI/FeedbackSnackbar/FeedbackSnackbar";
import { isSupplier } from "../../../../tools";

export type SelectedType = {
  grupo: GrupoTipoDivergencia;
  tipo: TipoDivergencia;
};

function isDisabledButton(selected: SelectedType): boolean | undefined {
  return selected.grupo.title === defaultSelected.grupo.title;
}

const defaultSelected = {
  grupo: { title: "Default", tipos: [], hideCategory: [] },
  tipo: { title: "", component: () => <div />, hideSubCategory: [] },
};

interface DivergenceData {
  isPA?: boolean | undefined
  corteInterno?: boolean
  devInterno?: boolean
  digitalizacaoInterna?: boolean
  desenvolvimento?: string
}

const INITIAL_VALUE_FOR_FEEDBACK: FeedbackType = {
  message: "",
  show: false,
  type: "info",
};

const SelectTipoDivergenciaStep: TipoDivergenciaComponent = ({ onSetStep }) => {
  const [selected, setSelected] = useState<SelectedType>(defaultSelected);
  const [feedback, setFeedback] = useState<FeedbackType>(INITIAL_VALUE_FOR_FEEDBACK);

  const { idProducaoPppOp } = useParams<{ idProducaoPppOp: string }>();
  const { setTypeOfDivergence, setIdProducaoPppOp } = useContext<DivergenceContextType>(DivergenceContext);
  const { userLogged } = useContext<UserContextType>(UserContext);
  const { fetchDivergence,  divergenceData: divergenceResult } = useGetDivergence(idProducaoPppOp);
  const { data: loggedUserData } = useLoggedUser();


  const handleTipoDivergenciaClick = (grupo: GrupoTipoDivergencia, tipo: TipoDivergencia) => {
    return () => {
      setSelected({
        grupo,
        tipo,
      });
    };
  };

  let id_marca = FindMarca({
    idProducaoPppOp: idProducaoPppOp,
  });

  const handleCloseSnackbar = () => {
    setFeedback(INITIAL_VALUE_FOR_FEEDBACK);
  };

  useEffect(() => {
    if(idProducaoPppOp) fetchDivergence()
  //eslint-disable-next-line
  }, [idProducaoPppOp])

  const divergenceData = useMemo <DivergenceData | null>(() => {
    if (divergenceResult) {
      return {
        isPA: divergenceResult.Ordem_Producao?.is_pa,
        corteInterno: divergenceResult.Producao_Prog_Prod.Produto_Cor.Produto.corte_interno,
        devInterno: divergenceResult.Producao_Prog_Prod.Produto_Cor.Produto.dev_interno,
        digitalizacaoInterna: divergenceResult.Producao_Prog_Prod.Produto_Cor.Produto.digitalizacao_interna,
        desenvolvimento: divergenceResult.Producao_Prog_Prod.Produto_Cor.Produto.desenvolvimento,
      }
    } 

    return null
  }, [divergenceResult])

  const validateTypeDivergence = () => {
    const { title } = selected.tipo;

    const {
      corteInterno,
      devInterno,
      digitalizacaoInterna,
      isPA,
      desenvolvimento
    } = divergenceData || {};
      const atelierSP = 'INTERNO - ATELIE ANML SP'
    

    const isComplementAviation = title === 'Complemento de grade - Aviamento';
    const isIncorrectCut = title === 'Corte incorreto';
    const isPendingCut = title === 'Pendentes partes do corte';
    const isAdjustFabricExpense = title === 'Ajuste de gasto do tecido';
    const isRiskProblem = ['Problema no risco', 'Risco errado', 'Risco não enviado'].includes(title);
    const isErrorModeling = ['Erro de modelagem', 'Dúvida de montagem'].includes(title);
    const isMissingPattern = title === 'Falta de Molde';
    const isFabricQuality = title === 'Qualidade do tecido';
    const isReceivedCutMissingAviation = title === 'Recebeu corte, faltou aviamento';
    const isReceivedAviationMissingCut = title === 'Recebeu aviamento, faltou corte';
    const isMissingFabric = title === 'Falta de tecido';
    const validIsPa = isFabricQuality || isReceivedAviationMissingCut || isReceivedCutMissingAviation || isIncorrectCut || isErrorModeling || isMissingPattern || isMissingFabric

    if(isComplementAviation && corteInterno) return true

    if((isIncorrectCut || isPendingCut) && !corteInterno) return true

    if((isPendingCut || isAdjustFabricExpense) && (!digitalizacaoInterna)) return true

    if(isRiskProblem && (!digitalizacaoInterna && (desenvolvimento !== atelierSP))) return true

    if(isErrorModeling && !devInterno) return true

    if((isMissingPattern || isErrorModeling) && (!devInterno && !digitalizacaoInterna)) return true

    if(validIsPa && isPA) return true

    return false
  }

  const handleSubmit = () => {
    if(validateTypeDivergence() && isSupplier(userLogged?.id_grupo || null)) {
       setFeedback({
        message: 'Não é possível abrir esse tipo de chamado para está OP.',
        show: true,
        type: "error",
      });

      return
    } 

    const type = selected.tipo.title.toString();
    setTypeOfDivergence("");
    setTypeOfDivergence(type || "");
    setIdProducaoPppOp(idProducaoPppOp)
    onSetStep(selected.tipo);
  }

  const stepNumber = "1";
  const stepSubTitle = "Selecione o tipo de divergência encontrado.";
  const stepTitle = "Tipo da divergência";
  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={16}></NovaDivergenciaProgressBar>
      <DialogContent
        className="backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth"
        style={{ padding: "0 32px" }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div>
          {gruposTipoDivergencia.map((grupo, indexGrupo) => {
            let showCategory = grupo.hideCategory.includes(id_marca);

            if (
              loggedUserData &&
              loggedUserData.getLoggedUser &&
             (
              grupo.title === "Planejamento de Demanda" ||
              grupo.title === "Arquivo Cód.Barras (demanda interna)" ||
              grupo.title === "Ajuste de matéria prima" ||
              grupo.title === "Anomalia" 
              ) && 
              loggedUserData.getLoggedUser.id_grupo === 14 &&
              loggedUserData.getLoggedUser.admin !== true
            ) {
              showCategory = false;
            }

            if (showCategory)
              return (
                <GrupoDivergenciaAccordion
                  grupo={grupo}
                  key={`grupo-${indexGrupo}`}
                  onClick={handleTipoDivergenciaClick}
                  selected={selected}
                ></GrupoDivergenciaAccordion>
              );
            else return <></>;
          })}
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        style={{ padding: "30px 32px" }}
        className="divergenciaMaxWidth"
      >
        {isDisabledButton(selected) ? (
          <Button
            disabled
            fullWidth
            style={{ padding: "16px" }}
            variant="outlined"
          >
            <span className="text-transform-initial neutral500 bold">
              Selecione para continuar
            </span>
          </Button>
        ) : (
          <Button
            className="backgroundPrimary500"
            fullWidth
            style={{ padding: "16px", height: "60px" }}
            onClick={handleSubmit}
          >
            <span className="text-transform-initial neutral000 bold">
              Continuar
            </span>
          </Button>
        )}
      </DialogActions>
      <FeedbackSnackbar feedback={feedback} onClose={handleCloseSnackbar} />
    </React.Fragment>
  );
};

export default SelectTipoDivergenciaStep;
