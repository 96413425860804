import React, { useContext } from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import LabelValueComponent from '../../../components/LabelValueComponent';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import {
  TipoDivergenciaComponent
} from '../../../TiposDeDivergencias';
import { DivergenceContext, DivergenceContextType, FileProps } from '../../../../../../../context';
import { useMutation } from '@apollo/client';
import { DELETE_FILE_MUTATION } from '../../../template/UploadStepTemplate';
import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { PictureAsPdf as PdfIcon, InsertDriveFile as FileIcon } from '@material-ui/icons';
import RiscoErradoLarguraStep, { RiscoErradoProps } from './RiscoErradoLarguraStep';

const RiscoErradoFinalStep: TipoDivergenciaComponent<RiscoErradoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = () => {
    onBack({
      component: RiscoErradoLarguraStep as TipoDivergenciaComponent,
      payload: {
        tecidos: payload.tecidos,
        largura: payload.largura,
      },
    });
  };

  const { files: filesDivergencia, truncateString, formatFileSize, setFiles, files } = useContext<DivergenceContextType>(DivergenceContext);
  const [deleteFile] = useMutation(DELETE_FILE_MUTATION);

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.RISCO,
      'Risco está errado'
    ),
    <LabelValueComponent
      label='Tecido'
      value={payload.tecidos.map((item) => item.text).join(', ')}
    />,
    <LabelValueComponent
      label='Largura do tecido'
      value={`${payload.largura} cm`}
    />,
    ...filesDivergencia.map((file: FileProps, index) => (
      <ListItem key={index} button>
        <ListItemIcon>
          {file.type === 'pdf' ? <PdfIcon /> : <FileIcon />}
        </ListItemIcon>
        <ListItemText
          primary={truncateString(file.name as string, 18)}
          secondary={formatFileSize(file.size ? file.size : 0)}
        />
        <IconButton edge="end">
          <Typography variant="caption" color="primary" onClick={() => {
            const variables = { variables: { filename: file.fileId } };
            
            deleteFile(variables)
             .then((message) => {
              setFiles(files.filter((fileOld) => fileOld.path !== file.path))
             })
             .catch((error) => console.log(error))
          }}>
            Deletar
          </Typography>
        </IconButton>
      </ListItem>
    ))
  ];
  const key = TipoDivergenciasKey.RISCO_ERRADO;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      infoMessage={
        <span>
          Envie uma foto em anexo para obter uma resposta mais objetiva.
        </span>
      }
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(step, [
          ...payload.tecidos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.TECIDO,
            value: item.id.toString(),
            filesDivergencia: filesDivergencia,
          })),
          {
            tipo: TipoDivergenciasInfoKey.LARGURA_TECIDO,
            value: payload.largura,
          },
        ]);
      }}
      progressValue={90}
      stepNumber={'5'}
      infoComponents={infoComponents}
    />
  );
};

export default RiscoErradoFinalStep;
