import { Box, Paper, TextField } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryString } from '../../Utils';

const PaperExtended = (props: {
  other?: JSX.IntrinsicAttributes & import('@material-ui/core').PaperProps;
  children?: React.ReactNode;
}) => (
  <Paper style={{ width: 'max-content' }} {...props.other}>
    {props.children}
  </Paper>
);

export type FilterComponentOption = {
  title: string;
  value: string;
};

export const FilterComponent: FunctionComponent<{
  boxWidth: number;
  defaultValue?: { title: string; value: string };
  label: string;
  loading?: boolean;
  options: FilterComponentOption[];
  pushPathname: string;
  queryKey: string;
}> = ({
  boxWidth,
  defaultValue,
  label,
  loading = false,
  options,
  pushPathname,
  queryKey,
}) => {
  const history = useHistory();
  let queryString = useQueryString();
  const selectedOption = queryString.get(queryKey) || '';
  let value = options.findIndex((item) => item.value === selectedOption);

  return (
    <Box display='flex' alignItems='center' width={boxWidth} marginRight={4}>
      <span
        className='subtitle1 neutral500'
        style={{ marginRight: '8px', paddingBottom: '4px' }}
      >
        {label}
      </span>
      <Autocomplete
        loading={loading}
        style={{ flexGrow: 1 }}
        size='small'
        disableListWrap
        openOnFocus
        className='removeInputBorder'
        options={options}
        getOptionLabel={(option) => option.title}
        PaperComponent={PaperExtended}
        value={value >= 0 ? options[value] : defaultValue}
        getOptionSelected={(option, value) => {
          return option.value === value.value;
        }}
        disableClearable
        fullWidth
        onChange={(_, { value }) => {
          queryString.set(queryKey, value.toString());
          queryString.set('page', '1');
          history.push({
            pathname: pushPathname,
            search: queryString.toString(),
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <KeyboardArrowDown
                  className='MuiSelect-icon neutral300'
                  style={{ paddingBottom: 3 }}
                ></KeyboardArrowDown>
              ),
            }}
          />
        )}
      ></Autocomplete>
    </Box>
  );
};
