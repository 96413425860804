import { createMuiTheme } from '@material-ui/core';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import slug from 'slug';

export const momentFormatShortDayWithHour = 'DD MMM YYYY [às] HH[:]mm';
export const momentFormatShortDay = 'DD MMM YYYY';

export function useQueryString() {
  return new URLSearchParams(useLocation().search);
}

export function getColecaoAtual() {
  const now = moment();
  const month = now.month();
  let grupoColecao = 'INV';
  let anoColecao = now.year();
  if (month >= 6) {
    grupoColecao = 'VER';
    anoColecao += 1;
  }
  return {
    anoColecao,
    grupoColecao,
    colecaoGrupo: `${grupoColecao}-${anoColecao - 2000}`,
  };
}

export function destructorColecaoString(colecao: string) {
  const splited = colecao.split('-');

  return {
    anoColecao: parseInt(splited[1], 10) + 2000,
    grupoColecao: splited[0],
  };
}

export function buildColecaoAno(grupo: string, ano: string) {
  if (!grupo || !ano) {
    return '';
  }
  const colecaoAno = parseInt(ano, 10) - 2000;
  return grupo + '-' + colecaoAno;
}

export function buildUserNickname(name?: string) {
  return slug(name || '', { replacement: '.' });
}

export function buildUserName(name: string) {
  return name
    ?.toLowerCase()
    .split('.')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ');
}

export function buildDivergenciaNumber(idDivergencia: number): string {
  return '#' + idDivergencia.toString().padStart(7, '0');
}

export function buildUserNameMention(name: string) {
  let inicio = name.indexOf("[") + 1
  let fim = name.indexOf("]")
  return name
    /* .toLowerCase()
    .replace('@[','')
    .split(']', 1)    
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' '); */
    .substring(inicio, fim)


}

export const loaderTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3A3A3A',
    },
  },
});