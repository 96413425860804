import { FileItem } from "../../../../context";
import { Material } from "../detail/DivergenciaDetailTypes";

export enum TipoAdicionalInfoAbreviacoes {
  QUANTIDADE_METROS = " mt",
  UNIDADES = " un.",
}

export enum TipoAdicionalInfo {
  QUANTIDADE_METROS = "QUANTIDADE_METROS",
  UNIDADES = "UNIDADES",
}

export type AdicionalInfo = {
  value: String;
  tipo: TipoAdicionalInfo;
};

export type TipoDivergenciaInfo = {
  tipo: TipoDivergenciasInfoKey;
  value: String;
  adicionalInfo?: AdicionalInfo[];
  material?: Material;
  filesDivergencia?: FileItem
};

enum TipoDivergenciasInfoKey {
  AVIAMENTO = "AVIAMENTO",
  LARGURA_TECIDO = "LARGURA_TECIDO",
  TECIDO = "TECIDO",
  OUTROS_TECIDOS = "OUTROS_TECIDOS",
  OUTROS_AVIAMENTOS = "OUTROS_AVIAMENTOS",
  AJUSTE_MP = "AJUSTE_MP",
  SOBRA_TECIDOS = "SOBRA_TECIDOS"
}

export enum TipoDivergenciaTranslate {
  AVIAMENTO = "Aviamento",
  LARGURA_TECIDO = "Largura do tecido",
  TECIDO = "Tecido",
  OUTROS_TECIDOS = "Outros",
  OUTROS_AVIAMENTOS = "Outros",
  AJUSTE_MP = "Descrição do problema",
}

export default TipoDivergenciasInfoKey;
