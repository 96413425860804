import React, { FunctionComponent } from 'react';
import { NotaFiscalSaidaOS } from '../DivergenciaDetailTypes';
import { Grid } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import {    
    momentFormatShortDay,
  } from '../../../../../support/Utils';

const useStyles = makeStyles({
    alert: {
      alignItems: 'center',
      background: 'transparent',
      border: '2px solid #F4F3F3',
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'flex',
      padding: '16px 24px',
    },
    icon: {
      color: '#949394',
    },
  });

const NotaFiscalComponent: FunctionComponent<{ notafiscal: NotaFiscalSaidaOS }> = ({
    notafiscal,
  }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} key={`nf-${notafiscal.id_ordem_producao}`}>
        <div className={classes.alert}>
      <InfoOutlinedIcon className={classes.icon}></InfoOutlinedIcon>
        <div className='body2 medium neutral500 ml32'>
        NF de saída " 
        <span className='bold'>
            {notafiscal.nf_saida} 
        </span>"
        <span> encontrada para este chamado. Emissão em </span>  
        
        <span className='bold'>
            {moment(notafiscal.data_emissao_nf).format(momentFormatShortDay)}            
         </span>
        </div>
        </div>
         
        </Grid>
    );

    };

  export default NotaFiscalComponent;