import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import { loaderTheme } from '../../../support/Utils';
import { User } from './InputUser';

const createUsuarioEspelho = loader('../queries/createUsuarioEspelho.gql');
const queryUsers = loader('../../../support/queries/GetUserForMentions.gql');

const NewUserEspelho: FunctionComponent<{ onSave: Function }> = ({
  onSave,
}) => {
  const {
    loading: loadingUsers,
    data: dataUsers = { findManyUsuario: [] },
  } = useQuery<{
    findManyUsuario: User[];
  }>(queryUsers);

  const [original, setOriginal] = useState<User>({} as User);
  const [copia, setCopia] = useState<User>({} as User);

  const [save, { loading: loadinSave }] = useMutation(createUsuarioEspelho);

  const isLoading = loadingUsers || loadinSave;

  return (
    <div
      className='d-flex align-item-center mt16'
      style={{ width: '100%', justifyContent: 'space-around' }}
    >
      <div style={{ width: '40%' }}>
        <Autocomplete
          options={dataUsers.findManyUsuario}
          value={original}
          closeIcon={null}
          onChange={(_, newValue) => {
            setOriginal(newValue || ({} as User));
          }}
          getOptionSelected={(option, value) =>
            option.id_usuario === value.id_usuario
          }
          getOptionLabel={(option) => {
            return option.login || '';
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              label='Usuário original'
              fullWidth
            />
          )}
        />
      </div>
      <div className='ml8' style={{ width: '40%' }}>
        <Autocomplete
          options={dataUsers.findManyUsuario}
          value={copia}
          closeIcon={null}
          onChange={(_, newValue) => {
            setCopia(newValue || ({} as User));
          }}
          getOptionSelected={(option, value) =>
            option.id_usuario === value.id_usuario
          }
          getOptionLabel={(option) => {
            return option.login || '';
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              label='Usuário cópia'
              fullWidth
            />
          )}
        />
      </div>
      <div className='ml8' style={{ width: '10%' }}>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            save({
              variables: {
                idOriginal: original.id_usuario,
                idCopia: copia.id_usuario,
              },
            }).then(() => {
              setOriginal({} as User);
              setCopia({} as User);
              onSave();
            });
          }}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Salvar
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default NewUserEspelho;
