import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import {
  Link,
  matchPath,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { ConfiguracoesKeys } from '../../support/enums/Configuracoes';
import { useConfiguracaoByKey } from '../../support/UseConfiguracao';
import { useLoggedUser } from '../../support/UseLoggedUser';
import DivergenciaEspelho from './tabs/DivergenciaEspelho';
import Equipes from './tabs/Equipes';
import Geral from './tabs/Geral';
import Planners from './tabs/Planners';
import ResponsavelPCP from './tabs/ResponsavelPCP';
import ResponsavelRiscos from './tabs/ResponsavelRiscos';
import Situacoes from './tabs/Situacoes'
import { ManagementZendeskTicket } from './tabs/ManagementZendeskTicket';

function isActiveRoute(path: string) {
  return matchPath(window.location.pathname, {
    path: path,
    exact: true,
    strict: false,
  });
}

function getRouteClass(path: string): string {
  return clsx({
    body2: true,
    neutral400: !isActiveRoute(path),
    medium: !isActiveRoute(path),
    activeRoute: isActiveRoute(path),
    'semi-bold': isActiveRoute(path),
    neutral800: isActiveRoute(path),
  });
}

const ConfiguracaoTab: FunctionComponent<{ url: string; label: string }> = ({
  url,
  label,
}) => (
  <Box marginRight={3} marginBottom={3}>
    <Grid item>
      <Link to={url}>
        <span
          style={{
            paddingBottom: 16,
          }}
          className={getRouteClass(url)}
        >
          {label}
        </span>
      </Link>
    </Grid>
  </Box>
);

const buildRoute = (
  url: string,
  label: string,
  component: React.FunctionComponent<{}>,
  exact: boolean = false
) => {
  return {
    url: `${url}`,
    label,
    component,
    exact,
  };
};

const buildRoutes = (url: string) => {
  return [
    buildRoute(url, 'Geral', Geral, true),
    buildRoute(`${url}/responsaveis-pcp`, 'Responsáveis PCP', ResponsavelPCP),
    buildRoute(`${url}/planners`, 'Planners', Planners),
    buildRoute(
      `${url}/responsaveis-riscos`,
      'Responsáveis Riscos',
      ResponsavelRiscos
    ),    
    buildRoute(`${url}/equipes`, 'Equipes', Equipes),
    buildRoute(`${url}/espelho`, 'Espelho', DivergenciaEspelho),
    buildRoute(`${url}/situacoes`, 'Situações', Situacoes),
    buildRoute(`${url}/chamados-zendesk`, 'Direcionamento Chamados Zendesk', ManagementZendeskTicket),
  ];
};

const ConfiguracaoMenu = () => {
  const { url } = useRouteMatch();
  const routes = buildRoutes(url);

  return (
    <Box paddingX={2} className='backgroundNeutral00'>
      <Box marginBottom={3} marginTop={1}>
        <Grid container>
          <Grid item xs={9}>
            <span className='h4 headline primary500'>
              Configurações PLM colaborativo
            </span>
          </Grid>
        </Grid>
      </Box>
      <Grid container item xs={12}>
        {routes.map((route) => {
          return (
            <ConfiguracaoTab
              key={`tab-${route.url}`}
              url={route.url}
              label={route.label}
            ></ConfiguracaoTab>
          );
        })}
      </Grid>
    </Box>
  );
};

const Configuracoes: FunctionComponent = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const { data } = useConfiguracaoByKey(
    ConfiguracoesKeys.USUARIOS_ADMINISTRADORES
  );

  const { data: userData } = useLoggedUser();

  if (
    data &&
    data.findOnePLM_Colaborativo_Configuracoes &&
    userData &&
    userData.getLoggedUser
  ) {
    /* const admins = JSON.parse(
      data.findOnePLM_Colaborativo_Configuracoes.valor
    ) as number[]; */

    if(!userData.getLoggedUser.admin){
      history.push({
        pathname: '/',
      });
    }

   /*  if (!admins.includes(userData.getLoggedUser.idUsuario)) {
      history.push({
        pathname: '/',
      });
    } */
  }
  const routes = buildRoutes(path);

  return (
    <div className='backgroundNeutral50 fillHeightWithTopbar'>
      <ConfiguracaoMenu></ConfiguracaoMenu>
      <Box className='backgroundNeutral50' paddingX={2} marginBottom={2}>
        <Switch>
          {routes.map((route) => {
            return (
              <Route
                key={`route-${route.url}`}
                exact={route.exact}
                path={route.url}
                component={route.component}
              />
            );
          })}
        </Switch>
      </Box>
    </div>
  );
};

export default Configuracoes;
