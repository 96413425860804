import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, Checkbox, CircularProgress, FormControlLabel, styled, TextField} from "@material-ui/core";
import {buildUserName} from "../../../support/Utils";

interface SearchTypeTicketProps {
  onSearch: (tipo: string, isZendesk: boolean | null) => void;
  isLoading: boolean;
}

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#97877A',
  },
}));

export const SearchTypeTicket: FunctionComponent<SearchTypeTicketProps> = ({ onSearch, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isZendesk, setIsZendesk] = useState<boolean | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    onSearch(searchTerm, isZendesk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, isZendesk]);

  return (
      <div style={{
        width: '90%',
        display: 'flex',
        alignItems: 'end',
        marginTop: '30px',
        justifyContent: 'space-between'
      }}>
        <div className='d-flex align-item-center'
             style={{width: '30%', justifyContent: 'start'}}
        >
          <div style={{width: '80%'}}>
            <TextField
                required
                value={searchTerm}
                onChange={handleChange}
                variant='outlined'
                size='small'
                label='Tipos de chamados'
                fullWidth
            />
          </div>

          <div className='ml8' style={{width: '25%'}}>
            <Button
                variant='contained'
                className='backgroundPrimary500'
                onClick={() => {
                  onSearch(searchTerm, isZendesk)
                }}
                disabled={searchTerm === ''}
                fullWidth
            >
              {isLoading ? <CircularProgress size={25} color='primary'></CircularProgress> : <span className='text-transform-initial body2 bold primary100'>
              Pesquisar
            </span>}
            </Button>
          </div>
        </div>

        <div
            className='d-flex align-item-center mt16'
            style={{
              width: '30%',
              justifyContent: 'space-between',
            }}
        >
          <div style={{ width: '25%' }}>
            {buildUserName('')}
          </div>
          <div>
            <FormControlLabel
                control={
                  <CustomCheckbox
                      checked={isZendesk === false}
                      onChange={() => setIsZendesk(isZendesk === false ? null : false)}
                  />
                }
                label='PLM'
            />
          </div>
          <div>
            <FormControlLabel
                control={
                  <CustomCheckbox
                      checked={isZendesk === true}
                      onChange={() => setIsZendesk(isZendesk === true ? null : true)}
                  />
                }
                label='ZENDESK'
            />
          </div>
          <div>
            <FormControlLabel
                control={
                  <CustomCheckbox
                      checked={isZendesk === null}
                      onChange={() => setIsZendesk(isZendesk === null ? true : null)}
                  />
                }
                label='TODOS'
            />
          </div>
        </div>
      </div>
  );
}