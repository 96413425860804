import { Avatar, CircularProgress } from '@material-ui/core';
import ColorHash from 'color-hash';
import React, { FunctionComponent } from 'react';
import { useLoggedUser } from '../UseLoggedUser';

const UserAvatar: FunctionComponent = () => {
  const { loading, data } = useLoggedUser();
  const avatarColor = new ColorHash().hex(data?.getLoggedUser.login || '');
  return (
    <Avatar
      className='mr16 mt8'
      style={{
        background: avatarColor,
      }}
    >
      {loading ? (
        <CircularProgress></CircularProgress>
      ) : (
        data?.getLoggedUser.login.substr(0, 1).toUpperCase()
      )}
    </Avatar>
  );
};

export default UserAvatar;
