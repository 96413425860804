import { Menu, MenuItem } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MenuType } from './Menus';
import { UserWithPermission } from './Topbar';
import TopbarMenuButton from './TopbarMenuButton';
import TopbarMenuItem from './TopbarMenuItem';
import {
  userHasAllPermissionFor,
  userHasAtLeastOnePermissionFor,
} from './TopbarUtils';

const TopbarMenuList: FunctionComponent<
  RouteComponentProps & {
    menu: MenuType;
    userWithPermission: UserWithPermission;
    urlPlm: string;
  }
> = ({ menu, userWithPermission, urlPlm, history }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {userHasAtLeastOnePermissionFor(userWithPermission, menu) && (
        <TopbarMenuButton
          handleClick={handleClick}
          label={menu.label}
        ></TopbarMenuButton>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {menu.submenus.map((submenu, index) => {
          return (
            userHasAllPermissionFor(userWithPermission, submenu) && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (submenu.isOldPlm) {
                    //window.location.assign(`${urlPlm}/#/${submenu.path}`);
                    window.open(`${urlPlm}#/${submenu.path}`);
                    return;
                  }
                  history.push(submenu.path || '');
                  return;
                }}
                key={`submenu-${index}`}
              >
                <TopbarMenuItem label={submenu.label}></TopbarMenuItem>
              </MenuItem>
            )
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default withRouter(TopbarMenuList);
