import React from "react";
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from "../../TiposDeDivergencias";
import UploadStepTemplate from "../../template/UploadStepTemplate";

import { createBackHandler } from "../../template/utils";
import { MaterialSelectItem } from "../../template/UseGetMateriais";
import QualidadeDoTecidoFinalStep from "../materia-prima/qualidade-do-tecido/QualidadeDoTecidoFinalStep";

interface UploadQualidadeProps extends TipoDivergenciaComponentProps {
  payload: { materiais: MaterialSelectItem[]; problemDescription: string };
  onBackComponent: TipoDivergenciaComponent;
}

const UploadQualidadeTecidoStep: TipoDivergenciaComponent<
  UploadQualidadeProps
> = ({ onBack, onSetStep, payload, onBackComponent }) => {
  const stepNumber = "2";
  const stepSubTitle = "Anexar uma imagem para prosseguir.";
  const stepTitle = "A imagem para este tipo de chamado e obrigatória";

  const onBackHandler = createBackHandler(
    onBack,
    onBackComponent || UploadQualidadeTecidoStep
  );
  return (
    <UploadStepTemplate
      header={{ title: stepTitle, subtitle: stepSubTitle, step: stepNumber }}
      nextStepComponent={QualidadeDoTecidoFinalStep as TipoDivergenciaComponent}
      onBack={onBackHandler}
      onSetStep={onSetStep}
      payload={payload}
      onClose={() => {}}
    />
  );
};

export default UploadQualidadeTecidoStep;
