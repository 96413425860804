import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  ThemeProvider,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { buildUserName, loaderTheme } from '../../../support/Utils';
import { PLMColaborativoUsuarioEquipe } from '../tabs/Equipes';

const updateUserMutation = gql`
  mutation updateUser(
    $userEquipeId: Int!
    $isLider: Boolean!
    $recebeNotificacao: Boolean!
  ) {
    updateOnePLM_Colaborativo_Usuario_Equipe(
      where: { id_usuario_equipe: $userEquipeId }
      data: {
        is_lider: { set: $isLider }
        recebe_notificao_equipe: { set: $recebeNotificacao }
      }
    ) {
      id_usuario_equipe
    }
  }
`;

const deleteUserMutation = gql`
  mutation deleteUserEquipe($userEquipeId: Int!) {
    deleteOnePLM_Colaborativo_Usuario_Equipe(
      where: { id_usuario_equipe: $userEquipeId }
    ) {
      id_usuario_equipe
    }
  }
`;

const EquipeUserRow: FunctionComponent<{
  equipeUser: PLMColaborativoUsuarioEquipe;
  onUpdate: Function;
}> = ({ equipeUser, onUpdate }) => {
  const [isLider, setIsLider] = useState(equipeUser.is_lider);
  const [recebeNotificacao, setRecebeNotificacao] = useState(
    equipeUser.recebe_notificao_equipe
  );

  const [updateUser, { loading: loadingUpdate }] = useMutation(
    updateUserMutation
  );
  const [deleteUser, { loading: loadingDelete }] = useMutation(
    deleteUserMutation
  );

  return (
    <div
      className='d-flex align-item-center mt16'
      style={{
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ width: '25%' }}>
        {buildUserName(equipeUser.Usuario.login)}
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLider}
              onChange={() => {
                setIsLider(!isLider);
              }}
            />
          }
          label='Lider'
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={recebeNotificacao}
              onChange={() => {
                setRecebeNotificacao(!recebeNotificacao);
              }}
            />
          }
          label='Recebe notificações da equipe'
        />
      </div>
      <div className='ml8' style={{ width: '10%' }}>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            updateUser({
              variables: {
                userEquipeId: equipeUser.id_usuario_equipe,
                isLider,
                recebeNotificacao,
              },
            }).then(() => {
              onUpdate();
            });
          }}
          disabled={loadingUpdate}
          fullWidth
        >
          {loadingUpdate ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Salvar
            </span>
          )}
        </Button>
      </div>
      <div className='ml8' style={{ width: '10%' }}>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            deleteUser({
              variables: {
                userEquipeId: equipeUser.id_usuario_equipe,
              },
            }).then(() => {
              onUpdate();
            });
          }}
          disabled={loadingDelete}
          fullWidth
        >
          {loadingDelete ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Remover
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default EquipeUserRow;
