import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
  buildOutrosTecidos,
} from '../../../template/UseGetMateriais';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import MisturaLotesEtiquetaIncorretaLarguraStep from './MisturaLotesEtiquetaIncorretaLarguraStep';

const MisturaLotesEtiquetaIncorreta: TipoDivergenciaComponent = ({ onBack, onSetStep }) => {
  const { loading, materiais } = useGetMateriais(
    TipoDivergenciasInfoKey.TECIDO
  );
  const newMateriais = [...materiais, buildOutrosTecidos()];

  const stepNumber = '2';
  const stepSubTitle = 'Selecione a parte do tecido com Mistura de lotes / Etiquetagem incorreta.';
  const stepTitle = 'Qual parte do tecido está com Mistura de lotes / Etiquetagem incorreta?';
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={MisturaLotesEtiquetaIncorretaLarguraStep as TipoDivergenciaComponent}
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey='tecidos'
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};

export default MisturaLotesEtiquetaIncorreta;

