import AreaTipoDivergenciaEnum from "../../AreaTipoDivergenciaEnum";
import { useBuildFinalStepTemplate } from "../../template/FinalStepTemplate";
import useBuildSubmitHandler from "../../template/UseBuildSubmitHandler";
import TipoDivergenciasKey from "../../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../../TiposDeDivergencias";

const AjusteComposicao: TipoDivergenciaComponent = ({ onBack, onSetStep }) => {
  const key = TipoDivergenciasKey.AJUSTE_COMPOSICAO;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);
  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.CAD_ATELIER,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: "Ajuste de composição",
  });
};

export default AjusteComposicao;
