import { DialogActions, DialogContent, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { FunctionComponent, useContext, useState } from 'react';
import { DivergenceContext, DivergenceContextType } from '../../../../../context';
import ContinueOrBackActions from '../components/ContinueOrBackActions';
import NovaDivergenciaProgressBar from '../components/NovaDivergenciaProgressBar';
import SelectStep, { SelectItem } from '../components/SelectStep';
import StepperHeader from '../components/StepperHeader';
import { Step } from '../NovaDivergenciaStepHandler';
import { TipoDivergenciaComponent } from '../TiposDeDivergencias';

const SelectSkeleton: FunctionComponent = () => (
  <div className='mx16'>
    {[250, 200, 225, 150, 250, 200].map((value, index) => (
      <Skeleton
        key={index}
        variant='rect'
        width={value}
        height={32}
        className='mt8'
      />
    ))}
  </div>
);

const SelectStepTemplate: FunctionComponent<{
  data: SelectItem[];
  loading?: boolean;
  nextStepComponent: TipoDivergenciaComponent;
  onBack: () => void;
  onSetStep: (step: Step) => void;
  stepNumber: string;
  payloadKey: string;
  stepSubTitle: string;
  stepTitle: string;
}> = (props) => {
  const [selected, setSelecteds] = useState<SelectItem[]>([]);
  const { setSelectedItems } = useContext<DivergenceContextType>(DivergenceContext);

  const isDisabledButton = () => selected.length < 1;
  const handleContinue = () => {
    const payload: { [key: string]: SelectItem[] } = {};    
    payload[props.payloadKey] = selected;
    setSelectedItems([]);
    setSelectedItems(selected);
    props.onSetStep({
      component: props.nextStepComponent,
      payload,
    });
  };
  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={32}></NovaDivergenciaProgressBar>
      <DialogContent
        className='backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth'
        style={{
          padding: '0 32px',
        }}
      >
        <StepperHeader
          stepNumber={props.stepNumber}
          stepSubTitle={props.stepSubTitle}
          stepTitle={props.stepTitle}
        />
        {props.loading ? (
          <SelectSkeleton />
        ) : props.data.length ? (
          <SelectStep
            data={props.data}
            selected={selected}
            allowSelectMany={true}
            setSelecteds={setSelecteds}
          />
        ) : (
          <div className='text-center'>
            <h6
              className='body1 medium neutral800'
              style={{ marginBottom: '24px' }}
            >
              Este produto não possui matéria-prima vinculada a ele.
            </h6>
          </div>
        )}
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        style={{
          padding: '30px 32px',
        }}
        className='divergenciaMaxWidth'
      >
        <ContinueOrBackActions
          continueMessage='Continuar'
          disabled={isDisabledButton()}
          disabledMessage='Continuar'
          onBack={props.onBack}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  );
};

export default SelectStepTemplate;
