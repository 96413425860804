import AreaTipoDivergenciaEnum from "../../AreaTipoDivergenciaEnum";
import { useBuildFinalStepTemplate } from "../../template/FinalStepTemplate";
import useBuildSubmitHandler from "../../template/UseBuildSubmitHandler";
import TipoDivergenciasKey from "../../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../../TiposDeDivergencias";

const DuvidaMontagem: TipoDivergenciaComponent = ({ onBack, onSetStep }) => {
  const key = TipoDivergenciasKey.DUVIDA_MONTAGEM;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);

  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.MODELAGEM,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: "Dúvida de montagem",
  });
};

export default DuvidaMontagem;
