import { Box, Divider, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import CopyToClipboard from '../support/components/CopyToClipboard';

type props = {
  op: string;
  os: string;
  colecao: string;
  loading?: boolean;
};

export default class ProductInfo extends React.Component<props> {
  infoBlock(title: string, desc: string) {
    return (
      <Grid item xs={3}>
        <div className='caption medium neutral400'>{title}</div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <span className='body1 medium primary500'>
            {this.props.loading ? (
              <Skeleton animation='wave' width={70} />
            ) : (
              desc
            )}
          </span>

          <Box marginLeft={1} component='span'>
            {!this.props.loading && (
              <CopyToClipboard value={desc}></CopyToClipboard>
            )}
          </Box>
        </div>
      </Grid>
    );
  }

  render() {
    return (
      <Box clone marginLeft={2}>
        <Grid container item xs={6}>
          <Divider orientation='vertical' flexItem variant='middle'></Divider>
          {this.infoBlock('OP - Pedido', this.props.op)}
          <Divider orientation='vertical' flexItem variant='middle'></Divider>
          {this.infoBlock('OS', this.props.os)}
          <Divider orientation='vertical' flexItem variant='middle'></Divider>
          {this.infoBlock('Coleção - Pedido', this.props.colecao)}
        </Grid>
      </Box>
    );
  }
}
