import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    backButton: {
      alignItems: 'center',
      border: '2px solid #f4f3f3',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '56px',
      minWidth: '56px',
    },
  })
);

const BackButton: FunctionComponent<{ onBack: Function }> = ({ onBack }) => {
  const classes = useStyles();

  return (
    <span className={classes.backButton}>
      <IconButton
        onClick={() => {
          onBack();
        }}
      >
        <ArrowLeftIcon></ArrowLeftIcon>
      </IconButton>
    </span>
  );
};

export default BackButton;
