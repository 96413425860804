import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import ContinueOrBackActions from "../../../components/ContinueOrBackActions";
import NovaDivergenciaProgressBar from "../../../components/NovaDivergenciaProgressBar";
import LabelValueBase from "../../../components/LabelValueBase";
import StepperHeader from "../../../components/StepperHeader";
import { MaterialSelectItem } from "../../../template/UseGetMateriais";
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from "../../../TiposDeDivergencias";
import CorteIncorreto from "./ReacaoTecidoPosCorte";
import CorteIncorretoFinalStep from "./ReacaoTecidoPosCorteFinalStep";

interface TecidoProps extends TipoDivergenciaComponentProps {
  payload: { tecidos: MaterialSelectItem[] };
}

const ReacaoTecidoPosCorteLarguraStep: TipoDivergenciaComponent<
  TecidoProps
> = ({ onSetStep, onBack, payload }) => {
  const [largura, setLargura] = useState("");
  const isDisabledButton = () => !largura;

  const handleContinue = () => {
    const nextPayload = {
      largura: largura,
      tecidos: payload.tecidos,
    };
    onSetStep({
      component: CorteIncorretoFinalStep as TipoDivergenciaComponent,
      payload: nextPayload,
    });
  };

  const onBackHandler = () => {
    onBack({
      component: CorteIncorreto,
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLargura(event.target.value);
  };

  const stepNumber = "3";
  const stepSubTitle =
    "Obs: Quando as partes cortadas estão diferentes da modelagem recebida, pode ser por erro de corte ou reação do tecido.";
  const stepTitle = "As partes cortadas estão diferentes da modelagem?";

  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={66}></NovaDivergenciaProgressBar>
      <DialogContent
        className="backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth"
        style={{
          padding: "0 32px",
        }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div className="px16">
          <form
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}
          >
            <TextField
              fullWidth
              name="text"
              onChange={handleChange}
              placeholder="Ex.: Forro do bolso (P - 10 / M - 20 / G - 25)"
              variant="outlined"
              inputProps={{ maxLength: 45 }}
            />
          </form>
          <div className="mt32">
            <LabelValueBase label="Tecido selecionado">
              <div>
                <span className="mt8 body2 semi-bold neutral700">
                  {payload.tecidos.map((item) => item.text).join(", ")}
                </span>
                <Button onClick={onBackHandler}>
                  <span className="caption bold neutral300">ALTERAR</span>
                </Button>
              </div>
            </LabelValueBase>
          </div>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className="divergenciaMaxWidth"
        style={{ padding: "30px 32px" }}
      >
        <ContinueOrBackActions
          continueMessage="Continuar"
          disabled={isDisabledButton()}
          disabledMessage="Continuar"
          onBack={onBackHandler}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  );
};

export default ReacaoTecidoPosCorteLarguraStep;
