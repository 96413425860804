import React from "react";
import {
  TipoDivergenciaComponent
} from "../../../TiposDeDivergencias";
import UploadStepTemplate from "../../../template/UploadStepTemplate";

import RiscoErradoLarguraStep, { RiscoErradoProps } from "./RiscoErradoLarguraStep";
import RiscoErradoFinalStep from "./RiscoErradoFinalStep";

const UploadRiscoErradoStep: TipoDivergenciaComponent<
  RiscoErradoProps
> = ({ onBack, onSetStep, payload }) => {
  const stepNumber = "4";
  const stepSubTitle = "Anexar uma imagem para prosseguir.";
  const stepTitle = "A imagem para este tipo de chamado e obrigatória";

  const onBackHandler = () => {
    onBack({
      component: RiscoErradoLarguraStep as TipoDivergenciaComponent,
      payload: {
        tecidos: payload.tecidos,
        largura: payload.largura,
      },
    });
  };
  return (
    <UploadStepTemplate
      header={{ title: stepTitle, subtitle: stepSubTitle, step: stepNumber }}
      nextStepComponent={RiscoErradoFinalStep as TipoDivergenciaComponent}
      onBack={onBackHandler}
      onSetStep={onSetStep}
      payload={payload}
      onClose={() => {}}
    />
  );
};

export default UploadRiscoErradoStep;
