import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import { useBuildFinalStepTemplate } from '../../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';

const AtualizacaoGrade: TipoDivergenciaComponent = ({
  onBack,
  onSetStep,
}) => {
  const key = TipoDivergenciasKey.ATUALIZACAO_DE_GRADE;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);
  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.PLANEJAMENTO_DEMANDA,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: 'Atualização de grade',
  });
};

export default AtualizacaoGrade;