export enum BufferPenetrationColorHex {
  Black = '#000000',
  White = '#FFFFFF',
  Cyan = '#89CFF0',
  Green = '#5DFF53',
  Yellow = '#F1E62E',
  Red = '#EA1515',
  'Dark Gray' = '#777777',
  'Light Gray' = '#CACACA',
}
