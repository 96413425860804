import { MenuType, SubMenuType } from './Menus';
import { UserWithPermission } from './Topbar';

export function userHasAllPermissionFor(
  userWithPermissions: UserWithPermission,
  submenu: SubMenuType
) {
  if (userIsAdmin(userWithPermissions)) {
    return true;
  }

  if (submenu.permissoes.length === 0) {
    return true;
  }

  const totalPermissions = getTotalPermissions(submenu, userWithPermissions);

  return totalPermissions === submenu.permissoes.length;
}

export function getTotalPermissions(
  submenu: SubMenuType,
  userWithPermissions: UserWithPermission
) {
  let totalPermissions = 0;

  submenu.permissoes.forEach((permission) => {
    if (typeof permission === 'function' && permission(userWithPermissions)) {
      totalPermissions++;
    }

    if (
      typeof permission === 'string' &&
      userWithPermissions.grupoAreas.includes(permission)
    ) {
      totalPermissions++;
    }
  });
  return totalPermissions;
}

export function userIsAdmin(userWithPermission: UserWithPermission) {
  return userWithPermission.isAdmin;
}

export function userHasAtLeastOnePermissionFor(
  userWithPermissions: UserWithPermission,
  menu: MenuType
) {
  if (userIsAdmin(userWithPermissions)) {
    return true;
  }

  if (menu.submenus.length === 0) {
    return true;
  }

  let hasPermission = false;

  menu.submenus.forEach((submenu) => {
    if (submenu.permissoes.length === 0) {
      hasPermission = true;
    }

    let totalPermissions = getTotalPermissions(submenu, userWithPermissions);

    if (totalPermissions === submenu.permissoes.length) {
      hasPermission = true;
    }
  });

  return hasPermission;
}
