import React, { createContext, ReactNode, useState } from "react";

interface TamanhoGradeContextProps {
    children: ReactNode
}

export type TamanhoDetail = {
    [tamanho: string]: {
        quantidade: number;
    }
}

export type TamanhoGradeContextType = {
    quantidadeTamanhos: TamanhoDetail;
    setQuantidadeTamanhos: (newValue: TamanhoDetail) => void;
}

export const TamanhoGradeContext = createContext<TamanhoGradeContextType>({} as TamanhoGradeContextType)

export const TamanhoGradeContextProvider = ({ children }: TamanhoGradeContextProps) => {
    const [quantidadeTamanhos, setQuantidadeTamanhos] = useState<TamanhoDetail>({} as any);
    return (
        <TamanhoGradeContext.Provider
            value={{ quantidadeTamanhos, setQuantidadeTamanhos }}
        >
            {children}
        </TamanhoGradeContext.Provider>
    )
}