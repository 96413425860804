import React, { FunctionComponent } from 'react';
import LabelValueBase from './LabelValueBase';

const LabelValueComponent: FunctionComponent<{
  label: string;
  value: string;
  hideLabel?: boolean;
  hideChildren?: boolean;
}> = ({ label, value, hideLabel, hideChildren }) => (
  <LabelValueBase label={label} hideChildren={hideChildren} hideLabel={hideLabel}>
    <div className='mt8 body2 semi-bold neutral700'>{value}</div>
  </LabelValueBase>
);

export default LabelValueComponent;
