import { useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  ThemeProvider,
  TextField,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { loaderTheme } from '../../../support/Utils';


const createSituacaoMutation = loader(
    '../queries/createSituacao.gql'
  );

const NewSituacao: FunctionComponent<{ 
  onUpdate: Function;  
}> = ({ onUpdate }) => {
 
  const [createSituacao, { loading }] = useMutation(
    createSituacaoMutation
  );

  const [situacaoValue, setSituacaoValue] = React.useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSituacaoValue(event.target.value);    
  };

  return (
    <TableRow className='tableRowHover pointer'>
      <TableCell width='40%'></TableCell>
      <TableCell width='40%'>
      <TextField onChange={handleChange} value={situacaoValue} variant='outlined' size='small' fullWidth />      
      </TableCell>
      <TableCell width='10%'>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            createSituacao({
              variables: {                
                situacao: situacaoValue,                
              },
            }).then(() => {
                onUpdate();
                setSituacaoValue('');
            });
          }}
          fullWidth
        >
          {loading ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Inserir
            </span>
          )}
        </Button>
      </TableCell>
      <TableCell width='10%'>        
      </TableCell>
    </TableRow>
  );
};

export default NewSituacao;