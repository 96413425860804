import { Button, CircularProgress, ThemeProvider } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { loaderTheme } from '../../../../../support/Utils';
import BackButton from './BackButton';

const ContinueOrBackActions: FunctionComponent<{
  continueMessage: string;
  disabled?: boolean;
  disabledMessage: string;
  loading?: boolean;
  onBack: () => void;
  onContinue: () => void;
}> = ({
  continueMessage,
  disabled = false,
  disabledMessage,
  loading = false,
  onBack,
  onContinue,
}) => (
  <React.Fragment>
    <BackButton onBack={onBack}></BackButton>
    {disabled ? (
      <Button className='pa16' disabled fullWidth variant='outlined'>
        <span className='text-transform-initial neutral500 bold'>
          {disabledMessage}
        </span>
      </Button>
    ) : (
      <Button
        className='backgroundPrimary500 pa16'
        disabled={loading}
        fullWidth
        onClick={() => onContinue()}
      >
        <span className='text-transform-initial neutral000 bold'>
          {!loading ? (
            continueMessage
          ) : (
            <div style={{ display: 'flex' }}>
              <ThemeProvider theme={loaderTheme}>
                <CircularProgress size={26} color='primary'></CircularProgress>
              </ThemeProvider>
            </div>
          )}
        </span>
      </Button>
    )}
  </React.Fragment>
);
export default ContinueOrBackActions;
