import React, { useContext, useState } from 'react';
import { Box, Button, Grid, Snackbar, TextField } from '@material-ui/core';
import { YesNoRadio } from '../../../../../../shared';

import { useMutation } from '@apollo/client';
import { UPSERT_FINE, UpsertFineData, UpsertFineVars } from '../../../mutations';

import { Alert } from '@material-ui/lab';
import { UserContext, UserContextType } from '../../../../../../context';
import { isSupplier } from "../../../../../../tools";

interface FineData {
    idDivergence: number,
    productionOrder: string,
    idFine?: number | undefined,
    fine?: boolean| undefined,
    observation?: string | undefined,
}

interface FineComponentProps  {
    fineDataProps: FineData,
    reload: Function
}

type FeedbackType = {
  show: boolean;
  message: string;
  type: "error" | "warning" | "info" | "success";
};
const INITIAL_FEEDBACK_STATE: FeedbackType = {
  message: "",
  show: false,
  type: "info",
};

export function FineComponent({ fineDataProps, reload }: FineComponentProps  ): JSX.Element {
    const { userLogged } = useContext<UserContextType>(UserContext);
    
    const [feedback, setFeedback] = useState<FeedbackType>(INITIAL_FEEDBACK_STATE); 
    const [fineData, setFineData] = useState<FineData>({...fineDataProps})  
    const isFineUndefined = fineData?.fine === undefined
    const observationRequired = (!fineData.fine && !fineData.observation)
    const disabledForm = isSupplier(userLogged?.id_grupo || null)

    const [runMutation, { loading }] = useMutation<UpsertFineData, UpsertFineVars>(UPSERT_FINE,
    { onCompleted: () => {
            reload()
        }
    }
    )

    const handleSubmit = async () => {
        if(observationRequired ){
            setFeedback({
                message: 'Campo observação é obrigatório',
                show: true,
                type: "error",
            });
            return
        }

        try {
         const  { data } = await runMutation({
            variables: {
            data: {
                fine: fineData?.fine!,
                ...fineData
            }
            }
        })
         if(data){
            setFeedback({
            message: "Multa salva com sucesso.",
            show: true,
            type: "success",
            });
        }
       } catch (error) {
        const { message } = error as any
        setFeedback({
            message,
            show: true,
            type: "error",
        });
       }
      
    }

    const handleCloseSnackbar = () => {
        setFeedback(INITIAL_FEEDBACK_STATE);
    };

  return (
    <>
        <Box style={{border: '1px solid lightgray', borderRadius: '10px'}} p={2}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <YesNoRadio
                        name='Multa'
                        disabled={disabledForm}
                        label='Multa aplicada'
                        value={fineData?.fine}
                        onChange={(event) => {
                            setFineData({...fineData, fine: event })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box pt={1} hidden={isFineUndefined}>
                        <TextField
                        disabled={disabledForm}
                        id="observation"
                        fullWidth
                        variant="outlined"
                        label="Observação"
                        placeholder="Insira o texto..."
                        value={fineData?.observation}
                        multiline
                        required={observationRequired}
                        onChange={({target}) => {
                            setFineData({...fineData, observation: target.value })
                        }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {!isFineUndefined && (
                            <Button
                            disabled={loading || disabledForm}
                            disableElevation
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            className='backgroundPrimary500'
                            size='small'
                            >
                            <Box>
                                Salvar Multa
                            </Box>
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Snackbar
            open={feedback.show}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
            <Alert onClose={handleCloseSnackbar} severity={feedback.type}>
                {feedback.message}
            </Alert>
        </Snackbar>

    </>
  );
};

export default FineComponent;
