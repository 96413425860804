import { gql, useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { loaderTheme } from '../../../support/Utils';

const createEquipeMutation = gql`
  mutation createEquipe($nome: String!) {
    createOnePLM_Colaborativo_Equipe(data: { nome_equipe: $nome }) {
      id_equipe
    }
  }
`;

const NewEquipe: FunctionComponent<{ onUpdate: Function }> = ({ onUpdate }) => {
  const [save, { loading: isLoading }] = useMutation(createEquipeMutation);
  const [newName, setNewName] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  };

  return (
    <div
      className='d-flex align-item-center '
      style={{ width: '100%', justifyContent: 'start' }}
    >
      <div style={{ width: '40%' }}>
        <TextField
          required
          value={newName}
          onChange={handleChange}
          variant='outlined'
          size='small'
          label='Nome da equipe'
          fullWidth
        />
      </div>

      <div className='ml8' style={{ width: '10%' }}>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            save({
              variables: {
                nome: newName,
              },
            }).then(() => {
              onUpdate();
              setNewName('');
            });
          }}
          disabled={isLoading || newName === ''}
          fullWidth
        >
          {isLoading ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Salvar
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default NewEquipe;
