import React from "react";
import MateriaisPopover from "../../../../detail/components/MateriaisPopover";
import AreaTipoDivergenciaEnum from "../../../AreaTipoDivergenciaEnum";
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from "../../../template/FinalStepTemplate";
import useBuildSubmitHandler from "../../../template/UseBuildSubmitHandler";
import { transforSelectItemToDivergenciaInfo } from "../../../template/utils";
import TipoDivergenciasKey from "../../../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../../../TiposDeDivergencias";
import SaldoEmDevolucaoAviamentoObservacaoStep from "./SaldoEmDevolucaoAviamentoObservacaoStep";
import { AviamentoProps } from "./SaldoEmDevolucaoAviamentoObservacaoStep";
import LabelValueWithSubtitle from "../../../components/LabelValueWithSubtitle";
import TipoDivergenciasInfoKey from "../../../TipoDivergenciasInfoKey.";

const SaldoEmDevolucaoAviamentoFinalStep: TipoDivergenciaComponent<
  AviamentoProps
> = ({ onSetStep, onBack, payload }) => {
  const onBackHandler = () => {
    onBack({
      component:
        SaldoEmDevolucaoAviamentoObservacaoStep as TipoDivergenciaComponent,
    });
  };

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.PLANEJAMENTO_DEMANDA,
      TipoDivergenciasKey.SALDO_EM_DEVOLUCAO_ANDAMENTO
    ),
    <MateriaisPopover
      materiais={payload.materiais.map(transforSelectItemToDivergenciaInfo)}
    ></MateriaisPopover>,
    <LabelValueWithSubtitle
      label="Descrição do problema"
      value={`${payload.problemDescription}`}
      subtitle={""}
    />,
  ];

  const key = TipoDivergenciasKey.SALDO_EM_DEVOLUCAO_ANDAMENTO;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(step, [
          ...payload.materiais.map((item) => {
            return {
              tipo: item.tipo,
              value: item.id.toString(),
              material: item.material
            };
          }),
          {
            tipo: TipoDivergenciasInfoKey.AJUSTE_MP,
            value: payload.problemDescription,
          },
        ]);
      }}
      progressValue={90}
      stepNumber={"4"}
      infoComponents={infoComponents}
    />
  );
};

export default SaldoEmDevolucaoAviamentoFinalStep;
