import AreaTipoDivergenciaEnum from "../../AreaTipoDivergenciaEnum";
import { useBuildFinalStepTemplate } from "../../template/FinalStepTemplate";
import useBuildSubmitHandler from "../../template/UseBuildSubmitHandler";
import TipoDivergenciasKey from "../../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../../TiposDeDivergencias";

const ErroModelagem: TipoDivergenciaComponent = ({ onBack, onSetStep }) => {
  const key = TipoDivergenciasKey.ERRO_MODELAGEM;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);

  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.MODELAGEM,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: "Erro de modelagem",
  });
};

export default ErroModelagem;
