import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import { Material } from '../../detail/DivergenciaDetailTypes';
import { SelectItem } from '../components/SelectStep';
import TipoDivergenciasInfoKey, {
  AdicionalInfo,
} from '../TipoDivergenciasInfoKey.';

const query = loader('../../queries/GetMateriais.gql');

export type MaterialSelectItem = SelectItem & {
  tipo: TipoDivergenciasInfoKey;
  codigo?: String;
  adicionalInfo?: AdicionalInfo[];
  material?: Material;
};

export default function useGetMateriais(tipo?: TipoDivergenciasInfoKey) {
  const { idProducaoProgProd } = useParams<{ idProducaoProgProd: string }>();

  const {
    loading,
    data: tecidosData = { findManyProducaoProgProdMaterial: [] },
  } = useQuery(query, {
    variables: {
      idProducaoProgProd: Number(idProducaoProgProd),
      tipoMaterial: tipo,
    },
  });

  const materiais: MaterialSelectItem[] = tecidosData.findManyProducaoProgProdMaterial.map(
    buildMaterial
  );
  return {
    loading,
    materiais,
  };
}

function buildMaterial(
  item: Material & { tipo?: TipoDivergenciasInfoKey; customValue?: string }
): MaterialSelectItem {
  const tipoByPano = item.eh_pano
    ? TipoDivergenciasInfoKey.TECIDO
    : TipoDivergenciasInfoKey.AVIAMENTO;
  return {
    id: item.customValue || item.id_material,
    text: item.desc_material_limpo,
    tipo: item.tipo || tipoByPano,
    subtext: 'COR: ' + item.desc_cor,
    codigo: item.id_linx_mp,
    material: {
      desc_cor: item.desc_cor,
      desc_material_limpo: item.desc_material_limpo,
      eh_pano: item.eh_pano,
      id_linx_mp: item.id_linx_mp,
      id_material: item.id_material,
      id_material_cor: item.id_material_cor,
      unid_estoque: item.unid_estoque
    },
  };
}

export function buildOutrosAviamentos() {
  return buildMaterial({
    desc_cor: '',
    desc_material_limpo: 'OUTROS AVIAMENTOS',
    eh_pano: false,
    id_linx_mp: '0',
    id_material: 0,
    tipo: TipoDivergenciasInfoKey.OUTROS_AVIAMENTOS,
    customValue: 'Outros Aviamentos',
  });
}

export function buildOutrosTecidos() {
  return buildMaterial({
    desc_cor: '',
    desc_material_limpo: 'OUTROS TECIDOS',
    eh_pano: false,
    id_linx_mp: '0',
    id_material: 0,
    tipo: TipoDivergenciasInfoKey.OUTROS_TECIDOS,
    customValue: 'Outros Tecidos',
  });
}
