import {
  DialogActions,
  DialogContent,
  Divider,
  TextField
} from "@material-ui/core";
import React, { FunctionComponent, useContext, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import ContinueOrBackActions from "../components/ContinueOrBackActions";
import NovaDivergenciaProgressBar from "../components/NovaDivergenciaProgressBar";
import StepperHeader from "../components/StepperHeader";
import { Step } from "../NovaDivergenciaStepHandler";
import {
  TipoAdicionalInfo,
  TipoAdicionalInfoAbreviacoes,
} from "../TipoDivergenciasInfoKey.";
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from "../TiposDeDivergencias";
import { MaterialSelectItem } from "./UseGetMateriais";
import {
  DivergenceContext,
  DivergenceContextType
} from '../../../../../context'

export interface MateriaisTemplateProps extends TipoDivergenciaComponentProps {
  payload: { materiais: MaterialSelectItem[] };
}

interface MateriaTextFieldProps {
  label: string;
  fullWidth?: boolean;
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  placeholder: string;
  variant: "outlined";
  helperText?: string;
  unid_estoque?: string;
  className?: string;
}

export interface StepInfosProps {
  stepNumber?: string,
  stepSubTitle?: string,
  stepTitle?: string
}

const MaterialTextField: FunctionComponent<MateriaTextFieldProps> = ({
  unid_estoque,
  ...otherProps
}) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (values: NumberFormatValues) => {
    const newValue = values.value.split(".").join("");
    setValue(newValue);
    otherProps.onChange({
      target: {
        name: otherProps.name,
        value: newValue,
      },
    });
  };

  const tipoSuffix =
    unid_estoque === "UN"
      ? TipoAdicionalInfoAbreviacoes.UNIDADES
      : TipoAdicionalInfoAbreviacoes.QUANTIDADE_METROS;

  return (
    <NumberFormat
      {...otherProps}
      allowNegative={false}
      decimalScale={0}
      value={value}
      onValueChange={handleChange}
      suffix={tipoSuffix}
      isNumericString
      customInput={TextField}
    />
  );
};

export const MateriasQuantidadeStepTemplate: FunctionComponent<
  MateriaisTemplateProps & {
    nextStepComponent: TipoDivergenciaComponent;
    onBack: () => void;
    onSetStep: (step: Step) => void;
    stepInfos: StepInfosProps | null
  }
> = ({ onSetStep, onBack, payload, nextStepComponent, stepInfos }) => {
  const [disabledButton, setDisabledButton] = useState(true);
  const { setAviamentos } = useContext<DivergenceContextType>(DivergenceContext)

  const checkDisableButton = () => {
    let disabled = false;
    payload.materiais.forEach((material) => {
      if (!material.adicionalInfo || material.adicionalInfo.length === 0) {
        disabled = true;
      } else {
        material.adicionalInfo.forEach((info) => {
          if (!info.value) {
            disabled = true;
          }
        });
      }
    });
    setDisabledButton(disabled);
  };

  const handleContinue = () => {
    const nextPayload = {
      materiais: payload.materiais,
    };
    setAviamentos(null);
    setAviamentos({ aviamentos: nextPayload.materiais });
    onSetStep({
      component: nextStepComponent,
      payload: nextPayload,
    });
  };

  const handleChange = (value: string, aviamento: MaterialSelectItem) => {
     const newValue = value.replace(/[^\d]/g, ""); // Remove a parte não numérica da string
     const unid_estoque   = aviamento?.material?.unid_estoque
     const tipoAdicionalInfo = unid_estoque === 'UN' ? TipoAdicionalInfo.UNIDADES : TipoAdicionalInfo.QUANTIDADE_METROS
    aviamento.adicionalInfo = [
      {
        value: newValue,
        tipo: tipoAdicionalInfo,
      },
    ];
    checkDisableButton();
  };

  const stepNumber = stepInfos?.stepNumber ? stepInfos?.stepNumber : "3";
  const stepSubTitle = stepInfos?.stepSubTitle ? stepInfos?.stepSubTitle : "Digite abaixo a quantidade que precisa de cada material.";
  const stepTitle =  stepInfos?.stepTitle ? stepInfos?.stepTitle :  "Quantos materias precisa?";

  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={50}></NovaDivergenciaProgressBar>
      <DialogContent
        className="backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth"
        style={{
          padding: "0 32px",
        }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div className="px16">
          <form
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}
          >
            {payload.materiais.map((material, index) => (
              <MaterialTextField
                className="mb16"
                key={`tecido-${index}`}
                label={material.text && `COR ${material.text}`}
                fullWidth
                unid_estoque={material.material?.unid_estoque}
                name="numberformat"
                onChange={(event) => {
                  handleChange(event.target.value, material);
                }}
                placeholder="Digite quantas unidades você precisa a mais"
                variant="outlined"
                helperText={material.subtext}
              />
            ))}
          </form>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className="divergenciaMaxWidth"
        style={{ padding: "30px 32px" }}
      >
        <ContinueOrBackActions
          continueMessage="Continuar"
          disabled={disabledButton}
          disabledMessage="Continuar"
          onBack={onBack}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  );
};

