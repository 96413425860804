import { gql, useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import Routes from './app/Routes';
import { useLoggedUser } from './app/support/UseLoggedUser';
import {
  messaging,
  requestFirebaseNotificationPermission,
} from './firebaseInit';
import { TamanhoGradeContextProvider } from './app/context/TamanhoGradeContext';
import { DivergenceContextProvider, UserContext, UserContextType } from './app/context';
import { NotificationsResolver } from './app/context'

const upsertUsuarioNotificacaoMutation = gql`
  mutation upsertUsuarioNotificacao($userId: Float!, $token: String!) {
    upsertUsuarioNotificacao(userId: $userId, token: $token) {
      id_usuario_notificacao_token
      Usuario {
        login
      }
      fcm_token
    }
  }
`;

const App: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [saveToken, { loading: loadingSave }] = useMutation(
    upsertUsuarioNotificacaoMutation
  );
  const [tokenSaved, setTokenSaved] = useState(false);

  const { data } = useLoggedUser();
  const { setUserLogged } = useContext<UserContextType>(UserContext);
  setUserLogged(data?.getLoggedUser || null);

  if (!tokenSaved && !loadingSave && data && data.getLoggedUser) {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        saveToken({
          variables: {
            userId: data.getLoggedUser.idUsuario,
            token: firebaseToken,
          },
        });
        setTokenSaved(true);
        localStorage.setItem('fcm_token', JSON.stringify(firebaseToken));
      })
      .catch((err) => {
        console.log(err);

        return err;
      });
  }

  messaging.onMessage((payload) => {
    const { body, click_action } = payload.notification;
    const action = (key: any) => (
      <Fragment>
        <Button
          onClick={() => {
            window.location = click_action;
          }}
        >
          Ver
        </Button>
        <Button
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          OK
        </Button>
      </Fragment>
    );
    console.debug(`New FCM receive: ${body}`);

    enqueueSnackbar(body, { action, variant: 'info', persist: true });
  });

  return (
    <DivergenceContextProvider>
      <NotificationsResolver>
      <TamanhoGradeContextProvider>
        <div>
          <Routes></Routes>
        </div>
      </TamanhoGradeContextProvider>
      </NotificationsResolver>
    </DivergenceContextProvider>
  );
};

export default App;
