import { Box } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useQueryString } from '../../support/Utils';
import ProductTable from './components/ProductTable';
import { SimpleFilters } from './components/SimpleFilters';
import { TopBar } from './components/TopBar';

export const ProductList: FunctionComponent = () => {
  let queryString = useQueryString();
  return (
    <Box className='backgroundNeutral50 fillHeightWithTopbar' marginBottom={2}>
      <Box paddingX={2} className='backgroundNeutral00'>
        <TopBar></TopBar>
      </Box>
      <Box paddingX={2}>
        <SimpleFilters key={queryString.toString()}></SimpleFilters>
        <ProductTable></ProductTable>
      </Box>
    </Box>
  );
};
