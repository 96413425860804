import React from 'react';
import AreaTipoDivergenciaEnum from '../../AreaTipoDivergenciaEnum';
import { useBuildFinalStepTemplate } from '../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../TiposDeDivergencias';

const PilotoEnviadoComProblema: TipoDivergenciaComponent = ({
  onBack,
  onSetStep,
}) => {
  const key = TipoDivergenciasKey.PILOTO_ENVIADO_COM_PROBLEMA;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);
  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.PILOTO,
    infoMessage: (
      <span>
        Envie uma foto em anexo para obter uma resposta mais objetiva.
      </span>
    ),
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: 'Piloto enviado com problema',
  });
};

export default PilotoEnviadoComProblema;
