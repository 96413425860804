import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

const LoadingConfig: FunctionComponent<{ width?: string }> = ({
  width = '100%',
}) => {
  return (
    <div style={{ width: width }}>
      <div className='d-flex' style={{ justifyContent: 'space-between' }}>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='10%'></Skeleton>
      </div>
      <div className='d-flex' style={{ justifyContent: 'space-between' }}>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='10%'></Skeleton>
      </div>
      <div className='d-flex' style={{ justifyContent: 'space-between' }}>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='10%'></Skeleton>
      </div>
      <div className='d-flex' style={{ justifyContent: 'space-between' }}>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='44%'></Skeleton>
        <Skeleton height='46px' width='10%'></Skeleton>
      </div>
    </div>
  );
};

export default LoadingConfig;
