import {
  Accordion,
  AccordionDetails,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
  Zoom,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FunctionComponent, useContext } from 'react';
import { SelectedType } from '../SelectTipoDivergenciaStep';
import { GrupoTipoDivergencia, TipoDivergencia } from '../TiposDeDivergencias';
import { useParams } from 'react-router-dom';
import { FindMarca } from '../FindMarca';
import { UserContext, UserContextType } from '../../../../../context';
import { isSupplier } from '../../../../../tools';

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
  },
})(MuiAccordionSummary);

function isSelected(
  selected: SelectedType,
  grupo: GrupoTipoDivergencia,
  tipo: TipoDivergencia
): boolean {
  return (
    grupo.title === selected?.grupo?.title &&
    tipo.title === selected?.tipo?.title
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    accordionClass: {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
    },
    listClass: {
      background: '#F9F9F9',
      border: '2px solid #F4F3F3',
      borderRadius: '4px',
      padding: '0 32px',
      width: '100%',
    },
  })
);

const GrupoDivergenciaAccordion: FunctionComponent<{
  grupo: GrupoTipoDivergencia;
  onClick: Function;
  selected: SelectedType;
}> = ({ grupo, selected, onClick }) => {
  const { userLogged } = useContext<UserContextType>(UserContext);
  const classes = useStyles();

  const { idProducaoPppOp } = useParams<{ idProducaoPppOp: string }>();

  let id_marca = FindMarca({
    idProducaoPppOp: idProducaoPppOp
  })

  const typesOfDivergenceToHide: string[] = ['Envio sem Full Kit'];
  const isTypeOfDivergenceForHidden = (type: string): boolean => {
    return typesOfDivergenceToHide.some((item) => item.toLowerCase() === type.toLowerCase());
  };

  const shouldHideDivergence = (divergenceType: string): boolean => {
    const loggedInUserGroupId = userLogged?.id_grupo || null;
    return isSupplier(loggedInUserGroupId) && isTypeOfDivergenceForHidden(divergenceType);
  };


  return (
    <Accordion className={classes.accordionClass}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className='iconNeutral800' />}
      >
        <Typography className='body1 bold neutral800'>{grupo.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List component='div' disablePadding className={classes.listClass}>
          {grupo.tipos.map((tipo: any, indexTipo: number) => {
            let showCategory = tipo.hideSubCategory.includes(id_marca)
            if(!showCategory || shouldHideDivergence(tipo?.title)){
               return (
                <></>
              )
            }
            return (
              <ListItem
                className='pointer'
                disableGutters
                divider={indexTipo !== grupo.tipos.length - 1}
                key={`tipo${indexTipo}`}
                onClick={onClick(grupo, tipo)}
              >
                <Tooltip title={tipo.description ? tipo.description : ''}>
                <ListItemText>
                  <span className='body1 medium neutral700'>{tipo.title}</span>
                </ListItemText>
                </Tooltip>
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                  <Zoom
                    in={isSelected(selected, grupo, tipo)}
                    mountOnEnter
                    unmountOnExit
                  >
                    <DoneIcon className='iconPrimary500' />
                  </Zoom>
                </ListItemIcon>
              </ListItem>
            );             
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default GrupoDivergenciaAccordion;
