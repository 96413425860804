export type Area = {
  id: string;
  text: string;
};

const AreaTipoDivergenciaEnum: { [key: string]: Area } = {
  CONTROLE_PCP: {
    id: 'CONTROLE_PCP',
    text: 'Controle PCP',
  },
  SUSTENTABILIDADE: {
    id: 'SUSTENTABILIDADE',
    text: 'Sustentabilidade'
  },
  CORTE: {
    id: 'CORTE',
    text: 'Corte',
  },
  FINANCEIRO: {
    id: 'FINANCEIRO',
    text: 'Financeiro',
  },
  PILOTO: {
    id: 'PILOTO',
    text: 'Piloto',
  },
  PLANNER: {
    id: 'PLANNER',
    text: 'Planner',
  },
  QUALIDADE_MP: {
    id: 'QUALIDADE_MP',
    text: 'Qualidade MP',
  },
  RISCO: {
    id: 'RISCO',
    text: 'Risco',
  },
  MODELAGEM: {
    id: 'MODELAGEM',
    text: 'Modelagem',
  },
  LOGISTICA: {
    id: 'LOGISTICA',
    text: 'Logistica',
  },
  CAD_ATELIER: {
    id: 'CAD_ATELIER',
    text: 'Cad Atelier',
  },
  ESTAMPARIA: {
    id: 'ESTAMPARIA',
    text: 'Estamparia',
  },
  BORDADO: {
    id: 'BORDADO',
    text: 'Bordado',
  },
  PRE_COSTURA: {
    id: 'PRE_COSTURA',
    text: 'Pre costura',
  },
  PLANEJAMENTO_DEMANDA: {
    id: 'PLANEJAMENTO_DEMANDA',
    text: 'Planejamento e Demanda',
  },
   AJUSTE_MATERIA_PRIMA: {
    id: 'PLANEJAMENTO_DEMANDA',
    text: 'Planejamento e Demanda',
  },
};

export default AreaTipoDivergenciaEnum;
