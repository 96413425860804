import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
  buildOutrosAviamentos,
} from '../../../template/UseGetMateriais';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import { UploadQualidadeAviamentoStep } from '../../upload-step';

const QualidadeDoAviamento: TipoDivergenciaComponent = ({
  onSetStep,
  onBack,
}) => {
  const { loading, materiais } = useGetMateriais(
    TipoDivergenciasInfoKey.AVIAMENTO
  );
  const newMateriais = [...materiais, buildOutrosAviamentos()];

  const stepNumber = '2';
  const stepSubTitle = 'Selecione o aviamento com o problema de qualidade.';
  const stepTitle = 'Qual aviamento está com problema de qualidade?';
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={
        UploadQualidadeAviamentoStep as TipoDivergenciaComponent
      }
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey='aviamentos'
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};

export default QualidadeDoAviamento;
