import moment from 'moment';

interface DeliveryStatusProps {
  scheduledDeliveryDate: Date | null | undefined;
  creationDate: Date;
  idTypeDivergence: number;
  idBrand: number;
}

const isDeliveryDateValid = ({ scheduledDeliveryDate, creationDate }: Partial<DeliveryStatusProps>): boolean => {
  if (scheduledDeliveryDate && creationDate) {
    return creationDate >= scheduledDeliveryDate;
  }
  return false;
};

export const isRenderDeliveryStatus = ({
  idTypeDivergence,
  idBrand,
  scheduledDeliveryDate,
  creationDate,
}: DeliveryStatusProps): boolean => {
  const allowedDivergenceIds = [6, 12, 15];
  const allowedBrandIds = [6, 12, 13, 1820, 361151, 361157];

  return (
    allowedDivergenceIds.includes(idTypeDivergence) &&
    allowedBrandIds.includes(idBrand) &&
    isDeliveryDateValid({ scheduledDeliveryDate, creationDate })
  );
};


export const isOverdue = ({scheduledDeliveryDate, creationDate}: Pick<DeliveryStatusProps, 'creationDate' | 'scheduledDeliveryDate'>): boolean => {
    const diffDays = moment(creationDate).diff(moment(scheduledDeliveryDate), 'days');
    return diffDays > 7;
};


