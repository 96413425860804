import { gql, useQuery } from "@apollo/client";
import Skeleton from "@material-ui/lab/Skeleton";
import { loader } from "graphql.macro";
import React, { FunctionComponent, useContext } from "react";
import {
  DivergenceContext,
  DivergenceContextType,
} from "../../../../../context/divergence";
import LabelValueBase from "../../nova-divergencia/components/LabelValueBase";
import LabelValueWithSubtitle from "../../nova-divergencia/components/LabelValueWithSubtitle";
import { MaterialSelectItem } from "../../nova-divergencia/template/UseGetMateriais";
import {
  TipoAdicionalInfoAbreviacoes,
  TipoDivergenciaTranslate,
} from "../../nova-divergencia/TipoDivergenciasInfoKey.";
import { DivergenciaInfo, Material } from "../DivergenciaDetailTypes";

const query = loader("../../queries/GetMateriaisById.gql");

type MaterialCostInformation = {
  id_material_cor: number;
  id_material: number;
  custo_a_vista: number;
  id_linx_mp: string;
  id_divergencia: number;
  desc_cor: string;
  cor: string;
  desc_material_limpo: string;
  id_divergencia_info: number;
};

type GetCostInformationProps = {
  info: DivergenciaInfo | undefined;
  materialInfo?: MaterialCostInformation[];
};

function getCostInformation({ info, materialInfo }: GetCostInformationProps) {
  const dataDivergenceInfo = extractDataFromDivergenceInfo(info);

  if (
    !(
      materialInfo &&
      materialInfo?.length > 0 &&
      materialInfo[0].id_material === dataDivergenceInfo.idMaterial &&
      materialInfo[0].custo_a_vista !== null
    )
  ) {
    return "";
  }
 
  const unitPrice = materialInfo[0].custo_a_vista;
  const totalPrice =
    materialInfo[0].custo_a_vista * dataDivergenceInfo.quantity;
  return `(Preço unitário: ${unitPrice.toFixed(
    2
  )} | Total: ${totalPrice.toFixed(2)})`;
}

const MaterialInfoComponent: FunctionComponent<{
  infos: DivergenciaInfo[];
}> = ({ infos }) => {
  const { aviamentos } = useContext<DivergenceContextType>(DivergenceContext);
  const { idMaterial, idDivergenceInfo } =
    extractIdMaterialAndIdDivergenceInfo(infos);
  const QUERY_GET_COST_MATERIAL_INFO_GQL =
    makeQueryGetCostMaterialInformation();

  const ids = infos.map((item) => Number(item.value)).filter((item) => !!item);
  const { loading, data } = useQuery<{
    findManyMaterial: Material[];
  }>(query, {
    skip: ids.length === 0,
    variables: {
      ids: ids,
    },
  });
  const tipos = new Set(infos.map((item) => item.tipo));
  let label = "";
  if (tipos.size === 1) {
    label =
      TipoDivergenciaTranslate[
        infos[0].tipo as keyof typeof TipoDivergenciaTranslate
      ];
  } else {
    label = "Matéria-prima";
  }

  const materiais = data?.findManyMaterial;
  const { data: materialInfo } = useQuery<{
    getCostMaterialInformation: MaterialCostInformation[];
  }>(QUERY_GET_COST_MATERIAL_INFO_GQL, {
    variables: {
      idMaterial: idMaterial,
      idDivergenceInfo,
    },
  });

  let descricoes: string[] = [];
  descricoes.push(
    ...(materiais?.map((item) => {
      const info = infos.find(
        (itemSearch) => itemSearch.value === item.id_material.toString()
      );

      const adicionalInfo = getAdicionalInfo(info);
      const colorInfo = getColorFromMaterialInfo({
        info: info,
        aviamentos: aviamentos?.aviamentos || null,
        materialInfo: materialInfo?.getCostMaterialInformation,
      });

      return `${item.desc_material_limpo} ${colorInfo} ${adicionalInfo}`;
    }) || [])
  );
  infos.forEach((info) => {
    if (!Number(info.value)) {
      const adicionalInfo = getAdicionalInfo(info);

      descricoes.push(`${info.value}${adicionalInfo}`);
    }
  });

  const descMaterial = descricoes.join(", ");
  const materialNumber =
    materiais?.map((item) => item.id_linx_mp).join(", ") ?? "";
  const materialColor =
    materialInfo?.getCostMaterialInformation?.[0]?.cor ?? "";
  const materialInfoString =
    materialColor && materialNumber
      ? `${materialNumber} | ${materialColor}`
      : "";
  const value = descMaterial;

  const subtitle = `${materialInfoString} ${getCostInformation({
    info: infos[0],
    materialInfo: materialInfo?.getCostMaterialInformation,
  })}`;

  return loading ? (
    <LabelValueBase label={label}>
      <Skeleton variant="text" width={125} height={24} />
    </LabelValueBase>
  ) : (
    <LabelValueWithSubtitle
      label={label}
      value={value}
      subtitle={subtitle}
    ></LabelValueWithSubtitle>
  );
};

export default MaterialInfoComponent;

function getAdicionalInfo(info: DivergenciaInfo | undefined): string {
  let adicionalInfo = "";
  if (info?.InfoChildren) {
    info.InfoChildren.forEach((child) => {
      adicionalInfo = adicionalInfo.concat(
        ` (${child.value}${
          TipoAdicionalInfoAbreviacoes[
            child.tipo as keyof typeof TipoAdicionalInfoAbreviacoes
          ]
        })`
      );
    });
  }
  return adicionalInfo;
}

type GetColorFromMaterialInfoProps = {
  info: DivergenciaInfo | undefined;
  aviamentos?: MaterialSelectItem[] | null;
  materialInfo?: MaterialCostInformation[];
};
function getColorFromMaterialInfo({
  info,
  aviamentos,
  materialInfo,
}: GetColorFromMaterialInfoProps) {
  const dataDivergenceInfo = extractDataFromDivergenceInfo(info);
  if (
    materialInfo &&
    materialInfo[0].id_material === dataDivergenceInfo.idMaterial &&
    materialInfo[0].desc_cor !== null
  ) {
    return `(COR ${materialInfo[0].desc_cor})`;
  }
  const recordFound = aviamentos?.find((data) => {
    const aviamento = extractDataFromMaterialInfo(data);
    if (aviamento?.idMaterial !== dataDivergenceInfo.idMaterial) return false;
    if (aviamento?.quantity !== dataDivergenceInfo.quantity) return false;
    if (aviamento?.type !== dataDivergenceInfo.type) return false;
    return true;
  });

  return recordFound ? `(${recordFound.subtext})` : "";
}

function extractDataFromMaterialInfo(aviamento?: MaterialSelectItem) {
  let quantity = null;
  let type = null;
  const idMaterial = aviamento?.id || null;

  if (
    aviamento?.adicionalInfo &&
    aviamento?.adicionalInfo[0].tipo === "UNIDADES"
  ) {
    quantity = aviamento?.adicionalInfo[0].value;
    type = aviamento?.adicionalInfo[0].tipo || null;
  }

  return {
    quantity: Number(quantity),
    type: String(type).toUpperCase(),
    idMaterial: Number(idMaterial),
  };
}

function extractDataFromDivergenceInfo(info?: DivergenciaInfo) {
  let quantity = null;
  let type = null;
  let idMaterial = null;

  if (info?.InfoChildren && info?.InfoChildren[0]?.tipo === "UNIDADES") {
    quantity = info?.InfoChildren[0].value || null;
    type = info?.InfoChildren[0].tipo || null;
  }

  if (info?.InfoChildren && info?.InfoChildren[0]?.tipo === "QUANTIDADE_METROS") {
    quantity = info?.InfoChildren[0].value || null;
    type = info?.InfoChildren[0].tipo || null;
  }

  if (info?.tipo === "AVIAMENTO") {
    idMaterial = info?.value || null;
  }

  if (info?.tipo === "TECIDO") {
    idMaterial = info?.value || null;
  }

  return {
    quantity: Number(quantity),
    type: String(type).toUpperCase(),
    idMaterial: Number(idMaterial),
  };
}

function makeQueryGetCostMaterialInformation() {
  return gql`
    query getCostMaterialInformationGQL(
      $idMaterial: Int!
      $idDivergenceInfo: Int!
    ) {
      getCostMaterialInformation(
        idMaterial: $idMaterial
        idDivergenceInfo: $idDivergenceInfo
      ) {
        id_material_cor
        id_material
        id_divergencia_info
        id_linx_mp
        desc_material_limpo
        desc_cor
        cor
        custo_a_vista
      }
    }
  `;
}

function extractIdMaterialAndIdDivergenceInfo(info?: DivergenciaInfo[]) {
  const allowedTypes = ["AVIAMENTO", "TECIDO"];
  const returnDefault = { idMaterial: 0, idDivergenceInfo: 0 };
  if (!info) return returnDefault;
  if (!allowedTypes.includes(info[0].tipo)) return returnDefault;
  if (!info[0].value) return returnDefault;
  if (info[0].value === "Outros Aviamentos") return returnDefault;

  return {
    idMaterial: parseInt(info[0].value) || 0,
    idDivergenceInfo: info[0].id_divergencia_info || 0,
  };
}
