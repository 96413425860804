import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    stepNumber: {
      alignItems: 'center',
      border: '2px solid #97877A',
      borderRadius: '32px',
      display: 'flex',
      height: '48px',
      justifyContent: 'center',
      width: '48px',
    },
  })
);

const StepperHeader: FunctionComponent<{
  stepNumber: string;
  stepSubTitle: string;
  stepTitle: string;
}> = ({ stepNumber, stepSubTitle, stepTitle }) => {
  const classes = useStyles();

  return (
    <div className='mt32 mb32'>
      <div className='ml16 d-flex align-item-center'>
        <div className={clsx('body1 bold primary500', classes.stepNumber)}>
          {stepNumber}
        </div>
        <div className='h6 headline medium neutral700 ml16'>{stepTitle}</div>
      </div>
      <div className='body2 semi-bold neutral500 ml16 mt16'>{stepSubTitle}</div>
    </div>
  );
};

export default StepperHeader;
