import React from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import FinalStepTemplate, {
    useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import {
    buildMultiploMaterialInfoComponent,
    TecidoProps,
} from '../../../template/TecidoQuantidadeStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import { createBackHandler } from '../../../template/utils';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import DivergenciaEtiquetaxFisico from './DivergenciaEtiquetaxFisico';

const DivergenciaEtiquexFisicoFinalStep: TipoDivergenciaComponent<TecidoProps> = ({
    onSetStep,
    onBack,
    payload,
}) => {
    const onBackHandler = createBackHandler(onBack, DivergenciaEtiquetaxFisico);

    const infoComponents = [
        ...useBuildDefaultInfoComponent(
            AreaTipoDivergenciaEnum.QUALIDADE_MP,
            'Divergência Etiqueta x Físico'
        ),
        ...buildMultiploMaterialInfoComponent(payload),
    ];
    const key = TipoDivergenciasKey.DIVERGENCIA_ETIQUETA_X_FISICO;
    const {
        loadingSave,
        loadingTipoDivergencia,
        handleSubmit,
    } = useBuildSubmitHandler(key, onSetStep);

    return (
        <FinalStepTemplate
            loading={loadingSave || loadingTipoDivergencia}
            onBack={onBackHandler}
            infoMessage={
                <span>
                    Envie uma foto em anexo para obter uma resposta mais objetiva.
                </span>
            }
            onSetStep={(step) => {
                handleSubmit(
                    step,
                    payload.tecidos.map((item) => ({
                        tipo: TipoDivergenciasInfoKey.TECIDO,
                        value: item.id.toString(),
                    }))
                );
            }}
            progressValue={90}
            stepNumber={'3'}
            infoComponents={infoComponents}
        />
    );
};

export default DivergenciaEtiquexFisicoFinalStep;
