import { useRouteMatch } from 'react-router-dom';
import { useQueryString } from './Utils';

export default function useReceiveAuth() {
  const authQueryStringKey = 'jwt_token';
  const queryString = useQueryString();
  const auth = queryString.get(authQueryStringKey);
  const route = useRouteMatch();
  const open = queryString.get('open');

  if (auth) {    
    localStorage.setItem('auth', atob(auth));
    let urlLocation = route.url;

    if (open){
      urlLocation +='?open=' + open;
    }
    //console.log(urlLocation);
    window.location.replace(urlLocation);
    return;
  }
}