import { DivergenciaInfo } from '../../detail/DivergenciaDetailTypes';
import { Step } from '../NovaDivergenciaStepHandler';
import { TipoAdicionalInfoAbreviacoes } from '../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponentProps } from '../TiposDeDivergencias';
import { MaterialSelectItem } from './UseGetMateriais';

export function createValueAndSubtitleFromAdicionalInfo(
  item: MaterialSelectItem
) {
  const title = item.text;
  const codigo = item.codigo !== '0' ? `${item.codigo}` : '';
  const info = item.adicionalInfo?.map(
    (info) => `${info.value}${TipoAdicionalInfoAbreviacoes[info.tipo]}`
  );
  const value = `${title} (${info})`;
  return { value, codigo };
}

export function createBackHandler(
  onBack: (step?: Step | undefined) => void,
  backComponent: React.ComponentType<TipoDivergenciaComponentProps>
) {
  return () => {
    onBack({
      component: backComponent,
    });
  };
}

export function transforSelectItemToDivergenciaInfo(
  item: MaterialSelectItem
): DivergenciaInfo {
  return {
    tipo: item.tipo,
    value: item.id.toString(),
    InfoChildren: item.adicionalInfo?.map((info) => ({
      tipo: info.tipo,
      value: info.value.toString(),
    })),
  } as DivergenciaInfo;
}
