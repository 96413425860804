import AreaTipoDivergenciaEnum from '../../AreaTipoDivergenciaEnum';
import { useBuildFinalStepTemplate } from '../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../TiposDeDivergencias';

const QuebraDeGradeMP: TipoDivergenciaComponent = ({
  onBack,
  onSetStep,
}) => {
  const key = TipoDivergenciasKey.QUEBRA_DE_GRADE_FALTA_MP_FORN;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.CONTROLE_PCP,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: 'Quebra de grade por falta de matéria prima comprada pelo fornecedor',
  });
};

export default QuebraDeGradeMP;