import React, { createContext, ReactNode, useState } from "react";
import { LoggedUser } from "../../support/types/LoggedUser";

interface ContextProps {
  children: ReactNode;
}

export type UserContextType = {
  userLogged?: LoggedUser | null;
  setUserLogged: (newValue: LoggedUser | null) => void;
};

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

export const UserContextProvider = ({ children }: ContextProps) => {
  const [userLogged, setUserLogged] = useState<LoggedUser | null>(null);

  return (
    <UserContext.Provider
      value={{
        userLogged,
        setUserLogged,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
