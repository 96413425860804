import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import merge from "lodash.merge";
import { useParams } from "react-router-dom";
import { CustomFile } from "../../components/chat/ChatInput";
import DivergenciaRegistrada from "../DivergenciaRegistrada";
import { Step } from "../NovaDivergenciaStepHandler";
import { TipoDivergenciaInfo } from "../TipoDivergenciasInfoKey.";
import TipoDivergenciasKey from "../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../TiposDeDivergencias";

const saveMutation = loader("../../queries/CreateDivergenciaMutation.gql");
const getTipoDivergenciaByKey = loader(
  "../../queries/GetTiposDivergenciasByKeyQuery.gql"
);
const getLoggedUser = loader(
  "../../../../../support/queries/GetLoggedUserQuery.gql"
);

const useBuildSubmitHandler = (
  key: TipoDivergenciasKey,
  onSetStep: (step: Step) => void
) => {
  const [save, { loading: loadingSave }] = useMutation(saveMutation);
  const { idProducaoPppOp } = useParams<{ idProducaoPppOp: string }>();
  const { data: loggedUser } = useQuery(getLoggedUser);

  const { loading: loadingTipoDivergencia, data: tipoDivergencia } = useQuery(
    getTipoDivergenciaByKey,
    {
      variables: {
        key: key,
      },
    }
  );

  const handleSubmit = async (step: Step, addInfos?: TipoDivergenciaInfo[]) => {
    const addChatPayload = step.payload as {
      message: string;
      files: Array<CustomFile>;
    };

    save({
      variables: {
        data: buildData({
          idUsuario: loggedUser.getLoggedUser.idUsuario,
          idPppOp: parseInt(idProducaoPppOp, 10),
          idTipoDivergencia:
            tipoDivergencia?.findManyPLM_Colaborativo_Tipos_Divergencias[0]
              .id_tipo_divergencia,
          message: addChatPayload.message,
          files: addChatPayload.files,
          isZendesk: tipoDivergencia?.findManyPLM_Colaborativo_Tipos_Divergencias[0]
              .is_zendesk,
          addInfos,
        }),
        arquivosDivergencia: extractFilesDivergencia(addInfos),
      },
    })
      .then(({ data }) => {
        onSetStep({
          component: DivergenciaRegistrada as TipoDivergenciaComponent,
          isFinalStep: true,
          payload: {
            idDivergencia:
              data.createOnePLM_Colaborativo_Divergencias.id_divergencia,
          },
        });
      })
      .catch((error) => {
        console.error("Falha ao salvar nova divergencia", error);
      });
  };

  return {
    handleSubmit,
    loadingSave,
    loadingTipoDivergencia,
  };
};
export default useBuildSubmitHandler;

function buildData({
  idUsuario,
  idPppOp,
  idTipoDivergencia,
  message,
  addInfos,
  files,
  isZendesk
}: {
  idUsuario: number;
  idPppOp: number;
  idTipoDivergencia: number;
  message: string;
  files: CustomFile[];
  addInfos?: TipoDivergenciaInfo[];
  isZendesk: boolean
}): any {
  const defaultData = {
    UsuarioAbriu: {
      connect: {
        id_usuario: idUsuario,
      },
    },
    Producao_PPP_OP: {
      connect: {
        id_producao_ppp_op: idPppOp,
      },
    },
    TipoDivergencia: {
      connect: {
        id_tipo_divergencia: idTipoDivergencia,
      },
    },
    is_zendesk: isZendesk
  };

  let filesData = {};
  if (files.length) {
    filesData = {
      Arquivos: {
        create: files.map((file) => ({
          nome: file.fileId,
          nome_original: file.file.name,
        })),
      },
    };
  }

  let messageData = {};
  if (message || files.length) {
    messageData = {
      Mensagens: {
        create: {
          mensagem: message,
          EnviadoPor: {
            connect: {
              id_usuario: idUsuario,
            },
          },
          ...filesData,
        },
      },
    };
  }

  let addInfoData = {};
  if (addInfos) {
    addInfoData = {
      DivergenciaInfos: {
        create: addInfos.map((info) => ({
          tipo: info.tipo,
          value: info.value,
          id_material_cor: info.material?.id_material_cor,
          InfoChildren: {
            create: info.adicionalInfo?.map((adicional) => ({
              tipo: adicional.tipo,
              value: adicional.value,
            })),
          },
        })),
      },
    };
  }

  return merge({}, defaultData, messageData, addInfoData);
}

function extractFilesDivergencia(infos?: TipoDivergenciaInfo[]) {
  if (infos && infos?.length > 0) {
    return infos[0].filesDivergencia?.map((fileDivergencia) => ({
      fileId: fileDivergencia.fileId,
      name: fileDivergencia.name,
    }));
  }
  return [];
}
