import AreaTipoDivergenciaEnum from '../../AreaTipoDivergenciaEnum';
import { useBuildFinalStepTemplate } from '../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../TiposDeDivergencias';

const InclusaoCodBarras: TipoDivergenciaComponent = ({
  onBack,
  onSetStep,
}) => {
  const key = TipoDivergenciasKey.INCLUSAO_ARQUIVO_COD_BARRAS;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.CONTROLE_PCP,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: 'Inclusão de Arquivo Cód.Barras',
    key
  });
};

export default InclusaoCodBarras;
