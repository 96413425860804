import { Box, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ReactComponent as StackIcon } from '../../../assets/icons/stack.svg';
import ExportExcelButton from '../../components/ExportExcelButton';
import useReceiveAuth from '../../support/UseReceiveAuth';
import DivergenciaFilters from './components/DivergenciaFilters';
import DivergenciaListMenu from './components/DivergenciaListMenu';
import DivergenciasKpi from './components/DivergenciasKpi';
import DivergenciaTable from './components/DivergenciaTable';



const DivergenciaList: FunctionComponent = () => {
  useReceiveAuth();

  return (
    <div className='backgroundNeutral50 fillHeightWithTopbar'>
      <DivergenciaListMenu></DivergenciaListMenu>

      <div>
      <Box className='backgroundNeutral50' padding={2}>
        <DivergenciaFilters></DivergenciaFilters>
      </Box>
      <Box className='backgroundNeutral50 ' padding={2}>
        <DivergenciasKpi></DivergenciasKpi>
      </Box>
      <Box
        className='backgroundNeutral50'
        paddingX={2}
        marginBottom={2}
        marginTop={2}
      >
        <Grid container justify='space-around' alignItems='center'>
          <Grid item className='d-flex flexGrow1 align-item-center'>
            <StackIcon className='iconPrimary500'></StackIcon>
            <span className='h6 headline neutral700' style={{ marginLeft: 20 }}>
              Divergências
            </span>
          </Grid>
          <Grid item>
            <ExportExcelButton></ExportExcelButton>
          </Grid>
        </Grid>
        <DivergenciaTable></DivergenciaTable>
      </Box>
      </div>
    </div>
  );
};

export default DivergenciaList;
