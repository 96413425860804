// libs
import { createContext } from 'react'

interface NotificationsContext {
  refetch?: boolean
  setRefetch: (evt: boolean) => void
}

const NotificationsInitialState: NotificationsContext = {
  refetch: false,
  setRefetch: () => {
    return
  }
}

const NotificationsContext = createContext<NotificationsContext>(
  NotificationsInitialState
)

NotificationsContext.displayName = 'NotificationsContext'

export { NotificationsContext }
