import { Avatar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ColorHash from 'color-hash';
import React, { FunctionComponent } from 'react';
import { buildUserName, buildUserNickname } from '../../../../support/Utils';
import LabelValueBase from '../nova-divergencia/components/LabelValueBase';

const ColaboradorResponsavelComponent: FunctionComponent<{
  nome: string;
  hideLabel?: boolean;
  loading?: boolean;
  label?: string;
}> = ({
  nome,
  hideLabel = false,
  loading = false,
  label = 'Colaborador responsável',
}) => {
    const avatarColor = new ColorHash().hex(nome);

    return (
      <LabelValueBase label={label} hideLabel={hideLabel}>
        <div className='d-flex align-item-center'>
          <span>
            {loading ? (
              <Skeleton variant='circle'>
                <Avatar />
              </Skeleton>
            ) : (
              <Avatar style={{ backgroundColor: avatarColor }}>
                {nome?.substring(0, 1)?.toUpperCase()}
              </Avatar>
            )}
          </span>
          <span className='ml16'>
            <div className='body2 semi-bold neutral700'>
              {loading ? (
                <Skeleton variant='text' width={125} />
              ) : (
                buildUserName(nome)
              )}
            </div>
            <div className='overline medium neutral500 '>
              {loading ? (
                <Skeleton variant='text' width={125} />
              ) : (
                `@${buildUserNickname(nome)}`
              )}
            </div>
          </span>
        </div>
      </LabelValueBase>
    );
  };

export default ColaboradorResponsavelComponent;
