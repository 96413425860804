// libs
import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'


// assets
import { ColorsController } from '../../../../config/theme/colorsController'
import CheckIcon from '@material-ui/icons/Check';

//services
import { NotificationsData } from '../../queries'
import { formatDateUTC } from '../../../tools'
import { buildDivergenciaNumber } from '../../Utils';

interface NotificationCardProps {
  onClick: (data: NotificationsData) => void
  data: NotificationsData
  onClose: () => void
}

function NotificationCard({ data, onClick, onClose }: NotificationCardProps) {
   const history = useHistory()

  const resolvedBorder = data.statusRemider === 'ON_TIME' ? true : false
  const reminderDate = formatDateUTC(data.data_lembrete).format('LL')

  return (
    <Box
      p={2}
      pb={1}
      width="90%"
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      border={`2px solid ${
        resolvedBorder
          ? ColorsController.colors.success
          : ColorsController.colors.error
      }`}
      borderRadius="10px"

    >
      <Box
          width="100%"
          display="flex"
          alignItems="center"
      >
        <Button  
        onClick={() => {
           history.push({search: `open=${data.Divergencia.id_divergencia}`})
           onClose()
          }
        }>
          <Typography color="primary" style={{fontSize: 14, fontWeight: 500}}>
            {buildDivergenciaNumber(data.Divergencia.id_divergencia)} - {data.Divergencia.TipoDivergencia.tipo}
          </Typography>
        </Button>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          borderLeft={`4px solid ${
            resolvedBorder ? 'primary' : 'disabled'
          }`}
          pl={2}
        >
          <Typography style={{fontSize: 14, fontWeight: 500, color:`${ColorsController.colors.primary}`}}>
            {reminderDate}
          </Typography>
        </Box>
        <Button
          color="primary"
          endIcon={<CheckIcon />}
          onClick={() => onClick(data)}
          style={{fontSize: 12, textTransform: 'none'}}
        >
          Marcar como lido
        </Button>
      </Box>
    </Box>
  )
}

export { NotificationCard }

