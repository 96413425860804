import StatusDivergenciaEnum from '../../../../support/enums/DivergenciaStatusEnum';

export enum TipoLog {
  MUDANCA_STATUS = 'MUDANCA_STATUS',
  FINALIZACAO_DIVERGENCIA = 'FINALIZACAO_DIVERGENCIA',
  MUDANCA_TIPO = 'MUDANCA_TIPO',
  MUDANCA_SITUACAO = 'MUDANCA_SITUACAO',
  MUDANCA_SUBGRUPO_DIVERGENCIA = 'MUDANCA_SUBGRUPO_DIVERGENCIA',
  MUDANCA_AREA_RESP_SECUNDARIA = 'MUDANCA_AREA_RESP_SECUNDARIA',
  MUDANCA_CATEGORIA_AREA_RESP_SECUNDARIA = 'MUDANCA_CATEGORIA_AREA_RESP_SECUNDARIA'

}

export type DivergenciaType = {
  id_divergencia: number;
  status: StatusDivergenciaEnum;
  created_at: Date;
  TipoDivergencia: TipoDivergencia;
  UsuarioResponsavel: Usuario;
  DivergenciaInfos: DivergenciaInfo[];
  Mensagens: Mensagem[];
  UsuarioAbriu: Usuario;
  Logs: Log[];
  Producao_PPP_OP: ProducaoPPPOP;
  SituacaoDivergencia: SituacaoDivergencia;
  Divergencias_Lembretes: TypeReminders
  Multas?: Multas
  DivergenciaArquivos?: DivergenciaArquivos[]
  AreaRespSecundaria?: AreaRespSecundaria;
  CategoriaAreaRespSecundaria?: CategoriaAreaRespSecundaria;
  obs_area_secundaria: string;
};

export type Log = {
  id_divergencia_log: number;
  AlteradoPor: Usuario;
  de: string;
  para: string;
  tipo: string;
  created_at: Date;
};

export type DivergenciaInfo = {
  id_divergencia?: number;
  id_divergencia_info: number;
  tipo: string;
  value: string;
  InfoChildren: DivergenciaInfo[];
};

type Arquivo = {
  id_arquivo: number;
  nome: string;
  url: string;
  nome_original: string;
};

export type Mensagem = {
  id_mensagem: number;
  mensagem: string;
  EnviadoPor: Usuario;
  created_at: Date;
  Arquivos: Arquivo[];
};

type Usuario = {
  id_usuario: number;
  login: string;
};

type TipoDivergencia = {
  id_tipo_divergencia: number;
  tipo: string;
  area: string;
};

type SituacaoDivergencia = {
  id_situacao_divergencia: number;
  situacao: string;
};

export type StatusTransportadora = {
  id_status_op_nf: number;
  status_transportadora: string;
  ordem_producao: string;
  nf: string;
  data_hora_ocorrencia: Date;
};

type bufferPenetrationColor = {
  buffer_penetration_color: string
}

type ProducaoPPPOP = {
  id_producao_ppp_op: number;
  Ordem_Producao: OrdemProducao;
  Producao_Prog_Prod: ProducaoProgProd;
  FarolOneBeat: bufferPenetrationColor
};

type OrdemProducao = {
  id_ordem_producao: number;
  ordem_producao: string;
  primeira_data_entrega_insumos?: Date
  is_pa: boolean
};

type ProducaoProgProd = {
  id_producao_prog_prod: number;
  Produto_Cor: ProdutoCor;
};

type ProdutoCor = {
  id_produto_cor: number;
  Produto: Produto;
};

type Produto = {
  id_produto: number;
  produto: string;
  digitalizacao_interna?: boolean
  dev_interno?: boolean
  corte_interno?: boolean
  Marca: Marca
};

export type Material = {
  desc_cor: string;
  desc_material_limpo: string;
  eh_pano: boolean;
  id_linx_mp: string;
  id_material: number;
  id_material_cor?: number;
  unid_estoque?: string
};

export type NotaFiscalSaidaOS = {
  id_ordem_producao: string;
  nf_saida: string;
  data_emissao_nf: Date;
};

export type TypeReminders = {
  id_lembrete: number;
  id_divergencia: number;
  id_usuario: number;
  data_lembrete?: Date;
  visualizado: boolean;
}

type Marca = {
  id_marca: number
  marca: string
}

type Multas = {
  id_multa: number
  multa: boolean,
  observacao?: string
}

type DivergenciaArquivos = {
  id_divergencia: number
  id_divergencia_arquivo: number
  nome_arquivo: string
  url: string
}

type AreaRespSecundaria = {
  id_area: number;
  area: string;
};

type CategoriaAreaRespSecundaria = {
  id_categoria: number;
  categoria: string;
};
