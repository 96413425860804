import React, { useContext, useState } from "react";
import { Box, TextField, Snackbar, Grid } from '@material-ui/core';

import { useMutation, gql } from "@apollo/client";

import { Alert } from '@material-ui/lab';
import { UserContext, UserContextType } from '../../../../../../context';
import { isSupplier } from "../../../../../../tools";
import { ConfiguracoesKeys } from '../../../../../../support/enums/Configuracoes';
import { useConfiguracaoByKey } from '../../../../../../support/UseConfiguracao';

interface ObsData {
    idDivergence: number,
    observation?: string | undefined,
}

interface ObsProps {
    ObsDataProps: ObsData,
    divergencia: any,
}

type FeedbackType = {
    show: boolean;
    message: string;
    type: "error" | "warning" | "info" | "success";
};
const INITIAL_FEEDBACK_STATE: FeedbackType = {
    message: "",
    show: false,
    type: "info",
};

const makeUpdateAreaRespSecundariaGQL = () => {
    return gql`
mutation UpdateObsAreaRespSecundariaPLM_Colaborativo_Divergencias(
    $idDivergencia: Int!
    $novaObsAreaRespSecundariaDivergencia: String!
  ) {
    updateObsAreaRespSecundariaPLM_Colaborativo_Divergencias(
      idDivergencia: $idDivergencia
      novaObsAreaRespSecundariaDivergencia: $novaObsAreaRespSecundariaDivergencia
    ) {
      id_divergencia
    }
  }
  
`;
};

export function ObsAreaRespSecundaria({ ObsDataProps, divergencia }: ObsProps): JSX.Element {
    const { userLogged } = useContext<UserContextType>(UserContext);
    const [feedback, setFeedback] = useState<FeedbackType>(INITIAL_FEEDBACK_STATE);
    const [obsData, setObsData] = useState<ObsData>({ ...ObsDataProps })
    const disabledForm = isSupplier(userLogged?.id_grupo || null);
    const { data } = useConfiguracaoByKey(ConfiguracoesKeys.RESPONSAVEL_AREA_SECUNDARIA);

    const checkUserSecondaryResponsibility = (idUsuario?: number): boolean => {
        let usersSecondaryRespArea: number[] = [];

        if (data && data.findOnePLM_Colaborativo_Configuracoes) {
            usersSecondaryRespArea = JSON.parse(
                data.findOnePLM_Colaborativo_Configuracoes.valor
            ) as number[];
        }
        if (idUsuario) {
            return idUsuario ? usersSecondaryRespArea.includes(idUsuario) : false;
        }
        return false;
    }


    const [save, { loading: loadingSave }] = useMutation(
        makeUpdateAreaRespSecundariaGQL()
    );



    const handleSubmit = async () => {

        if (loadingSave || !obsData.observation) return;

        save({
            variables: {
                idDivergencia: obsData.idDivergence,
                novaObsAreaRespSecundariaDivergencia: obsData.observation,
            },
        }).then(() => {
            setFeedback({
                message: "OBS salvo com sucesso.",
                show: true,
                type: "success",
            });
        });

    }

    const handleBlur = () => {
        handleSubmit();
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }

    const handleCloseSnackbar = () => {
        setFeedback(INITIAL_FEEDBACK_STATE);
    };

    return (
        <> {divergencia.AreaRespSecundaria && !disabledForm && checkUserSecondaryResponsibility(userLogged?.idUsuario) &&
            <>
                <Grid item xs={12}>
                    <Box pt={1}>
                        <TextField
                            disabled={disabledForm}
                            id="observation"
                            fullWidth
                            variant="outlined"
                            label="Observação da Área Secundária"
                            placeholder="Insira o texto..."
                            value={obsData?.observation}
                            multiline
                            onChange={({ target }) => {
                                setObsData({ ...obsData, observation: target.value })
                            }}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                        />
                    </Box>
                </Grid>

                <Snackbar
                    open={feedback.show}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={feedback.type}>
                        {feedback.message}
                    </Alert>
                </Snackbar>
            </>
        }
        </>
    );
};

//export default ObsAreaRespSecundaria;