import {
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent, useContext, useState } from "react";
import {
  DivergenceContext,
  DivergenceContextType,
  FileProps,
} from "../../../../../context/divergence";
import ContinueOrBackActions from "../components/ContinueOrBackActions";
import NovaDivergenciaProgressBar from "../components/NovaDivergenciaProgressBar";
import StepperHeader from "../components/StepperHeader";
import { Step } from "../NovaDivergenciaStepHandler";
import { gql } from "@apollo/client";

import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from "../TiposDeDivergencias";
import { DropzoneArea } from "material-ui-dropzone";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

type header = {
  title: string;
  subtitle: string;
  step: string;
};

export interface UploadProps extends TipoDivergenciaComponentProps {
  payload?: any;
  header: header;
}

export const DELETE_FILE_MUTATION = gql`
  mutation deleteFile($filename: String!) {
    deleteFile(filename: $filename) {
      success
    }
  }
`;

const mutation = loader("../../../../../support/queries/UploadFile.gql");

const UploadStepTemplate: FunctionComponent<
  UploadProps & {
    nextStepComponent: TipoDivergenciaComponent;
    onBack: () => void;
    onSetStep: (step: Step) => void;
  }
> = ({ onSetStep, onBack, payload, nextStepComponent, header }) => {
  const [disabledButton, setDisabledButton] = useState(true);
  const { setFiles, files } =
    useContext<DivergenceContextType>(DivergenceContext);

  const [save] = useMutation<{
    uploadFile: {
      filename: string;
    };
  }>(mutation);

  const [deleteFile] = useMutation(DELETE_FILE_MUTATION);

  const { enqueueSnackbar } = useSnackbar();

  const checkDisableButton = () => {
    let disabled = false;

    setDisabledButton(disabled);
  };

  const handleContinue = () => {
    onSetStep({
      component: nextStepComponent,
      payload: payload,
    });
  };

  const handleChange = (event: any) => {
    if (event.length === 0) return;
    checkDisableButton();

    const newImages = event.filter((image: any) => {
      return !files.some(
        (file) => file.name === image.name && file.path === image.path
      );
    });

    if (newImages.length === 0) {
      enqueueSnackbar("As imagens já foram carregadas.", {
        variant: "warning",
        preventDuplicate: true,
      });
      return;
    }

    Promise.all(newImages.map(saveFile))
      .then((fileIds) => {
        newImages.forEach((image: any, index: number) => {
          image.fileId = fileIds[index];
        });

        setFiles((prevFiles: FileProps[]) => [...prevFiles, ...newImages]);
      })
      .catch((error) => console.error(error));

    enqueueSnackbar("Upload Completo", {
      variant: "success",
      preventDuplicate: true,
    });
  };

  const saveFile = (file: any) => {
    const variables = { variables: { file: file, type: "divergencias" } };

    return save(variables)
      .then(({ data }) => {
        const fileId = data?.uploadFile?.filename;

        return fileId;
      })
      .catch((error) => {
        console.error(error);
        return file;
      });
  };

  const useStyles = makeStyles((theme) => ({
    customDropzone: {
      border: "2px dashed #97877a",
      backgroundColor: "#f0f0f0",
      color: "#a19394",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={50}></NovaDivergenciaProgressBar>
      <DialogContent
        className="backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth"
        style={{
          padding: "0 32px",
        }}
      >
        <StepperHeader
          stepNumber={header.step}
          stepSubTitle={header.subtitle}
          stepTitle={header.title}
        ></StepperHeader>
        <div className="px16">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            <DropzoneArea
              onChange={handleChange}
              acceptedFiles={["image/*", "application/pdf"]}
              maxFileSize={5000000}
              filesLimit={5}
              clearOnUnmount={true}
              showPreviews={false}
              initialFiles={files as any}
              dropzoneClass={classes.customDropzone}
              dropzoneText="Arraste e solte a Imagem aqui ou clique"
              onDelete={(e: any) => {
                const targetFile = files.filter((file) => file.path === e.path);
                if (!targetFile || targetFile.length === 0) return;
                const variables = {
                  variables: { filename: targetFile[0].fileId },
                };

                deleteFile(variables)
                  .then((message) => {
                    setFiles(files.filter((file) => file.path !== e.path));
                  })
                  .catch((error) => console.log(error));
              }}
            />
          </div>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className="divergenciaMaxWidth"
        style={{ padding: "30px 32px" }}
      >
        <ContinueOrBackActions
          continueMessage="Continuar"
          disabled={disabledButton}
          disabledMessage="Continuar"
          onBack={onBack}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  );
};

export default UploadStepTemplate;
