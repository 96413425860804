import React from 'react';
import  {
  MateriasQuantidadeStepTemplate, MateriaisTemplateProps, StepInfosProps
} from '../../../template';
import { createBackHandler } from '../../../template/utils';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import { AjuesteNecessidadeMP } from './AjusteNecessidadeMP';
import AjusteNecessidadeMPFinalStep from './AjusteNecessidadeMPFinalStep';

const AjusteNecessidadeMPQuantidadeStep: TipoDivergenciaComponent<MateriaisTemplateProps> = ({
  onSetStep,
  onBack,
  payload,
  onClose,
}) => {
  const backComponent = AjuesteNecessidadeMP;
  const nextComponent = AjusteNecessidadeMPFinalStep;

  const stepInfos: StepInfosProps = {
    stepSubTitle: 'Digite a necessidade a ser ajustada de cada material.',
    stepTitle: 'Qual a necessidade do material?'
  }

  const onBackHandler = createBackHandler(onBack, backComponent);
  return (
    <MateriasQuantidadeStepTemplate
      nextStepComponent={nextComponent as TipoDivergenciaComponent}
      onClose={onClose}
      onSetStep={onSetStep}
      onBack={onBackHandler}
      payload={payload}
      stepInfos={stepInfos}
    ></MateriasQuantidadeStepTemplate>
  );
};

export default AjusteNecessidadeMPQuantidadeStep;
