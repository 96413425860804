import { useQuery } from '@apollo/client';
import { Box, createStyles, Grid, makeStyles } from '@material-ui/core';
import { loader } from 'graphql.macro';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { ReactComponent as Done } from '../../../../assets/icons/done.svg';
import { ReactComponent as Warning } from '../../../../assets/icons/warning.svg';
import StatusDivergenciaEnum from '../../../support/enums/DivergenciaStatusEnum';
import { useQueryString } from '../../../support/Utils';
import DivergenciasTable, {
  DivergenciasTableLoading,
} from './components/DivergenciasTable';
import DivergenciaDetail from './detail/DivergenciaDetail';
import NovaDivergenciaButton from './nova-divergencia/NovaDivergenciaStepHandler';
moment.locale('pt-br');

export function createData(
  type: string,
  number: number,
  status: string,
  responsavel: string,
  createdAt: string
): DivergenciaRow {
  return { type, number, status, responsavel, createdAt };
}

export type DivergenciaRow = {
  createdAt: string;
  number: number;
  responsavel: string;
  status: string;
  type: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    divergenciaButton: {
      border: 'none',
      height: '48px',
      width: '260px',
    },
    divergenciaNaoEncontradaButton: {
      border: '2px solid #97877A',
      padding: '16px 32px',
    },
    divergenciaNaoEncontradaButtonText: {
      background: 'red',
    },
  })
);

const divergenciasQuery = loader('./queries/ListDivergenciasQuery.gql');

type ItemDivergenciaQuery = {
  id_divergencia: number;
  status: string;
  created_at: Date;
  TipoDivergencia: {
    id_tipo_divergencia: number;
    tipo: string;
  };
  UsuarioResponsavel: {
    id_usuario: number;
    login: string;
  };
};

const DivergenciaComponent: FunctionComponent<RouteComponentProps> = (
  props
) => {
  const classes = useStyles();
  const { idProducaoPppOp } = useParams<{ idProducaoPppOp: string }>();
  const queryString = useQueryString();
  const openDivergencia = queryString.get('open');

  const [openDivergenciaModal, setOpenDivergenciaModal] = useState(false);

  if (!!openDivergencia && !openDivergenciaModal) {
    setOpenDivergenciaModal(true);
  }

  const {
    loading,
    data = { findManyPLM_Colaborativo_Divergencias: [] },
    refetch,
    networkStatus,
  } = useQuery<{
    findManyPLM_Colaborativo_Divergencias: ItemDivergenciaQuery[];
  }>(divergenciasQuery, {
    variables: {
      idProducaoPppOp: parseInt(idProducaoPppOp, 10),
    },
    notifyOnNetworkStatusChange: true,
  });

  const { rowsOpen, rowsClosed } = parseRows(data);
  const hasRowsOpen = !!rowsOpen.length;
  const hasRowsClosed = !!rowsClosed.length;

  const isReloading = networkStatus === 2 || networkStatus === 4;

  return (
    <div>
      {openDivergenciaModal && (
        <DivergenciaDetail
          open={openDivergenciaModal}
          onUpdatedDivergencia={() => {
            refetch();
          }}
          onClose={() => {
            props.history.push({
              search: '',
            });
            setOpenDivergenciaModal(false);
          }}
        />
      )}
      {hasRowsOpen && (
        <div>
          <Grid container>
            <Grid item className='d-flex flexGrow1 align-item-center'>
              <Warning className='iconPrimary500'></Warning>
              <span
                className='h6 headline neutral700'
                style={{ marginLeft: 20 }}
              >
                Divergências registradas
              </span>
              <Box flexGrow={1}></Box>
              <NovaDivergenciaButton
                classButton={`backgroundPrimary500 ${classes.divergenciaButton}`}
                iconClass='iconPrimary100'
                classText='primary100'
                onCloseModal={() => {
                  refetch();
                }}
              ></NovaDivergenciaButton>
            </Grid>
          </Grid>
          <DivergenciasTable
            loading={isReloading}
            rows={rowsOpen}
          ></DivergenciasTable>
        </div>
      )}

      {hasRowsClosed && (
        <div>
          <Grid container style={{ marginTop: hasRowsOpen ? '64px' : '' }}>
            <Grid item className='d-flex flexGrow1 align-item-center'>
              <Done className='iconPrimary500'></Done>
              <span
                className='h6 headline neutral700'
                style={{ marginLeft: 20 }}
              >
                Divergências finalizadas
              </span>
              <Box flexGrow={1}></Box>
              {!hasRowsOpen && (
                <NovaDivergenciaButton
                  classButton={`backgroundPrimary500 ${classes.divergenciaButton}`}
                  iconClass='iconPrimary100'
                  classText='primary100'
                  onCloseModal={() => {
                    refetch();
                  }}
                ></NovaDivergenciaButton>
              )}
            </Grid>
          </Grid>
          <DivergenciasTable
            loading={isReloading}
            rows={rowsClosed}
          ></DivergenciasTable>
        </div>
      )}

      {!loading && !rowsClosed.length && !rowsOpen.length && (
        <Grid container item justify='center'>
          <Grid item className='text-center'>
            <h4
              className='headline neutral800'
              style={{ marginBottom: '24px', marginTop: '150px' }}
            >
              Nenhuma divergência encontrada.
            </h4>
            <NovaDivergenciaButton
              classButton={classes.divergenciaNaoEncontradaButton}
              classText='primary500'
              iconClass='iconPrimary500'
              onCloseModal={() => {
                refetch();
              }}
            ></NovaDivergenciaButton>
          </Grid>
        </Grid>
      )}

      {loading && <DivergenciasTableLoading />}
    </div>
  );
};

export default withRouter(DivergenciaComponent);

function parseRows(data: {
  findManyPLM_Colaborativo_Divergencias: ItemDivergenciaQuery[];
}) {
  const rowsOpen = data.findManyPLM_Colaborativo_Divergencias
    .filter((item) => item.status !== StatusDivergenciaEnum.FINALIZADO)
    .map((item) => parseToDivergenciaRowType(item));

  const rowsClosed = data.findManyPLM_Colaborativo_Divergencias
    .filter((item) => item.status === StatusDivergenciaEnum.FINALIZADO)
    .map((item) => parseToDivergenciaRowType(item));
  return { rowsOpen, rowsClosed };
}

function parseToDivergenciaRowType(item: ItemDivergenciaQuery): DivergenciaRow {
  return createData(
    item.TipoDivergencia.tipo,
    item.id_divergencia,
    item.status,
    item.UsuarioResponsavel.login,
    moment(item.created_at).format('LL')
  );
}
