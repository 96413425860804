// libs
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Grid, Badge, Box, IconButton, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';

// services
import { useLazyQuery } from "@apollo/client"
import { GET_REMINDERS_COUNT_BY_USER, GetRemindersCountData } from '../../queries'
import { useLoggedUser } from '../../UseLoggedUser'

// assets
import  BellIcon  from '../../../../assets/icons/bell.svg'
import { ColorsController } from '../../../../config/theme/colorsController'

import { NotificationsDrawer } from './index'
import { NotificationsContext } from '../../../context';


export function NotificationsIcon(): JSX.Element {
    const { refetch } = useContext(NotificationsContext)

    const { loading } = useLoggedUser();
    const [open, setOpen] = useState<boolean>(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [runQuuery, {data: notificationData, error: notificationError}] =     
    useLazyQuery<GetRemindersCountData>(GET_REMINDERS_COUNT_BY_USER, {
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        runQuuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        runQuuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const notificationsCout = useMemo<number>(() => {
        if(notificationData?.getUnviewedRemindersCount && !notificationError){
            return +notificationData?.getUnviewedRemindersCount
        }  

        return 0
    }, [notificationData, notificationError])


    return(
        <>
        {loading ? (
        <Skeleton width={60} height={32} style={{margin: '0 1rem'}}></Skeleton>
      ) : (
        <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: '30px', width: 'min-content', margin: '0 1rem' }}
        >
        <Grid item>
            <IconButton onClick={handleOpen} data-testid="nav-notifications-test">
            <Badge
                badgeContent={
                notificationsCout > 0 ? (
                    <Box
                    width="20px"
                    height="20px"
                    bgcolor= {ColorsController.colors.error}
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Typography
                    style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: 'white'
                    }}
                    >
                        {notificationsCout}
                    </Typography>
                    </Box>
                ) : null
                }
            >
                <img
                src={BellIcon}
                alt="Icone de um sino para simbolizar notificações"
                />
            </Badge>
            </IconButton>
        </Grid>
        <NotificationsDrawer open={open} onClose={handleClose} />
        </Grid>
      )}
      </>
    )
}