import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { gql, loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import { loaderTheme } from '../../../support/Utils';
import { User } from '../components/InputUser';
import LoadingConfig from '../components/LoadingConfig';

const getCompradores = gql`
  query getCompradores {
    getProdutosCompradores {
      produto_compradora
      UsuarioComprador {
        id_usuario
        login
      }
    }
  }
`;
const headerClass = 'caption bold neutral500 uppercase';

const updateUserComprador = gql`
  mutation updateUserComprador($comprador: String!, $userId: Float!) {
    updateUserComprador(comprador: $comprador, userId: $userId) {
      compradora
      id_usuario
      login
    }
  }
`;

const CompradorRow: FunctionComponent<{
  row: CompradorRowType;
  users: User[];
}> = ({ row, users }) => {
  const selectedUser =
    users.find(
      (user) => user.id_usuario === row.UsuarioComprador?.id_usuario
    ) || ({} as User);
  const [value, setValue] = useState<User>(selectedUser);
  const [save, { loading }] = useMutation(updateUserComprador);
  return (
    <TableRow>
      <TableCell>{row.produto_compradora}</TableCell>
      <TableCell>
        <Autocomplete
          options={users}
          value={value}
          closeIcon={null}
          onChange={(_, newValue) => {
            setValue(newValue || ({} as User));
          }}
          getOptionSelected={(option, value) =>
            option.id_usuario === value.id_usuario
          }
          getOptionLabel={(option) => {
            return option.login || '';
          }}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} variant='outlined' size='small' fullWidth />
          )}
        />
      </TableCell>
      <TableCell>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            save({
              variables: {
                userId: value.id_usuario,
                comprador: row.produto_compradora,
              },
            });
          }}
          disabled={loading}
          fullWidth
        >
          {loading ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Salvar
            </span>
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

type CompradorRowType = {
  produto_compradora: string;
  UsuarioComprador?: User;
};

const queryUsers = loader('../../../support/queries/GetUserForMentions.gql');

const Compradores: FunctionComponent = () => {
  const {
    loading: loadingAssistentes,
    data = { getProdutosCompradores: [] },
  } = useQuery<{
    getProdutosCompradores: CompradorRowType[];
  }>(getCompradores);

  const {
    loading: loadingUsers,
    data: dataUsers = { findManyUsuario: [] },
  } = useQuery<{
    findManyUsuario: User[];
  }>(queryUsers);

  const loading = loadingAssistentes || loadingUsers;
  return (
    <div style={{ width: '50%' }}>
      {loading ? (
        <LoadingConfig></LoadingConfig>
      ) : (
        <div>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow className='tableHeader'>
                <TableCell className={headerClass}>Planner</TableCell>
                <TableCell className={headerClass}>Usuário</TableCell>
                <TableCell className={headerClass}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='backgroundNeutral50'>
              {data.getProdutosCompradores.map((item) => {
                return (
                  <CompradorRow
                    key={`responsavel-${item.produto_compradora}`}
                    row={item}
                    users={dataUsers.findManyUsuario}
                  ></CompradorRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default Compradores;
