import { useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  ThemeProvider,
  TextField,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { loaderTheme } from '../../../support/Utils';

import { Situacao } from '../tabs/Situacoes';

const deleteSituacaoMutation = loader(
  '../queries/deleteOneSituacao.gql'
);

const updateSituacaoMutation = loader(
    '../queries/updateSituacao.gql'
  );

const DivergenciaEspelhoRow: FunctionComponent<{
  row: Situacao;
  onUpdate: Function;
}> = ({ row, onUpdate }) => {
  const [deleteSituacao, { loading: loadingDelete }] = useMutation(
    deleteSituacaoMutation
  );

  const [updateSituacao, { loading: loadingUpdate }] = useMutation(
    updateSituacaoMutation
  );

  const [situacaoValue, setSituacaoValue] = React.useState(row.situacao);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSituacaoValue(event.target.value);    
  };

  return (
    <TableRow className='tableRowHover pointer'>
      <TableCell width='40%'>{row.id_situacao_divergencia}</TableCell>
      <TableCell width='40%'>
      <TextField onChange={handleChange} value={situacaoValue} variant='outlined' size='small' fullWidth />      
      </TableCell>
      <TableCell width='10%'>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            updateSituacao({
              variables: {
                id_situacao_divergencia: row.id_situacao_divergencia,
                novoValor: situacaoValue,                
              },
            }).then(() => {
                onUpdate();
            });
          }}
          fullWidth
        >
          {loadingUpdate ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Salvar
            </span>
          )}
        </Button>
      </TableCell>
      <TableCell width='10%'>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            deleteSituacao({
              variables: {
                idSituacao: row.id_situacao_divergencia,
              },
            }).then(() => {
                onUpdate();
            });
          }}
          fullWidth
        >
          {loadingDelete ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Excluir
            </span>
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default DivergenciaEspelhoRow;