import React, { FunctionComponent } from 'react';
import { ReactComponent as Done } from '../../../../../assets/icons/done.svg';
import StatusDivergenciaEnum from '../../../../support/enums/DivergenciaStatusEnum';
import { DivergenciaKpi } from './DivergenciaKpi';
import { useGetDIvergenciasStatusKpi } from './UseGetDIvergenciasStatusKpi';

const KpiDivergenciasAguardandoFinalizacao: FunctionComponent = () => {
  const { loading, data } = useGetDIvergenciasStatusKpi(
    StatusDivergenciaEnum.AGUARDANDO_FINALIZACAO
  );
  return (
    <DivergenciaKpi
      label='Divergências aguardando finalização'
      value={data?.getCountDivergenciasKPIForLoggedUser.toString()}
      icon={<Done className='iconPrimary500' width={48} height={48}></Done>}
      loading={loading}
    ></DivergenciaKpi>
  );
};

export default KpiDivergenciasAguardandoFinalizacao;
