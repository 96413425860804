import React, { FunctionComponent } from 'react';
import { ReactComponent as Messages } from '../../../../../assets/icons/messages.svg';
import StatusDivergenciaEnum from '../../../../support/enums/DivergenciaStatusEnum';
import { DivergenciaKpi } from './DivergenciaKpi';
import { useGetDIvergenciasStatusKpi } from './UseGetDIvergenciasStatusKpi';

const KpiDivergenciasEmAndamento: FunctionComponent = () => {
  const { loading, data } = useGetDIvergenciasStatusKpi(
    StatusDivergenciaEnum.EM_ANDAMENTO
  );
  return (
    <DivergenciaKpi
      label='Divergências em andamento'
      value={data?.getCountDivergenciasKPIForLoggedUser.toString()}
      icon={
        <Messages className='iconPrimary500' width={48} height={48}></Messages>
      }
      loading={loading}
    ></DivergenciaKpi>
  );
};

export default KpiDivergenciasEmAndamento;
