import React from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import {
  AviamentoProps,
  buildMultiploMaterialInfoComponent,
} from '../../../template/AviamentoQuntidadeStepTemplate';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import { createBackHandler } from '../../../template/utils';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import ComplementoDeGradeAviamento from './ComplementoDeGradeAviamento';

const ComplementoDeGradeAviamentoFinalStep: TipoDivergenciaComponent<AviamentoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = createBackHandler(onBack, ComplementoDeGradeAviamento);

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.CONTROLE_PCP,
      'Complemento de grade - Aviamento'
    ),
    ...buildMultiploMaterialInfoComponent(payload),
  ];
  const key = TipoDivergenciasKey.MP_COMPLEMENTO_DE_GRADE_AVIAMENTO;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(
          step,
          payload.aviamentos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.AVIAMENTO,
            value: item.id.toString(),
            adicionalInfo: item.adicionalInfo,
          }))
        );
      }}
      progressValue={90}
      stepNumber={'3'}
      infoComponents={infoComponents}
    />
  );
};

export default ComplementoDeGradeAviamentoFinalStep;
