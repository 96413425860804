import AreaTipoDivergenciaEnum from '../../AreaTipoDivergenciaEnum';
import { useBuildFinalStepTemplate } from '../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../TiposDeDivergencias';

const RiscoNaoEnviado: TipoDivergenciaComponent = ({ onBack, onSetStep }) => {
  const key = TipoDivergenciasKey.RISCO_NAO_ENVIADO;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);
  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.RISCO,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: 'Risco não enviada',
  });
};

export default RiscoNaoEnviado;
