import React from "react";
import MateriaisPopover from "../../../../detail/components/MateriaisPopover";
import AreaTipoDivergenciaEnum from "../../../AreaTipoDivergenciaEnum";
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from "../../../template/FinalStepTemplate";
import useBuildSubmitHandler from "../../../template/UseBuildSubmitHandler";
import { MaterialSelectItem } from "../../../template/UseGetMateriais";
import {
  createBackHandler,
  transforSelectItemToDivergenciaInfo,
} from "../../../template/utils";
import TipoDivergenciasKey from "../../../TipoDivergenciasKey";
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from "../../../TiposDeDivergencias";
import { UploadQualidadeAviamentoStep } from "../../upload-step";

interface TecidoProps extends TipoDivergenciaComponentProps {
  payload: { materiais: MaterialSelectItem[] };
}

const SobraMateriaPrimaFinalStep: TipoDivergenciaComponent<TecidoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = createBackHandler(
    onBack,
    UploadQualidadeAviamentoStep as TipoDivergenciaComponent
  );
  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.PLANNER,
      "Sobra de matéria-prima"
    ),
    <MateriaisPopover
      materiais={payload.materiais.map(transforSelectItemToDivergenciaInfo)}
    ></MateriaisPopover>,
  ];

  const key = TipoDivergenciasKey.MP_SOBRA_DE_MATERIA_PRIMA;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(
          step,
          payload.materiais.map((item) => {
            return {
              tipo: item.tipo,
              value: item.id.toString(),
            };
          })
        );
      }}
      progressValue={90}
      stepNumber={"3"}
      infoComponents={infoComponents}
    />
  );
};

export default SobraMateriaPrimaFinalStep;
