import {
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Zoom,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React, { Dispatch, FunctionComponent, SetStateAction } from "react";

export type SelectItem = {
  id: number | string;
  text: string;
  subtext?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    listClass: {
      background: "#F9F9F9",
      padding: "0 16px",
      width: "100%",
    },
  })
);

const SelectStep: FunctionComponent<{
  data: SelectItem[];
  allowSelectMany?: boolean;
  selected: SelectItem[];
  setSelecteds: Dispatch<SetStateAction<SelectItem[]>>;
}> = ({ data, allowSelectMany = false, setSelecteds, selected }) => {
  const classes = useStyles();

  const onClick = (tecidoSelected: SelectItem) => {
    if (isSelected(tecidoSelected)) {
      return setSelecteds(
        selected.filter((item) => item.id !== tecidoSelected.id)
      );
    }

    if (!allowSelectMany) {
      return setSelecteds([tecidoSelected]);
    }

    return setSelecteds([...selected, tecidoSelected]);
  };

  const isSelected = (tecido: SelectItem) => {
    return (
      selected.findIndex((item) => {
        if (item.subtext && tecido.subtext) {
          return item.id === tecido.id && item.subtext === tecido.subtext;
        }
        return item.id === tecido.id;
      }) >= 0
    );
  };

  return (
    <List disablePadding className={classes.listClass}>
      {data.map((item, index) => {
        return (
          <ListItem
            className="pointer"
            disableGutters
            divider={index !== data.length - 1}
            key={`tipo${index}`}
            onClick={() => onClick(item)}
          >
            <ListItemText>
              <div>
                <span className="subtitle1 bold neutral800">{item.text}</span>
              </div>
              {item.subtext && (
                <div className="caption medium neutral500">{item.subtext}</div>
              )}
            </ListItemText>
            <ListItemIcon
              style={{
                justifyContent: "flex-end",
              }}
            >
              <Zoom mountOnEnter unmountOnExit in={isSelected(item)}>
                <DoneIcon className="iconPrimary500" />
              </Zoom>
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SelectStep;
