// libs
import React from 'react'
import { Grid, Box, Dialog, IconButton, Button, Typography } from '@material-ui/core'


// assets
import { ColorsController } from '../../../../../config/theme/colorsController'

import CloseIcon from '@material-ui/icons/CloseRounded'

interface InformationModalButtonProps {
  label: string
  onClick: () => void
}
export interface InformationModalProps {
  variant?: 'error' | 'success'
  title: string
  description?: string | React.ReactNode
  descriptionBold?: string
  primaryBtn?: InformationModalButtonProps
  secondaryBtn?: InformationModalButtonProps
  open?: boolean
  disableBackTouch?: boolean
  onClose: () => void
}

function InformationModal({
  variant,
  title,
  description,
  primaryBtn,
  secondaryBtn,
  open,
  disableBackTouch,
  onClose,
  descriptionBold = ''
}: InformationModalProps) {
  const isError = variant === 'error'
  const decideColor = isError
    ? ColorsController.colors.error
    : ColorsController.colors.success
  return (
    <Dialog
      open={Boolean(open)}
      onClose={disableBackTouch ? undefined : onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: '15px',
          width: '300px',
          backgroundColor: decideColor
        }
      }}
    >
      <Box
        height="100%"
        width="100%"
        bgcolor={ColorsController.colors.white}
        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
      >
        <Grid
          container
          style={{
            height: '100% !important'
          }}
        >
          <Grid
            item
            xs={12}
            container
            justify="flex-end"
            style={{
              background: decideColor,

              height: 'max-content',
              paddingBottom: '2rem'
            }}
          >
            <Grid item>
              <IconButton data-testid="test-closeButton" onClick={onClose}>
                <CloseIcon
                  style={{ color: ColorsController.colors.white,  fontSize: "medium" }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    style={{color: isError ? 'error' : 'success', fontSize: 18, fontWeight: 600}}
                    align="center"
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                 <Typography
                 style={{
                  color: 'grey',
                  fontSize: 14,
                  fontWeight: 5,
                  whiteSpace: 'pre-line'
                 }}
                  align= 'center'
                 >
                  {String(description || '')}
                 </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ height: '100%' }}>
            <Box
              p={4}
              height="100%"
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
            >
              {primaryBtn ? (
                <Box width="100%">
                  <Button
                    style={{
                      background: decideColor
                    }}
                    variant="contained"
                    fullWidth
                    onClick={primaryBtn.onClick}
                    data-testid="test-primaryButton"
                    disableElevation
                  >
                    {primaryBtn.label}
                  </Button>
                </Box>
              ) : null}
              {secondaryBtn ? (
                <Box width="100%" mt={2}>
                  <Button
                    style={{
                      color: decideColor,
                      borderColor: decideColor
                    }}
                    variant="outlined"
                    fullWidth
                    onClick={secondaryBtn.onClick}
                    data-testid="test-secondaryButton"
                    disableElevation
                  >
                    {secondaryBtn.label}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export { InformationModal }
