import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState } from 'react';
import { LoggedUser } from '../../../../../support/types/LoggedUser';
import ChatInput from '../../components/chat/ChatInput';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';




const getLoggedUser = loader(
  '../../../../../support/queries/GetLoggedUserQuery.gql'
);
const saveMutation = loader('../../queries/CreateMensagemMutation.gql');

const DivergenciaDetailChat: FunctionComponent<{
  divergenciaId: number;
  onNewMessage: Function;
}> = ({ divergenciaId, onNewMessage }) => {
  const { data: loggedUser } = useQuery<{ getLoggedUser: LoggedUser }>(
    getLoggedUser
  );

  const [save, { loading: loadingSave }] = useMutation(saveMutation);
  const [key, setKey] = useState(1);
  const [alertMention,setAlertMention] = useState(false);
  const [alertMessage, setAletMessage]=useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleClose = ()  => {
    setAlertMention(false);    
  };

  return (
    <>
    <Dialog aria-labelledby="simple-dialog-title" open={alertMention} onClose={handleClose} fullScreen={fullScreen} >
    <DialogTitle id="simple-dialog-title">Atenção</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1">{alertMessage}</Typography>
        </DialogContentText>
    </DialogContent>

    <DialogActions>          
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
    </DialogActions>
        
    </Dialog>
    <ChatInput
      key={`chatkey${key}`}
      loading={loadingSave}
      showSaveButton
      onSaveMessage={(message, files) => {
        if(((message.match(new RegExp("@", "g")) || []).length)<1){
          setAletMessage('Prezado, favor mencionar um usuário (@nome do usuário) na mensagem antes de enviá-la.');
          setAlertMention(true);
          return false;
        }
        if(((message.match(new RegExp("@", "g")) || []).length)>1){
          setAletMessage('Prezado, favor mencionar somente um usuário (@nome do usuário) na mensagem antes de enviá-la.');
          setAlertMention(true);
          return false;
        }
        let fileData = {};
        if (files.length) {
          fileData = {
            Arquivos: {
              create: files.map((file) => ({
                nome: file.fileId,
                nome_original: file.file.name,
              })),
            },
          };
        }

        const variables = {
          mensagem: message,
          Divergencia: {
            connect: {
              id_divergencia: divergenciaId,
            },
          },
          EnviadoPor: {
            connect: {
              id_usuario: loggedUser?.getLoggedUser.idUsuario,
            },
          },
          ...fileData,
        };
        save({
          variables: {
            data: variables,
          },
        }).then(() => {
          setKey(key + 1);
          onNewMessage();
        });
      }}
    />
    </>
  );
};

export default DivergenciaDetailChat;
