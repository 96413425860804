import { useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@material-ui/core";
import { loader } from "graphql.macro";
import { useSnackbar } from "notistack";
import React, { FunctionComponent, useState } from "react";
import DivergenciaStatusEnum from "../../../../../support/enums/DivergenciaStatusEnum";
import { LoggedUser } from "../../../../../support/types/LoggedUser";
import { useLoggedUser } from "../../../../../support/UseLoggedUser";
import { DivergenciaType } from "../DivergenciaDetailTypes";

const updateStatusMutation = loader(
  "../../queries/UpdateStatusDivergencia.gql"
);

const FinalizarDivergenciaButton: FunctionComponent<{
  divergencia: DivergenciaType;
  onStatusChange: Function;
}> = ({ divergencia, onStatusChange }) => {
  const { loading: loadingUser, data: userData } = useLoggedUser();

  const canFinalizarDivergencia = (user?: LoggedUser) => {
  const tiposPermitidosAjusteMatPrima = [73, 74, 75, 76, 77];
  const idUsuarioPlanejamentoDemanda = 10947;
  return (
    user?.idUsuario === divergencia.UsuarioAbriu.id_usuario || 
    user?.admin || 
    (divergencia.UsuarioResponsavel.id_usuario === idUsuarioPlanejamentoDemanda &&
    tiposPermitidosAjusteMatPrima.includes(Number(divergencia.TipoDivergencia.id_tipo_divergencia)))
  );
  };

  const [save, { loading: loadingSave }] = useMutation(updateStatusMutation);
  const [changed, setChanged] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const finalizar = () => {
    if (loadingSave) return;
    if (changed) return;

    save({
      variables: {
        idDivergencia: divergencia.id_divergencia,
        novoStatus: DivergenciaStatusEnum.FINALIZADO,
      },
    })
      .then(() => {
        setChanged(true);
        onStatusChange();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        onStatusChange();
      });
  };
  return (
    <React.Fragment>
      {!loadingUser && canFinalizarDivergencia(userData?.getLoggedUser) && (
        <Button
          className="backgroundPrimary500"
          fullWidth
          size="large"
          variant="contained"
          onClick={finalizar}
          disabled={loadingSave}
        >
          {loadingSave ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <span
              className="button bold primary100"
              id="finalizarDivergenciaButton"
            >
              Finalizar divergência
            </span>
          )}
        </Button>
      )}
    </React.Fragment>
  );
};

export default FinalizarDivergenciaButton;
