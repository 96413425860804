import React, { FunctionComponent } from 'react';
import PaginationDefault from '../../../support/components/Pagination';

export const ProductPagination: FunctionComponent<{ totalRows: number }> = ({
  totalRows = 0,
}) => {
  return (
    <PaginationDefault
      pushToPathname='/produtos'
      totalRows={totalRows}
    ></PaginationDefault>
  );
};
