import { useQuery } from '@apollo/client';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import _get from 'lodash.get';
import moment from 'moment';
import React, { FunctionComponent, useState, CSSProperties } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PaginationDefault from '../../../support/components/Pagination';
import PaginationEnum from '../../../support/enums/PaginationEnum';
import {
  buildDivergenciaNumber,
  buildUserName,
  loaderTheme,
  useQueryString,
  buildUserNameMention,
} from '../../../support/Utils';
import ColaboradorResponsavelComponent from '../../product-detail/divergencias/components/ColaboradorResponsavelComponent';
import {
  DivergenciasTableLoading,
  StatusColumn,
} from '../../product-detail/divergencias/components/DivergenciasTable';
import DivergenciaDetail from '../../product-detail/divergencias/detail/DivergenciaDetail';
import { ProductInfoTableColumn } from '../../product-list/components/ProductInfoTableColumn';
import { isRenderDeliveryStatus } from './functions'
import { Divergencia } from '../DivergenciaListType';
import { DeliveryStatusComponent } from './DeliveryStatus'


const headerClass = 'caption bold neutral500 uppercase';

const divStyle: CSSProperties = {
  // overflowX: 'auto', 
  width: '100%',
  paddingBottom: '10px',
  //maxHeight:'100%',
}
const divStyleTable: CSSProperties = {
  minWidth: '100%',

}

const query = loader('../queries/GetDivergenciasList.gql');

const DivergenciaTable: FunctionComponent<RouteComponentProps> = (props) => {
  const queryString = useQueryString();
  const openDivergencia = queryString.get('open');
  const [openDivergenciaModal, setOpenDivergenciaModal] = useState(false);

  if (!!openDivergencia && !openDivergenciaModal) {
    setOpenDivergenciaModal(true);
  }
  const [lastCount, setLastCount] = useState(0);

  const page = parseInt(queryString.get('page') || '1', 10);
  const {
    loading,
    data = {
      findManyPLM_Colaborativo_DivergenciasWithColors: [],
      findCountWithColors: lastCount,
    },
    refetch,
    networkStatus,
  } = useQuery<{
    findManyPLM_Colaborativo_DivergenciasWithColors: Divergencia[];
    findCountWithColors: number;
  }>(query, {
    onCompleted: (data) => {
      setLastCount(data.findCountWithColors);
    },
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: (page - 1) * PaginationEnum.perPage,
      take: PaginationEnum.perPage,
      status: queryString.get('status') || undefined,
      cor: queryString.get('cor') || undefined,
      fornecedor: queryString.get('fornecedor') || undefined,
      responsavelId: queryString.get('responsavelId') || undefined,
      idTipoDivergencia: queryString.get('idTipoDivergencia') || undefined,
      idSituacaoDivergencia: queryString.get('idSituacaoDivergencia') || undefined,
      canal: queryString.get('canal') || undefined,
    },
  });
  const totalRows = data.findCountWithColors;
  const isReloading = networkStatus === 2 || networkStatus === 4;
  const isFirstLoading = loading && networkStatus === 1;

  return (
    <div>
      {openDivergenciaModal && (
        <DivergenciaDetail
          open={openDivergenciaModal}
          onUpdatedDivergencia={() => {
            refetch();
          }}
          onClose={() => {
            queryString.delete('open');
            props.history.push({
              search: queryString.toString(),
            });
            setOpenDivergenciaModal(false);
          }}
        />
      )}
      {isFirstLoading ? (
        <DivergenciasTableLoading> </DivergenciasTableLoading>
      ) : (
        <div style={divStyle}>
          <Table aria-label='simple table' stickyHeader style={divStyleTable}>
            <TableHead>
              <TableRow className='tableHeader'>
                <TableCell className={headerClass}>PRODUTO</TableCell>
                <TableCell className={headerClass}>OP</TableCell>
                <TableCell className={headerClass}>FORNECEDOR</TableCell>
                <TableCell className={headerClass}>RESPONSÁVEL</TableCell>
                <TableCell className={headerClass}>ABERTO EM</TableCell>
                <TableCell className={headerClass}>STATUS</TableCell>
                <TableCell className={headerClass}>ÚLTIMA MENÇÃO</TableCell>
                <TableCell className={headerClass}>CANAL</TableCell>
                <TableCell className={headerClass}>DESCRIÇÃO</TableCell>
                <TableCell className={headerClass}>LT DE ABERTURA</TableCell>

              </TableRow>
            </TableHead>
            <TableBody className='backgroundNeutral50'>
              {isReloading && (
                <TableRow>
                  <TableCell colSpan={9} style={{ padding: 0 }}>
                    <ThemeProvider theme={loaderTheme}>
                      <LinearProgress />
                    </ThemeProvider>
                  </TableCell>
                </TableRow>
              )}
              {data.findManyPLM_Colaborativo_DivergenciasWithColors.map(
                (row) => {
                  return (
                    <TableRow
                      key={`idDivergencia-${row.id_divergencia}`}
                      className='tableRowHover pointer'
                      onClickCapture={() => {
                        queryString.set('open', row.id_divergencia.toString());
                        props.history.push({
                          search: queryString.toString(),
                        });
                      }}
                    >
                      <TableCell width='20%'>
                        <ProductInfoTableColumn
                          color={_get(
                            row,
                            'Producao_PPP_OP.FarolOneBeat.buffer_penetration_color',
                            'Light Gray'
                          )}
                          image={_get(
                            row,
                            'ProdutoEstiloArquivos[0].nome_arquivo'
                          )}
                          subTitle={_get(
                            row,
                            'Producao_PPP_OP.Producao_Prog_Prod.Produto_Cor.desc_cor_produto'
                          )}
                          title={_get(
                            row,
                            'Producao_PPP_OP.Producao_Prog_Prod.Produto_Cor.Produto.desc_produto'
                          )}
                          number={_get(
                            row,
                            'Producao_PPP_OP.Producao_Prog_Prod.Produto_Cor.Produto.produto'
                          )}
                        ></ProductInfoTableColumn>
                      </TableCell>
                      <TableCell width='5%'>
                        {row.Producao_PPP_OP?.Ordem_Producao?.ordem_producao}
                      </TableCell>
                      <TableCell width='10%'>
                        {
                          row.Producao_PPP_OP?.Ordem_Producao?.Fornecedor
                            ?.fornecedor
                        }
                      </TableCell>
                      <TableCell width='10%'>
                        <ColaboradorResponsavelComponent
                          nome={buildUserName(row.UsuarioResponsavel?.login)}
                          hideLabel
                        ></ColaboradorResponsavelComponent>
                      </TableCell>
                      <TableCell width='10%'>
                        <AbertoPor
                          userName={row.UsuarioAbriu.login}
                          data={row.created_at}
                          dataFinalizado={row.finalizado_at}
                        ></AbertoPor>
                      </TableCell>
                      <TableCell width='5%'>
                        <StatusColumn status={row.status || ''}></StatusColumn>
                      </TableCell>
                      {/* <TableCell width='10%'>
                        {
                          (row.Logs[0]?.created_at > row.Mensagens[0]?.created_at ? buildUserName(row.Logs[0]?.AlteradoPor?.login || '') : buildUserName(row.Mensagens[0]?.EnviadoPor?.login || '') )
                        }
                      </TableCell> */}
                      <TableCell width='10%'>
                        {
                          //(row.DivergenciasSeguidores[0]?.UsuarioSeguidor ? buildUserName(row.DivergenciasSeguidores[0]?.UsuarioSeguidor.login || '') : '' )
                          (row.MensagensMencao[0] ? buildUserNameMention(row.MensagensMencao[0]?.mensagem || '') : '')
                        }

                      </TableCell>
                      <TableCell width='10%'>
                        {
                          row.Producao_PPP_OP?.Producao_Prog_Prod?.Programacao?.tipo_programacao
                        }
                      </TableCell>
                      <TableCell width='10%'>
                        <div className='body2 medium neutral800'>
                          {row.TipoDivergencia.tipo}
                        </div>
                        <div className='caption bold neutral500'>
                          {buildDivergenciaNumber(row.id_divergencia)}
                        </div>
                      </TableCell>
                      <TableCell>
                        {isRenderDeliveryStatus({
                          idBrand: row.Producao_PPP_OP.Producao_Prog_Prod.Produto_Cor.Produto.Marca.id_marca, idTypeDivergence: row.TipoDivergencia.id_tipo_divergencia,
                          scheduledDeliveryDate: row.Producao_PPP_OP.Ordem_Producao?.primeira_data_entrega_insumos, creationDate: row.created_at
                        }) ? (
                          <DeliveryStatusComponent scheduledDeliveryDate={row.Producao_PPP_OP.Ordem_Producao?.primeira_data_entrega_insumos} creationDate={row.created_at} />
                        ) : ''
                        }

                      </TableCell>
                      {/* <TableCell width='20%'>
                        <div className='body2 medium neutral800'>
                          {row.SituacaoDivergencia ? row.SituacaoDivergencia.situacao : ''}
                        </div>                        
                      </TableCell> */}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
          <PaginationDefault
            pushToPathname='/divergencias'
            totalRows={totalRows}
          ></PaginationDefault>
        </div>
      )}
    </div>
  );
};

const AbertoPor: FunctionComponent<{
  data: Date;
  userName: string;
  dataFinalizado?: Date;
}> = ({ data, userName, dataFinalizado }) => {
  const momentData = moment(data);
  return (
    <div>
      <div className='body2 medium neutral800'>
        {/* <span className='bold'>{buildUserName(userName)} </span> */}
        {/* {!dataFinalizado && <span>{momentData.fromNow()}</span>} */}
        {<span>{momentData.fromNow()}</span>}
      </div>
    </div>
  );
};

export default withRouter(DivergenciaTable);
