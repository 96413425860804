import React, { FunctionComponent } from 'react';
import { ConfiguracoesKeys } from '../../enums/Configuracoes';
import { useConfiguracaoByKey } from '../../UseConfiguracao';
import { useLoggedUser } from '../../UseLoggedUser';
import Topbar, { UserWithPermission } from './Topbar';

const TopbarWrapper: FunctionComponent = () => {
  const { loading: loadingUrl, data: dataUrl } = useConfiguracaoByKey(
    ConfiguracoesKeys.URL_PLM
  );

  let urlPlm = '';
  if (dataUrl && dataUrl?.findOnePLM_Colaborativo_Configuracoes.valor) {
    urlPlm = dataUrl.findOnePLM_Colaborativo_Configuracoes.valor;
  }

  const { loading: loadingUser, data: dataUser } = useLoggedUser();
  let permissions: string[] = [];
  let userWithPermission = {} as UserWithPermission;
  userWithPermission.grupoAreas = [];
  userWithPermission.isAdmin = false;
  userWithPermission.isSuperUser = false;
  if (dataUser && dataUser.getLoggedUser) {
    permissions =
      dataUser.getLoggedUser.Grupo?.Areas?.map((item) => item.area) || [];
    userWithPermission.id_usuario = dataUser.getLoggedUser.idUsuario;
    userWithPermission.isAdmin = dataUser.getLoggedUser.admin;
    userWithPermission.grupoAreas = permissions;
    userWithPermission.id_grupo = dataUser.getLoggedUser.id_grupo;
    userWithPermission.isSuperUser = dataUser.getLoggedUser.super_user;
  }

  //console.log(userWithPermission)

  return (
    <Topbar
      loading={loadingUser || loadingUrl}
      urlPlm={urlPlm}
      userWithPermission={userWithPermission}
    ></Topbar>
  );
};

export default TopbarWrapper;
