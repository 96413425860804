import { gql, useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import EquipeRow from '../components/EquipeRow';
import LoadingConfig from '../components/LoadingConfig';
import NewEquipe from '../components/NewEquipe';

const getEquipesQuerys = gql`
  query getEquipes {
    findManyPLM_Colaborativo_Equipe {
      id_equipe
      nome_equipe
      PLM_Colaborativo_Usuario_Equipe {
        id_usuario_equipe
        Usuario {
          id_usuario
          login
        }
        is_lider
        recebe_notificao_equipe
      }
    }
  }
`;

export type EquipeType = {
  id_equipe: number;
  nome_equipe: string;
  PLM_Colaborativo_Usuario_Equipe: PLMColaborativoUsuarioEquipe[];
};

export type PLMColaborativoUsuarioEquipe = {
  id_usuario_equipe: number;
  Usuario: Usuario;
  is_lider: boolean;
  recebe_notificao_equipe: boolean;
};

type Usuario = {
  id_usuario: number;
  login: string;
};

const Equipes: FunctionComponent = () => {
  const { loading, data, refetch } = useQuery<{
    findManyPLM_Colaborativo_Equipe: EquipeType[];
  }>(getEquipesQuerys);
  return (
    <div style={{ width: '50%' }}>
      {loading ? (
        <LoadingConfig></LoadingConfig>
      ) : (
        <div>
          <NewEquipe
            onUpdate={() => {
              refetch();
            }}
          ></NewEquipe>
          <div className='mt16'></div>
          {data?.findManyPLM_Colaborativo_Equipe.map((equipe) => {
            return (
              <EquipeRow
                key={`equipe-${equipe.id_equipe}`}
                equipe={equipe}
                onUpdate={() => {
                  refetch();
                }}
              ></EquipeRow>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Equipes;
