import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { FunctionComponent } from 'react';
//import { ConfiguracoesKeys } from '../../enums/Configuracoes';
//import { useConfiguracaoByKey } from '../../UseConfiguracao';
import menus, { MenuType } from './Menus';
import TopbarMenuList from './TopbarMenuList';
import TopbarMenuSingle from './TopbarMenuSingle';
import TopbarUserMenu from './TopbarUserMenu';
import { NotificationsIcon } from '../notifications'

const logo = require('../../../../assets/logo_soma.png');

const TopbarMenu: FunctionComponent<{
  menu: MenuType;
  userWithPermission: UserWithPermission;
  urlPlm: string;
}> = ({ menu, userWithPermission, urlPlm }) => {
  return (
    <div>
      {menu.submenus.length > 0 ? (
        <TopbarMenuList
          menu={menu}
          userWithPermission={userWithPermission}
          urlPlm={urlPlm}
        ></TopbarMenuList>
      ) : (
        <TopbarMenuSingle menu={menu} urlPlm={urlPlm}></TopbarMenuSingle>
      )}
    </div>
  );
};

export type UserWithPermission = {
  id_usuario: number;
  isAdmin: boolean;
  isSuperUser: boolean;
  grupoAreas: string[];
  id_grupo?: number;
};

const Topbar: FunctionComponent<{
  loading?: boolean;
  userWithPermission: UserWithPermission;
  urlPlm: string;
}> = ({ loading = false, userWithPermission, urlPlm }) => {
  /* const { data, loading: loadingAdmin } = useConfiguracaoByKey(
    ConfiguracoesKeys.USUARIOS_ADMINISTRADORES
  ); */

  /* let admins: number[] = [];

  if (data && data.findOnePLM_Colaborativo_Configuracoes) {
    admins = JSON.parse(
      data.findOnePLM_Colaborativo_Configuracoes.valor
    ) as number[];
  } */

  //const isLoading = loading || loadingAdmin;
  const isLoading = loading;

  const localMenus = [...menus];
  const configuracoesPLM = {
    label: 'Configurações PLM Colaborativo',
    isOldPlm: false,
    path: '/configuracoes',
    submenus: [],
  };

  //if (admins.includes(userWithPermission.id_usuario)) {
  //  localMenus.push(configuracoesPLM);
  //}
  //console.log(userWithPermission);
  if (userWithPermission.isAdmin) {
    localMenus.push(configuracoesPLM);
  }

  return (
    <Box
      alignItems='center'
      borderBottom='2px solid #F4F3F3'
      display='flex'
      height='56px'
      padding='8px 16px'
    >
      <Box className='mr16'>
        <img src={logo} alt='Logo SOMA'></img>
      </Box>
      {isLoading ? (
        <div className='d-flex align-item-center'>
          <Skeleton className='ml8' width={100} height={30} />
          <Skeleton className='ml8' width={100} height={30} />
          <Skeleton className='ml8' width={100} height={30} />
          <Skeleton className='ml8' width={100} height={30} />
          <Skeleton className='ml8' width={100} height={30} />
        </div>
      ) : (
        localMenus.map((menu, index) => {
          return (
            <TopbarMenu
              key={`menu-${index}`}
              menu={menu}
              userWithPermission={userWithPermission}
              urlPlm={urlPlm}
            ></TopbarMenu>
          );
        })
      )}

      <div className='flexGrow1'></div>
      <NotificationsIcon/>
      <TopbarUserMenu urlPlm={urlPlm}></TopbarUserMenu>
    </Box>
  );
};

export default Topbar;
