import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
  buildOutrosTecidos,
} from '../../../template/UseGetMateriais';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import RiscoErradoLarguraStep from './RiscoErradoLarguraStep';

const RiscoErrado: TipoDivergenciaComponent = ({ onSetStep, onBack }) => {
  const { loading, materiais } = useGetMateriais(
    TipoDivergenciasInfoKey.TECIDO
  );
  const newMateriais = [...materiais, buildOutrosTecidos()];

  const stepNumber = '2';
  const stepSubTitle = 'Selecione o tecido com o risco errado.';
  const stepTitle = 'Qual tecido está com o risco errado?';
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={RiscoErradoLarguraStep as TipoDivergenciaComponent}
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey='tecidos'
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};

export default RiscoErrado;
