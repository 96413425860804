import React, { useContext, useEffect, useState } from "react";
import {  Snackbar, TextField } from "@material-ui/core";
import { TypeReminders } from "../../DivergenciaDetailTypes";
import { useMutation } from "@apollo/client";
import { Alert } from "@material-ui/lab";
import LabelValueBase from "../../../nova-divergencia/components/LabelValueBase";
import moment from "moment";
import { UPSERT_REMINDER, UpsertReminderData, UpsertReminderVars } from '../../../mutations'
import { NotificationsContext } from "../../../../../../context";


type ReminderDateProps = {
  idDivergence: number;
  reminder: TypeReminders | undefined;
   onStatusChange: Function;
};

type FeedbackType = {
  show: boolean;
  message: string;
  type: "error" | "warning" | "info" | "success";
};

const INITIAL_VALUE_FOR_FEEDBACK: FeedbackType = {
  message: "",
  show: false,
  type: "info",
};

const ReminderDate: React.FC<ReminderDateProps> = ({
  idDivergence, reminder, onStatusChange
}: ReminderDateProps) => {
  const { setRefetch, refetch } = useContext(NotificationsContext)

  const [reminderDate, setReminderDate] = useState<string | undefined>();
  const [feedback, setFeedback] = useState<FeedbackType>(
  INITIAL_VALUE_FOR_FEEDBACK
  );

  const handleCloseSnackbar = () => {
    setFeedback(INITIAL_VALUE_FOR_FEEDBACK);
  };

  useEffect(() => {
    if(reminder){
      const reminderDateUTC = moment.utc(reminder.data_lembrete).format('YYYY-MM-DD');
      setReminderDate(reminderDateUTC) 
    }
  }, [reminder]);


  const [runMutation, { loading }] = useMutation<UpsertReminderData, UpsertReminderVars>(
    UPSERT_REMINDER,
    {
      onCompleted: () => {
        setRefetch(!refetch)
      }
    }
  )
  
  const handleChangeReminder = async (date: string): Promise<void> => {
    try {
      const reminderDate = moment(date).format('YYYY-MM-DD');
      const  { data } = await runMutation({
        variables: {
          input: {
              id_divergencia: idDivergence, 
              reminderDate: new Date(reminderDate)
          }
        }
      })

      if(data){
        setFeedback({
          message: "Lembrete salvo com sucesso.",
          show: true,
          type: "success",
        });
        onStatusChange();
      }
    } catch (error) {
      const { message } = error as any
      setFeedback({
        message,
        show: true,
        type: "error",
      });
    }
  }

  
  return(
    <>
      <LabelValueBase label='Lembrete de notificação:'>
        <TextField
        id="reminderDate"
        type="date"
        value={reminderDate}
        disabled={loading}
        variant="outlined"
        onChange={({target}) => {
          setReminderDate(target.value)
          handleChangeReminder(target.value);
        }}
        />
      </LabelValueBase>
      <Snackbar
          open={feedback.show}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={feedback.type}>
            {feedback.message}
          </Alert>
      </Snackbar>
    </>
  )
}


export default ReminderDate;