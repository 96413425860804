import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { isOverdue } from './functions';

interface DeliveryStatusProps {
  scheduledDeliveryDate: Date;
  creationDate: Date;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '10px',
      padding: '8px 16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'lightgreen',
      border: '1px solid lightgray',
    },
    overdue: {
      backgroundColor: 'lightcoral',
    },
  })
);

export function DeliveryStatusComponent ({ scheduledDeliveryDate, creationDate }: DeliveryStatusProps): JSX.Element {
  const classes = useStyles();
  const isOverdueStatus = isOverdue({ scheduledDeliveryDate, creationDate });

  const statusClass = `${classes.root} ${isOverdueStatus ? classes.overdue : ''}`;
  const label = isOverdueStatus ? 'Aberto fora do prazo' : 'Aberto dentro do prazo';

  return (
    <Paper className={statusClass} elevation={3}>
      <Typography>{label}</Typography>
    </Paper>
  );
};

