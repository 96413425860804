import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { BufferPenetrationColorHex } from '../../../support/enums/BufferPenetrationColorHex';
import { coalesce } from '../coalesce';

const notFoundImage = require('../../../../assets/notfound.svg');

const useClasses = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      display: 'flex',
      alignItems: 'center',
    },
    thumbnail: {
      width: '56px',
      height: '56px',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  })
);

const BufferColor: FunctionComponent<{
  color: string;
}> = ({ color }) => {
  return (
    <div
      style={{
        minWidth: '12px',
        minHeight: '12px',
        borderRadius: '50%',
        background:
          BufferPenetrationColorHex[
          color as keyof typeof BufferPenetrationColorHex
          ],
      }}
    ></div>
  );
};

const ProductImage: FunctionComponent<{
  nomeArquivo?: string;
  alt?: string;
}> = ({ nomeArquivo, alt }) => {
  const classes = useClasses();
  let src = notFoundImage;
  if (nomeArquivo) {
    src = nomeArquivo;
  }
  return <img className={classes.thumbnail} src={src} alt={alt} />;
};

const ProductInfo: FunctionComponent<{
  info: {
    title: string;
    number: string;
    subtitle: string;
  };
}> = ({ info }) => {
  return (
    <div>
      <div className='body2 neutral800 capitalize'>
        {coalesce(info, 'title')}
      </div>
      <span className='caption bold neutral500'>
        {coalesce(info, 'number')}
      </span>
      <span className='caption neutral300 medium'> - </span>
      <span className='caption neutral500 medium'>
        {coalesce(info, 'subtitle')}
      </span>
    </div>
  );
};

export const ProductInfoTableColumn: FunctionComponent<{
  color: string;
  image: string;
  number: string;
  subTitle: string;
  title: string;
}> = ({ color, subTitle, image, title, number }) => {
  const rowClass = useClasses();
  return (
    <div className={rowClass.name}>
      <BufferColor color={color}></BufferColor>
      <Box marginX={2}>
        <ProductImage nomeArquivo={image} alt={subTitle}></ProductImage>
      </Box>
      <ProductInfo
        info={{
          number,
          subtitle: subTitle,
          title,
        }}
      ></ProductInfo>
    </div>
  );
};
