import { ColorsTheme } from './colorsTheme'

export class ColorsController {
  public static colors: ColorsTheme = {
    // primary
    primary: '#97877a',
    backgroundPrimary: '#F1F5F7',
    pastelPrimary: '#FCF4F4',
    // secondary
    secondary: '#144882',
    secondaryLighter: '#0081B4',
    pastelSecondary: '#E8F7FF',
    // status
    success: '#00C54F',
    pastelSuccess: '#CCF3DC',
    error: '#E25050',
    pastelError: '#FFEAEA',
    alert: '#FFD80B',
    warning: '#FF800B',
    pastelWarning: '#FFE6CE',
    // grey
    grey: '#9E9E9E',
    greyHighlight: '#C4C4C4',
    // default
    white: '#fff',
    black: '#333',
    // white: '#333',
    // black: '#fff',
    disabled: '#dfdfdf',
    // text
    textPrimary: '#77787B',
    tableText: '#525252',
    // mui
    placeholder: '#d4d8d9',
    fieldDisabled: '#f6f9fb',
    // no pattern (unused)
    darkGrey: '#565656',
    buttonSecondary: '#89A7E0',
    backgroundSecondary: '#f00',
    textLightBlue: '#89A7E0',
    textLink: '#1fb1d5',
    textBlue: '#00a6d0',
    backButtonText: '#B1B3B3'
  }
}