import { Box, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { SearchComponent } from '../../../components/SearchComponent';
import { useQueryString } from '../../../support/Utils';

export const TopBar: FunctionComponent = () => {
  let queryString = useQueryString();
  const searched = queryString.get('search');
  return (
    <Grid container item xs={12} alignItems='center'>
      <Grid container item xs={9} justify='flex-start' alignItems='center'>
        <Grid item xs={12}>
          <Box marginY={2} clone>
            <h4 className='headline neutral800 text-truncate'>
              {searched ? (
                <div>
                  Resultados para “
                  <span style={{ color: '#97877A' }}>{searched}</span>”
                </div>
              ) : (
                <span style={{ color: '#97877A' }}>Lista de produtos</span>
              )}
            </h4>
          </Box>
        </Grid>
      </Grid>
      <SearchComponent></SearchComponent>
    </Grid>
  );
};
