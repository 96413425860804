import React from "react";
import AreaTipoDivergenciaEnum from "../../../AreaTipoDivergenciaEnum";
import LabelValueComponent from "../../../components/LabelValueComponent";
import { SelectItem } from "../../../components/SelectStep";
import useBuildSubmitHandler from "../../../template/UseBuildSubmitHandler";
import TipoDivergenciasKey from "../../../TipoDivergenciasKey";
import TipoDivergenciasInfoKey from "../../../TipoDivergenciasInfoKey.";
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from "../../../template/FinalStepTemplate";
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from "../../../TiposDeDivergencias";
import CorteIncorretoLarguraStep from "./ReacaoTecidoPosCorteLarguraStep";

interface ReacaoTecidoPosCorteProps extends TipoDivergenciaComponentProps {
  payload: { tecidos: SelectItem[]; largura: string };
}

const ReacaoTecidoPosCorte: TipoDivergenciaComponent<
  ReacaoTecidoPosCorteProps
> = ({ onSetStep, onBack, payload }) => {
  const onBackHandler = () => {
    onBack({
      component: CorteIncorretoLarguraStep as TipoDivergenciaComponent,
      payload: {
        tecidos: payload.tecidos,
      },
    });
  };

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.CORTE,
      "Reação de Tecido Pós Corte"
    ),
    <LabelValueComponent
      label="Tecido"
      value={payload.tecidos.map((item) => item.text).join(", ")}
    />,
    <LabelValueComponent
      label="Quantidade de peças pendentes"
      value={`${payload.largura}`}
    />,
  ];

  const key = TipoDivergenciasKey.REACAO_TECIDO_POS_CORTE;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(step, [
          ...payload.tecidos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.TECIDO,
            value: item.id.toString(),
          })),
          {
            tipo: TipoDivergenciasInfoKey.LARGURA_TECIDO,
            value: payload.largura,
          },
        ]);
      }}
      progressValue={90}
      stepNumber={"4"}
      infoComponents={infoComponents}
    ></FinalStepTemplate>
  );
};

export default ReacaoTecidoPosCorte;
