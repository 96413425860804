import React from "react";
import AreaTipoDivergenciaEnum from "../../../AreaTipoDivergenciaEnum";
import {
  AviamentoProps,
  buildMultiploMaterialInfoComponent,
} from "../../../template/AviamentoQuntidadeStepTemplate";
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from "../../../template/FinalStepTemplate";
import useBuildSubmitHandler from "../../../template/UseBuildSubmitHandler";
import { createBackHandler } from "../../../template/utils";
import TipoDivergenciasInfoKey from "../../../TipoDivergenciasInfoKey.";
import TipoDivergenciasKey from "../../../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../../../TiposDeDivergencias";
import EnvioSemFullKit from "./EnvioSemFullKit";

const EnvioSemFullKitFinalStep: TipoDivergenciaComponent<AviamentoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = createBackHandler(onBack, EnvioSemFullKit);

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.CONTROLE_PCP,
      "Envio Sem Full Kit"
    ),
    ...buildMultiploMaterialInfoComponent(payload),
  ];
  const key = TipoDivergenciasKey.MP_ENVIO_SEM_FULL_KIT;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(
          step,
          payload.aviamentos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.AVIAMENTO,
            value: item.id.toString(),
            adicionalInfo: item.adicionalInfo,
            material: item.material,
          }))
        );
      }}
      progressValue={90}
      stepNumber={"3"}
      infoComponents={infoComponents}
    />
  );
};

export default EnvioSemFullKitFinalStep;
