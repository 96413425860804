import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { ConfiguracoesKeys, ConfiguracoesTipo } from './enums/Configuracoes';

const query = loader('./queries/GetConfiguracaoByKey.gql');
export function useConfiguracaoByKey(key: ConfiguracoesKeys) {
  return useQuery<{
    findOnePLM_Colaborativo_Configuracoes: {
      id_configuracao: number;
      valor: string;
      tipo: ConfiguracoesTipo;
    };
  }>(query, {
    variables: { key },
  });
}
