import { useQuery } from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import SituacaoRow from '../components/SituacaoRow';
import LoadingConfig from '../components/LoadingConfig';
import NewSituacao from '../components/NewSituacao';

const queryGetSituacoes = loader(
  '../queries/GetListSituacoesDivergencia.gql'
);

const headerClass = 'caption bold neutral500 uppercase';

export type Situacao = {
  id_situacao_divergencia: number;
  situacao: string;  
};

const Situacoes: FunctionComponent = () => {
  const {
    loading,
    data = { getListSituacoesDivergencia: [] },
    refetch,
  } = useQuery<{
    getListSituacoesDivergencia: Situacao[];
  }>(queryGetSituacoes);
  
  return (
    <div style={{ width: '50%' }}>
      {loading ? (
        <LoadingConfig></LoadingConfig>
      ) : (
        <div>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow className='tableHeader'>
                <TableCell className={headerClass}>ID</TableCell>
                <TableCell className={headerClass}>Situação</TableCell>
                <TableCell className={headerClass}></TableCell>
                <TableCell className={headerClass}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='backgroundNeutral50'>
              {data.getListSituacoesDivergencia.map(
                (item) => {
                  return (
                    <SituacaoRow
                      key={`idSituacao-${item.id_situacao_divergencia}`}
                      onUpdate={() => {
                        refetch();
                      }}
                      row={item}
                    ></SituacaoRow>
                  );
                }
              )}
               <NewSituacao onUpdate={() => {
                        refetch();
                      }}></NewSituacao>
            </TableBody>
          </Table>
        </div>
      )}
      
    </div>
  );
};

export default Situacoes;