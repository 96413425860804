import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Criacao from '../../pages/product-detail/Criacao';
import Divergencia from '../../pages/product-detail/divergencias/Divergencia';
import ProductInfoWrapper from './components/ProductInfoWrapper';
import './Layout.css';

const RouterView: FunctionComponent = () => {
  let match = useRouteMatch();
  return (
    <Grid item xs={12}>
      <Switch>
        <Route path={`${match.path}/divergencias`} component={Divergencia} />
        <Route exact path={match.path} component={Criacao}>
          <h3>Default</h3>
        </Route>
      </Switch>
    </Grid>
  );
};
const Template: FunctionComponent<{
  aboveHeader?: React.ComponentType<any>;
  header?: React.ComponentType<any>;
  content?: React.ComponentType<any>;
}> = ({ aboveHeader }) => {
  return (
    <div>
      <Box className='backgroundNeutral50' minHeight='100vh'>
        {!!aboveHeader && (
          <Box padding={1} className='backgroundNeutral00'>
            {aboveHeader}
          </Box>
        )}

        <Box paddingX={2} className='backgroundNeutral00'>
          <ProductInfoWrapper></ProductInfoWrapper>
        </Box>

        <Box paddingX={2} marginTop={1}>
          <RouterView></RouterView>
        </Box>
      </Box>
    </div>
  );
};

export default class Layout extends React.Component {
  render() {
    return <Template></Template>;
  }
}
