import React, { FunctionComponent } from 'react';
import { ReactComponent as Attachment } from '../../../../assets/icons/attachment.svg';

const IconFileInput: FunctionComponent<{
  onNewFiles: (newFiles: Array<File>) => void;
}> = ({ onNewFiles }) => (
  <label className='d-flex pointer mlAuto'>
    <Attachment className='iconNeutral800'></Attachment>
    <input
      multiple
      style={{ display: 'none' }}
      type='file'
      onChange={(e) => {
        const files = [].map.call(e.target.files, (file) => file) as Array<
          File
        >;

        onNewFiles(files);
      }}
    />
  </label>
);

export default IconFileInput;
