import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
  buildOutrosAviamentos,
} from '../../../template/UseGetMateriais';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import FaltaDeAviamentoQuantidadeStep from './FaltaDeAviamentoQuantidadeStep';

const FaltaDeAviamento: TipoDivergenciaComponent = ({ onSetStep, onBack }) => {
  const { loading, materiais } = useGetMateriais(
    TipoDivergenciasInfoKey.AVIAMENTO
  );
  const newMateriais = [...materiais, buildOutrosAviamentos()];

  const stepNumber = '2';
  const stepSubTitle = 'Selecione o aviamento em falta.';
  const stepTitle = 'Qual aviamento está faltando?';
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={
        FaltaDeAviamentoQuantidadeStep as TipoDivergenciaComponent
      }
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey='aviamentos'
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};

export default FaltaDeAviamento;
