import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { FunctionComponent, useContext } from 'react';
import { useParams } from "react-router-dom";
import { TamanhoGradeContext, TamanhoGradeContextType } from "../../../../context/TamanhoGradeContext";

const queryTamanhoGrade = loader('../queries/GetTamanhoGrade.gql');

type TamanhoGrade = {
    tam_1: string;
    tam_2: string;
    tam_3: string;
    tam_4: string;
    tam_5: string;
    tam_6: string;
    tam_7: string;
    tam_8: string;
    tam_9: string;
    tam_10: string;
    tam_11: string;
    tam_12: string;
    tam_13: string;
    tam_14: string;
};

const TamanhoGrade: FunctionComponent<{}> = (props) => {

    const params: any = useParams();

    const { data: dataTamanhoGrade, loading } = useQuery<{
        findOneTamanho_Grade: TamanhoGrade;
    }>(queryTamanhoGrade, {
        variables: {
            idProducaoProgProd: Number(params.idProducaoProgProd),
        },
    });

    let tamanhoGrade = {} as TamanhoGrade;

    if (dataTamanhoGrade) {
        tamanhoGrade = dataTamanhoGrade?.findOneTamanho_Grade as TamanhoGrade;
    }

    const gradeValores = Object.values(tamanhoGrade).filter((item) => item !== null).slice(1)

    return (
        <div>
            {loading ? '' :
                gradeValores.map((item, idx) => {
                    return (
                        <InputQuantidade item={item} key={idx}></InputQuantidade>
                    )
                })
            }
        </div>
    )
}

const InputQuantidade: React.FC<{ item: string }> = (props) => {

    const { quantidadeTamanhos, setQuantidadeTamanhos } = useContext<TamanhoGradeContextType>(TamanhoGradeContext)

    const handleChangeInput = (tamanho: string, quantidade: string) => {
        if (quantidade === undefined || quantidade === null || +quantidade === 0) {
            delete quantidadeTamanhos[tamanho]
            setQuantidadeTamanhos({ ...quantidadeTamanhos })
            return
        }

        if (quantidadeTamanhos[tamanho]) {
            quantidadeTamanhos[tamanho] = { quantidade: +quantidade }
            setQuantidadeTamanhos({ ...quantidadeTamanhos, [tamanho]: { quantidade: +quantidade } })
        }

        else {
            setQuantidadeTamanhos({ ...quantidadeTamanhos, [tamanho]: { quantidade: +quantidade } })
        }
    }


    return (
        <div className="d-flex flexColumn d-inline-flex mx8 mb8">
            <label>{props.item}</label>
            <input type="number" inputMode="numeric" pattern="\d*" min="0" className="input-box" onChange={(event) => handleChangeInput(props.item, event.target.value)}></input>
        </div>
    )
}

export default TamanhoGrade;