import React, { FunctionComponent } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'normal',
    minHeight: 56,
  },
  highlighter: {
    border: '2.5px solid transparent',
  },
  input: {
    border: 'none',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    maxWidth: '100%',
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: '0px 16px 24px -8px rgba(0, 0, 0, 0.08)',
      color: '#565656',
      fontSize: '14px',
      fontWeight: '500',
    },
    item: {
      padding: '8px 24px',
      '&focused': {
        color: '#97877a',
      },
    },
  },
};
const defaultMentionStyle = {
  color: '#97877A',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '500',
  pointerEvents: 'none',
  position: 'relative',
  textDecoration: 'underline',
  textShadow:
    ' 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,    -1px -1px 1px white',
  zIndex: '1',
};

const Mentions: FunctionComponent<{
  autoFocus?: boolean;
  data: { id: string; display: string }[];
  disabled?: boolean;
  inputRef?: any;
  setValue: (newValue: string) => void;
  value: string;
}> = ({
  autoFocus = false,
  data,
  disabled = false,
  inputRef,
  setValue,
  value,
}) => {
  const onChange = (_: unknown, newValue: string) => {
    setValue(newValue);
  };

  const ref = React.useRef<HTMLElement | null>();
  React.useEffect(() => {
    ref.current = document.getElementById('novaDivergenciaElement');
  }, [ref]);

  return (
    <MentionsInput
      allowSpaceInQuery
      allowSuggestionsAboveCursor
      autoFocus={autoFocus}
      className='mentionsInput'
      ignoreAccents
      inputRef={inputRef}
      disabled={disabled}
      onChange={onChange}
      placeholder={'Escreva aqui seu comentário...'}
      style={defaultStyle}
      suggestionsPortalHost={ref.current || undefined}
      value={value}
    >
      <Mention
        appendSpaceOnAdd
        data={(search) => {
          return data
            .filter((item) =>
              item.id
                .split('.')
                .join(' ')
                .toLowerCase()
                .includes(search.toLowerCase())
            )
            .slice(0, 10);
        }}
        displayTransform={(id) => `@${id}`}
        style={defaultMentionStyle}
        trigger='@'
      />
    </MentionsInput>
  );
};
export default Mentions;
