import { useQuery } from '@apollo/client';
import { gql, loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import InputTextField from '../components/InputTextField';
import InputUser, { User } from '../components/InputUser';
import LoadingConfig from '../components/LoadingConfig';
import { useLoggedUser } from '../../../support/UseLoggedUser';

const configsText = [
  {
    configKey: 'URL_INDICADOR_DIVERGENCIAS',
    subTitle: 'URL Indicador de Divergencias',
    title: 'URL do Power BI com os indicadores de divergências',
  },
  {
    configKey: 'URL_PLM',
    subTitle: 'Valor inicial: http://plm.somalabs.com.br/',
    title: 'URL do PLM',
  },
  {
    configKey: 'URL_FRONT_PLM_COLABORATIVO',
    subTitle: 'Utilizado para links em e-mails',
    title: 'URL do front end do PLM Colaborativo',
  },
];

const configsUsers = [
  {
    configKey: 'RESPONSAVEL_DEFAULT_CONTROLE_PCP_MARCA_ANIMALE',
    subTitle: 'Usuário pcp responsável pela marca Animale',
    title: 'Usuário responsável Animale',
  },
  {
    configKey: 'RESPONSAVEL_DEFAULT_CONTROLE_PCP_MARCA_FARM',
    subTitle: 'Usuário pcp responsável pela marca Farm',
    title: 'Usuário pcp responsável Farm',
  },
  {
    configKey: 'RESPONSAVEL_DEFAULT_CONTROLE_PCP',
    subTitle: 'Usuário pcp responsável caso marca não seja Animale nem Farm',
    title: 'Usuário pcp responsável default',
  },
  {
    configKey: 'RESPONSAVEL_AREA_CORTE',
    subTitle: 'Usuário responsável pelas divergências da área de corte',
    title: 'Usuário responsável corte',
  },
  {
    configKey: 'RESPONSAVEL_CONTROLE_PCP_CORTE_EXTERNO',
    subTitle: 'Usuário responsável pelas divergências da área de corte externo',
    title: 'Usuário responsável corte externo',
  },
  {
    configKey: 'RESPONSAVEL_AREA_QUALIDADE_MP',
    subTitle:
      'Usuário responsável pelas divergências da área de qualidade de matéria-prima',
    title: 'Usuário responsável MP',
  },
  {
    configKey: 'RESPONSAVEL_DEFAULT_MODELAGEM_FFF',
    subTitle:
      'Usuário default responsável pelas divergências da área de modelagem FFF',
    title: 'Usuário responsável modelagem FFF',
  },
  {
    configKey: 'RESPONSAVEL_DEFAULT_MODELAGEM_AAFM',
    subTitle:
      'Usuário default responsável pelas divergências da área de modelagem que não seja FFF',
    title: 'Usuário responsável modelagem AAFM',
  },

];

const queryUsers = loader('../../../support/queries/GetUserForMentions.gql');
const queryConfig = gql`
  {
    findManyPLM_Colaborativo_Configuracoes2 {
      id_configuracao
      key
      valor
    }
  }
`;
type Config = {
  id_configuracao: number;
  key: string;
  valor: string;
  tipo: string;
};

function findConfig(
  dataConfig: { findManyPLM_Colaborativo_Configuracoes2: Config[] },
  key: string
) {
  return dataConfig.findManyPLM_Colaborativo_Configuracoes2.find(
    (item) => item.key === key
  );
}

const Geral: FunctionComponent = () => {
  const {
    loading: loadingUsers,
    data: dataUsers = { findManyUsuario: [] },
  } = useQuery<{
    findManyUsuario: User[];
  }>(queryUsers);

  const {
    loading: loadingConfig,
    data: dataConfig = { findManyPLM_Colaborativo_Configuracoes2: [] },
  } = useQuery<{
    findManyPLM_Colaborativo_Configuracoes2: Config[];
  }>(queryConfig);

  const findInicialTextValue = (key: string) => {
    const config = findConfig(dataConfig, key);
    return config?.valor || '';
  };

  const findInicialNumberValue = (key: string) => {
    const config = findConfig(dataConfig, key);
    return Number(config?.valor) || 0;
  };

  /*  const findInicialBooleanValue = (key: string) => {
     const config = findConfig(dataConfig, key);
     return config?.valor === 'true' || false;
   }; */

  const isLoading = loadingUsers || loadingConfig;

  const { data: getLoggedUser } = useLoggedUser();


  return isLoading ? (
    <LoadingConfig width='80%'></LoadingConfig>
  ) : (
    <div>
      {configsText.map((config) => {
        return getLoggedUser?.getLoggedUser.super_user ? (
          <div key={`config${config.configKey}`} className='mt16'>
            <InputTextField
              configKey={config.configKey}
              initialValue={findInicialTextValue(config.configKey)}
              subTitle={config.subTitle}
              title={config.title}
            ></InputTextField>
          </div>
        ) : <div></div>;
      })}
      {configsUsers.map((config) => {
        //console.log(config)
        return (
          <div key={`config${config.configKey}`} className='mt16'>
            <InputUser
              configKey={config.configKey}
              initialValue={findInicialNumberValue(config.configKey)}
              subTitle={config.subTitle}
              title={config.title}
              users={dataUsers.findManyUsuario}
            ></InputUser>
          </div>
        );
      })}

    </div>
  );
};

export default Geral;
