import React, { FunctionComponent } from 'react';
import StepperHeader from '../components/StepperHeader';

const FinalStepHeader: FunctionComponent<{ stepNumber: string }> = ({
  stepNumber,
}) => {
  const stepSubTitle = 'Confira abaixo os detalhes da divergência encontrada.';
  const stepTitle = 'Finalize o registro de divergência encontrado.';
  return (
    <StepperHeader
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    ></StepperHeader>
  );
};

export default FinalStepHeader;
