import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";
import firebaseConfig from "./config/firebase/firebaseConfig";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const messaging = firebase.messaging();

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });
