import React, { FunctionComponent } from "react";
import LabelValueBase from "./LabelValueBase";

const LabelValueWithSubtitle: FunctionComponent<{
  label: string;
  value: string;
  subtitle: string;
}> = ({ label, value, subtitle }) => (
  <LabelValueBase label={label}>
    <div>
      <div
        className="body2 semi-bold neutral700"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        title={value}
      >
        {value}
      </div>
      <div className="overline medium neutral500 ">{subtitle}</div>
    </div>
  </LabelValueBase>
);

export default LabelValueWithSubtitle;
