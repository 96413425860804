import { useMutation, useQuery, gql } from '@apollo/client';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState, useContext } from 'react';
import DivergenciaStatusEnum from '../../../../../support/enums/DivergenciaStatusEnum';
import LabelValueBase from '../../nova-divergencia/components/LabelValueBase';
import { DivergenciaType } from '../DivergenciaDetailTypes';
import { UserContext, UserContextType } from '../../../../../context';
import { isSupplier } from "../../../../../tools";
import { ConfiguracoesKeys } from '../../../../../support/enums/Configuracoes';
import { useConfiguracaoByKey } from '../../../../../support/UseConfiguracao';

const AreaRespSecundariaQuery = loader(
    '../../queries/getAllAreaRespSecundaria.gql'
);

const makeUpdateAreaRespSecundariaGQL = () => {
    return gql`
    mutation updateAreaRespSecundariaDivergencia(
        $idDivergencia: Int!
        $novaAreaRespSecundariaDivergenciaId: Int!
      ) {
        updateAreaRespSecundariaPLM_Colaborativo_Divergencias(
          idDivergencia: $idDivergencia
          novaAreaRespSecundariaDivergenciaId: $novaAreaRespSecundariaDivergenciaId
        ) {
          id_divergencia
        }
      }
      
    `;
};



const AreaRespSecundaria: FunctionComponent<{
    divergencia: DivergenciaType;
    onStatusChange: Function;
}> = ({ divergencia, onStatusChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { userLogged } = useContext<UserContextType>(UserContext);
    const disabledForm = isSupplier(userLogged?.id_grupo || null);
    const { data } = useConfiguracaoByKey(ConfiguracoesKeys.RESPONSAVEL_AREA_SECUNDARIA);


    const checkUserSecondaryResponsibility = (idUsuario?: number): boolean => {
        let usersSecondaryRespArea: number[] = [];

        if (data && data.findOnePLM_Colaborativo_Configuracoes) {
            usersSecondaryRespArea = JSON.parse(
                data.findOnePLM_Colaborativo_Configuracoes.valor
            ) as number[];
        }
        if (idUsuario) {
            return idUsuario ? usersSecondaryRespArea.includes(idUsuario) : false;
        }
        return false;
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [save, { loading: loadingSave }] = useMutation(
        makeUpdateAreaRespSecundariaGQL()
    );

    const [loadingId, setLoadingId] = useState(0);

    const changeAreaRespSecundaria = (novaAreaRespSecundariaDivergenciaId: number) => {
        if (loadingSave) return;
        setLoadingId(novaAreaRespSecundariaDivergenciaId);
        save({
            variables: {
                idDivergencia: divergencia.id_divergencia,
                novaAreaRespSecundariaDivergenciaId: novaAreaRespSecundariaDivergenciaId,
            },
        }).then(() => {
            handleClose();
            onStatusChange();
            setLoadingId(0);
        });
    };

    const { loading: loadingAreaRespSecundaria, data: dataAreaRespSecundaria } = useQuery<{
        getAll_PLM_Colaborativo_Area_Resp_Secundaria: {
            id_area: number;
            area: string;
        }[];
    }>(AreaRespSecundariaQuery);

    const statusFinalizado = divergencia.status === DivergenciaStatusEnum.FINALIZADO;

    return (
        <> {!disabledForm && checkUserSecondaryResponsibility(userLogged?.idUsuario) &&
            <>
                <LabelValueBase label='Área Responsável Secundária'>
                    <div>
                        <span className='mt8 body2 semi-bold neutral700'>
                            {divergencia?.AreaRespSecundaria != null ? divergencia?.AreaRespSecundaria?.area : 'Não definida'}
                        </span>

                        {!loadingAreaRespSecundaria && !statusFinalizado && (
                            <Button onClick={handleClick}>
                                <span className='overline bold neutral400'>ALTERAR</span>
                            </Button>
                        )}
                        <Menu
                            anchorEl={anchorEl}
                            id='AreaRespSecundaria-menu'
                            keepMounted
                            onClose={handleClose}
                            open={Boolean(anchorEl)}
                        >
                            {dataAreaRespSecundaria?.getAll_PLM_Colaborativo_Area_Resp_Secundaria.map((AreaRespSecundaria) => {
                                const AreaRespSecundariaFinalizado = AreaRespSecundaria.area === "Finalizado Automaticamente";

                                return (
                                    <MenuItem
                                        style={AreaRespSecundariaFinalizado ? { display: 'none' } : { display: 'block' }}
                                        disabled={
                                            divergencia.AreaRespSecundaria?.id_area === AreaRespSecundaria.id_area
                                        }
                                        onClick={() => {
                                            changeAreaRespSecundaria(
                                                AreaRespSecundaria.id_area
                                            );
                                        }}
                                        key={`AreaRespSecundaria${AreaRespSecundaria.id_area}`}
                                    >
                                        {loadingSave &&
                                            loadingId === AreaRespSecundaria.id_area ?
                                            (
                                                <div style={{ textAlign: 'center', width: '100%' }}>
                                                    <CircularProgress
                                                        color='inherit'
                                                        size={18}
                                                    ></CircularProgress>
                                                </div>
                                            ) : (
                                                <span>
                                                    {AreaRespSecundaria.area}
                                                </span>
                                            )
                                        }
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </div>
                </LabelValueBase>
            </>
        }
        </>
    );
};

export default AreaRespSecundaria;
