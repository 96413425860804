import { gql } from '@apollo/client'

export const GET_PRODUCAO_PPOP = gql`
query getProdutoPPOP($idProducaoPppOp: Float!) {
  findOneProducao_PPP_OP(where: { id_producao_ppp_op: $idProducaoPppOp }) {
     Ordem_Producao {
      ordem_producao
      is_pa
    }
    Producao_Prog_Prod {
      Produto_Cor {
        Produto {
          digitalizacao_interna
          dev_interno
          corte_interno
          desenvolvimento
        }
      }
    }
  }
}
`