import { useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  ThemeProvider,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { loaderTheme } from '../../../support/Utils';
import { UsuarioEspelho } from '../tabs/DivergenciaEspelho';

const deleteUsuarioEspelhoMutation = loader(
  '../queries/deleteUsuarioEspelho.gql'
);

const DivergenciaEspelhoRow: FunctionComponent<{
  row: UsuarioEspelho;
  onDelete: Function;
}> = ({ row, onDelete }) => {
  const [deleteLiderLiderado, { loading }] = useMutation(
    deleteUsuarioEspelhoMutation
  );

  return (
    <TableRow className='tableRowHover pointer'>
      <TableCell width='40%'>{row.UsuarioOriginal.login}</TableCell>
      <TableCell width='40%'>{row.UsuarioCopia.login}</TableCell>
      <TableCell width='10%'>
        <Button
          variant='contained'
          className='backgroundPrimary500'
          onClick={() => {
            deleteLiderLiderado({
              variables: {
                idUsuarioEspelho: row.id_usuario_divergencia_espelho,
              },
            }).then(() => {
              onDelete();
            });
          }}
          fullWidth
        >
          {loading ? (
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress size={25} color='primary'></CircularProgress>
            </ThemeProvider>
          ) : (
            <span className='text-transform-initial body2 bold primary100'>
              Excluir
            </span>
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default DivergenciaEspelhoRow;
