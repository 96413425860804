import React, { FunctionComponent } from 'react';
import { BufferPenetrationColorHex } from '../../enums/BufferPenetrationColorHex';

const BufferPenetrationColor: FunctionComponent<{
  color: string;
}> = ({ color }) => (
  <div style={{ position: 'relative', width: '10px' }}>
    <div
      style={{
        width: '8px',
        height: '8px',
        borderRadius: '4px',
        background:
          BufferPenetrationColorHex[
            color as keyof typeof BufferPenetrationColorHex
          ],
        position: 'absolute',
        top: '0',
        right: '0',
      }}
    ></div>
  </div>
);

export default BufferPenetrationColor;
