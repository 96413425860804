import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MenuType } from './Menus';
import TopbarMenuButton from './TopbarMenuButton';

const TopbarMenuSingle: FunctionComponent<
  RouteComponentProps & { menu: MenuType; urlPlm: string }
> = ({ menu, history, urlPlm }) => (
  <TopbarMenuButton
    label={menu.label}
    showIcon={false}
    handleClick={() => {
      if (menu.isOldPlm) {
        window.location.assign(`${urlPlm}/#/${menu.path}`);
        return;
      }

      history.push(menu.path || '');
      return;
    }}
  ></TopbarMenuButton>
);

export default withRouter(TopbarMenuSingle);
