import { Avatar, Button, Grid } from '@material-ui/core';
import ColorHash from 'color-hash';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import {
  buildUserName,
  momentFormatShortDayWithHour,
} from '../../../../../support/Utils';
import { Mensagem } from '../DivergenciaDetailTypes';
import MensagemValue from './MensagemValue';

const colorHash = new ColorHash();

const MensagemComponent: FunctionComponent<{ mensagem: Mensagem }> = ({
  mensagem,
}) => {
  const avatarColor = colorHash.hex(mensagem.EnviadoPor.login);
  return (
    <Grid item xs={12} key={`mensagem-${mensagem.id_mensagem}`}>
      <div className='d-flex'>
        <Avatar style={{ backgroundColor: avatarColor }}>
          {mensagem.EnviadoPor.login.substr(0, 1).toUpperCase()}
        </Avatar>
        <div className='ml16' style={{ width: '100%' }}>
          <div className='mt8'>
            <span className='body2 semi-bold neutral700 '>
              {buildUserName(mensagem.EnviadoPor.login)}
            </span>
            <span className='ml16 overline bold neutral400'>
              {moment(mensagem.created_at).format(momentFormatShortDayWithHour)}
            </span>
          </div>
          <div className='mt8 body2 medium neutral700'>
            <MensagemValue mensagem={mensagem.mensagem}></MensagemValue>
          </div>
          {mensagem.Arquivos.length > 0 && (
            <div style={{ width: '100%' }}>
              <div className='caption medium neutral500'>Arquivos anexados</div>
              {mensagem.Arquivos.map((arquivo, index) => {
                return (
                  <div
                    key={`arquivo${index}`}
                    className='d-flex align-items-center'
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    <span className='body2 medium neutral700'>
                      {arquivo.nome_original}
                    </span>
                    <Button
                      className='overline bold button primary500'
                      href={arquivo.url}
                      target='_blank'
                    >
                      Download
                    </Button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default MensagemComponent;
