import AreaTipoDivergenciaEnum from '../../AreaTipoDivergenciaEnum';
import { useBuildFinalStepTemplate } from '../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../template/UseBuildSubmitHandler';
import TipoDivergenciasKey from '../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../TiposDeDivergencias';


const BolsoSemProcesso: TipoDivergenciaComponent = ({
  onBack,
  onSetStep,
}) => {


  const key = TipoDivergenciasKey.BOLSO_SEM_PROCESSO;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);


  return useBuildFinalStepTemplate({

    area: AreaTipoDivergenciaEnum.PRE_COSTURA,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: 'Itens que necessitam de bolso sem o processo realizado, ou não conforme.',
  });

};

export default BolsoSemProcesso;
