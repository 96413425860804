import React from 'react';
import TecidoQuantidadeStepTemplate, {
  TecidoProps,
} from '../../../template/TecidoQuantidadeStepTemplate';
import { createBackHandler } from '../../../template/utils';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import FaltaDeTecidoInterno from './FaltaDeTecidoInterno';
import FaltaDeTecidoFinalStep from './FaltaDeTecidoFinalStep';

const FaltaDeTecidoQuantidadeStep: TipoDivergenciaComponent<TecidoProps> = ({
  onSetStep,
  onBack,
  payload,
  onClose,
}) => {
  const backComponent = FaltaDeTecidoInterno;
  const nextComponent = FaltaDeTecidoFinalStep;

  const onBackHandler = createBackHandler(onBack, backComponent);
  return (
    <TecidoQuantidadeStepTemplate
      nextStepComponent={nextComponent as TipoDivergenciaComponent}
      onClose={onClose}
      onSetStep={onSetStep}
      onBack={onBackHandler}
      payload={payload}
    ></TecidoQuantidadeStepTemplate>
  );
};

export default FaltaDeTecidoQuantidadeStep;
