import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import {
  FilterComponent,
  FilterComponentOption,
} from '../../../support/components/inputs/FilterComponent';
import {
  buildUserName,
} from '../../../support/Utils';
import StatusDivergenciaEnum from '../../../support/enums/DivergenciaStatusEnum';
import { StatusEnum } from '../../product-detail/divergencias/components/DivergenciasTable';
const fornecedorQuery = loader('../queries/GetFornecedorSelectQuery.gql');
const responsavelQuery = loader('../queries/ResponsavelDivergenciaQuery.gql');
const tiposDivergenciasQuery = loader('../queries/GetTiposDivergencias.gql');
const situacoesDivergenciasQuery = loader('../queries/GetSituacoesDivergencias.gql');
const canaisQuery = loader('../queries/CanaisQuery.gql');


const todosOption: FilterComponentOption = {
  title: 'Todos',
  value: '',
};

const statusOptions: FilterComponentOption[] = [
  todosOption,
  {
    title: StatusEnum.NOVO,
    value: StatusDivergenciaEnum.NOVO,
  },
  {
    title: StatusEnum.EM_ANDAMENTO,
    value: StatusDivergenciaEnum.EM_ANDAMENTO,
  },
  {
    title: StatusEnum.AGUARDANDO_FINALIZACAO,
    value: StatusDivergenciaEnum.AGUARDANDO_FINALIZACAO,
  },
  {
    title: StatusEnum.FINALIZADO,
    value: StatusDivergenciaEnum.FINALIZADO,
  },
];

const colorsOptions: FilterComponentOption[] = [
  todosOption,
  { title: 'Black', value: 'Black' },
  { title: 'Cyan', value: 'Cyan' },
  { title: 'Green', value: 'Green' },
  { title: 'Red', value: 'Red' },
  { title: 'Yellow', value: 'Yellow' },
];


function buildFornecedorOptions(
  data: { nome: string; id_fornecedor: string; fornecedor: string }[]
) {
  const fornecedorOptions = [
    {
      title: 'Todos',
      value: '',
    },
  ];
  fornecedorOptions.push(
    ...data.map(
      (item: { nome: string; id_fornecedor: string; fornecedor: string }) => ({
        title: item.fornecedor?.trim() || item.nome?.trim(),
        value: item.id_fornecedor.toString(),
      })
    )
  );
  return fornecedorOptions;
}



const DivergenciaFilters: FunctionComponent = () => {

  //fornecedores
  const {
    data: fornecedorData = { findManyFornecedor: [] },
    loading: fornecedorLoading,
  } = useQuery(fornecedorQuery, {});

  const fornecedorOptions = buildFornecedorOptions(
    fornecedorData.findManyFornecedor
  );

  //responsaveis
  const {
    data: responsavelData = {
      findManyUsuario: [],
    },
    loading: responsavelLoading,
  } = useQuery(responsavelQuery);
  const responsavelOptions = [todosOption];
  responsavelOptions.push(
    ...responsavelData.findManyUsuario.map(
      (item: { id_usuario: number; login: string }) => ({
        title: buildUserName(item.login),
        value: item.id_usuario.toString(),
      })
    )
  );

  //tipos
  const {
    data: tiposData = {
      findManyPLM_Colaborativo_Tipos_Divergencias: [],
    },
    loading: tiposLoading,
  } = useQuery(tiposDivergenciasQuery);
  const tiposOptions = [todosOption];
  tiposOptions.push(
    ...tiposData.findManyPLM_Colaborativo_Tipos_Divergencias.map(
      (item: { id_tipo_divergencia: number; tipo: string }) => ({
        title: item.tipo,
        value: item.id_tipo_divergencia.toString(),
      })
    )
  );

  //situacoes
  const {
    data: situacoesData = {
      getListSituacoesDivergencia: [],
    },
    loading: situacoesLoading,
  } = useQuery(situacoesDivergenciasQuery);
  const situacoesOptions = [todosOption];
  situacoesOptions.push(
    ...situacoesData.getListSituacoesDivergencia.map(
      (item: { id_situacao_divergencia: number; situacao: string }) => ({
        title: item.situacao,
        value: item.id_situacao_divergencia.toString(),
      })
    )
  );

  //canais
  const {
    data: canaisData = {
      findManyProgramacao: [],
    },
    loading: canaisLoading,
  } = useQuery(canaisQuery);
  const canaisOptions = [todosOption];
  canaisOptions.push(
    ...canaisData.findManyProgramacao.map(
      (item: { tipo_programacao: string }) => ({
        title: item.tipo_programacao,
        value: item.tipo_programacao,
      })
    )
  );


  return (
    <div className='d-flex'>
      <FilterComponent
        pushPathname='/divergencias'
        boxWidth={160}
        label='Status'
        options={statusOptions}
        queryKey='status'
        defaultValue={todosOption}
      />
      <FilterComponent
        pushPathname='/divergencias'
        boxWidth={160}
        label='Cor'
        options={colorsOptions}
        queryKey='cor'
        defaultValue={todosOption}
      />
      <FilterComponent
        boxWidth={250}
        defaultValue={todosOption}
        label='Fornecedor'
        loading={fornecedorLoading}
        options={fornecedorOptions}
        pushPathname='/divergencias'
        queryKey='fornecedor'
      />
      <FilterComponent
        boxWidth={210}
        defaultValue={todosOption}
        label='Responsável'
        loading={responsavelLoading}
        options={responsavelOptions}
        pushPathname='/divergencias'
        queryKey='responsavelId'
      />
      <FilterComponent
        boxWidth={180}
        defaultValue={todosOption}
        label='Canal'
        loading={canaisLoading}
        options={canaisOptions}
        pushPathname='/divergencias'
        queryKey='canal'
      />
      <FilterComponent
        boxWidth={210}
        defaultValue={todosOption}
        label='Descrição'
        loading={tiposLoading}
        options={tiposOptions}
        pushPathname='/divergencias'
        queryKey='idTipoDivergencia'
      />
      <FilterComponent
        boxWidth={210}
        defaultValue={todosOption}
        label='Situação'
        loading={situacoesLoading}
        options={situacoesOptions}
        pushPathname='/divergencias'
        queryKey='idSituacaoDivergencia'
      />

    </div>
  );
};


export default DivergenciaFilters;
