import { Grid, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryString } from '../support/Utils';

export const SearchComponent: FunctionComponent = () => {
  let queryString = useQueryString();
  const history = useHistory();
  const [value, setValue] = React.useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryString.set('colecao', 'todas');
    queryString.set('page', '1');
    queryString.set('search', value.toString());
    
    history.push({
      pathname: '/produtos',
      search: queryString.toString(),
    });
    setValue('');
    document.getElementById('sarchField')?.blur();
  };
  return (
    <Grid item xs={3}>
      <form noValidate autoComplete='off' onSubmit={handleSubmit}>
        <TextField
          fullWidth
          id='sarchField'
          placeholder='Produto, OP ou descrição este aqui'
          variant='outlined'
          value={value}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' disableTypography={true}>
                <SearchIcon className='primary500'></SearchIcon>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Grid>
  );
};
