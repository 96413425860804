import React, { FunctionComponent, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,

} from 'react-router-dom';
import Configuracoes from './pages/configuracoes/Configuracoes';
import DivergenciaIndicadores from './pages/divergencia-list/DivergenciaIndicadores';
import DivergenciaList from './pages/divergencia-list/DivergenciaList';
import Home from './pages/Home';
import { ProductList } from './pages/product-list/ProductList';
import TopbarWrapper from './support/components/Topbar/TopbarWrapper';
import ProductLayout from './support/template/ProductLayout';
import useReceiveAuth from './support/UseReceiveAuth';

const ExternalRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://plm.somalabs.com.br/';
  }, []);
  return null;
};

const PrivateRoute: FunctionComponent = ({ children, ...rest }) => {
  useReceiveAuth();
  const hasAuth = localStorage.getItem('auth');
  if (!hasAuth) {
    const pathname = window.location.pathname;
    localStorage.setItem(
      'redirectBack',
      `${pathname}${window.location.search}`
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!hasAuth ? (
          children
        ) : (
          < ExternalRedirect />
        )
      }
    />
  );
};

export default () => (
  <Router>
    <Switch>
      <Route exact path='/login' component={ExternalRedirect} />
      <Route exact path='/'>
        <Home></Home>
      </Route>
      <Route path='/'>
        <PrivateRoute>
          <TopbarWrapper></TopbarWrapper>

          <Switch>
            <Route exact path='/produtos' component={ProductList} />
            <Route path='/configuracoes' component={Configuracoes} />
            <Route exact path='/divergencias' component={DivergenciaList} />
            <Route
              exact
              path='/divergencias/indicadores'
              component={DivergenciaIndicadores}
            />
            <Route
              path='/produtos/:idProducaoProgProd/:idProducaoPppOp'
              component={ProductLayout}
            />
          </Switch>
        </PrivateRoute>
      </Route>
    </Switch>
  </Router>
);
