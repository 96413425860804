import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export const BackToProductList: FunctionComponent<{ hideArrow?: boolean }> = ({
  hideArrow = false,
}) => (
  <Link
    className='font-roboto neutral500 d-inline-flex align-item-center ml8 mt16 mb16'
    to='/produtos'
  >
    {!hideArrow && <ArrowLeftIcon></ArrowLeftIcon>}
    <span>Voltar para a listagem de produtos</span>
  </Link>
);
