import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
  buildOutrosAviamentos,
} from '../../../template/UseGetMateriais';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import ComplementoDeGradeAviamentoQuantidadeStep from './ComplementoDeGradeAviamentoQuantidadeStep';

const ComplementoDeGradeAviamento: TipoDivergenciaComponent = ({
  onSetStep,
  onBack,
}) => {
  const { loading, materiais } = useGetMateriais(
    TipoDivergenciasInfoKey.AVIAMENTO
  );
  const newMateriais = [...materiais, buildOutrosAviamentos()];

  const stepNumber = '2';
  const stepSubTitle =
    'Selecione o aviamento que precisa de complemento de grade.';
  const stepTitle = 'Qual aviamento precisa de complemento de grade?';
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={
        ComplementoDeGradeAviamentoQuantidadeStep as TipoDivergenciaComponent
      }
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey='aviamentos'
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};

export default ComplementoDeGradeAviamento;
