import {gql, useMutation} from "@apollo/client";
import React, {FunctionComponent, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, styled, Typography} from "@material-ui/core";

import { toast } from 'react-toastify';

interface TicketItemProps {
  title: string;
  isZendesk: string;
  divergenceId: string;
}

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#97877A',
  },
}));

const UPDATE_DIVERGENCE_TYPE_IS_ZENDESK = gql`
  mutation updateDivergenceTypeIsZendesk($divergenceId: Int!, $isZendesk: Boolean!) {
    updateDivergenceTypeIsZendesk(divergenceId: $divergenceId, isZendesk: $isZendesk) {
      id_tipo_divergencia
      area
      tipo
      is_zendesk
    }
  }
`;

export const TicketItem: FunctionComponent<TicketItemProps> = ({ divergenceId, title, isZendesk  }) => {
  const [updateDivergenceTypeIsZendesk] = useMutation(UPDATE_DIVERGENCE_TYPE_IS_ZENDESK);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isCheckedPLM, setIsCheckedPLM] = useState<boolean>(!JSON.parse(isZendesk));
  const [isCheckedZendesk, setIsCheckedZendesk] = useState<boolean>(JSON.parse(isZendesk));

  const handleCheckboxChange = (isPLM: boolean) => {
    setIsCheckedPLM(isPLM);
    setIsCheckedZendesk(!isPLM);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateDivergenceTypeIsZendesk({
        variables: {
          divergenceId: parseInt(divergenceId) || 0,
          isZendesk: isCheckedZendesk,
        },
      });
      setLoading(false);
      toast.success("Tipo divergencia atualizado com sucesso !")
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao gravar envio de chamado divergencia ')
    }
  };

  return (
      <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mt={4}
      >
        <Typography>
          {title}
        </Typography>
        <Box display="flex" alignItems="center" width={'30%'}>
          <FormControlLabel
              control={<CustomCheckbox name="plm" checked={isCheckedPLM} onChange={() => handleCheckboxChange(true)} />}
              label="PLM"
          />
          <FormControlLabel
              control={<CustomCheckbox name="zendesk" checked={isCheckedZendesk} onChange={() => handleCheckboxChange(false)} />}
              label="Zendesk"
          />
          <Button variant="contained" color="primary" onClick={handleSave} style={{ backgroundColor: '#97877A' }}>
            Salvar
          </Button>
        </Box>
      </Box>
  )
};
