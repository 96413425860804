import { UserWithPermission } from './Topbar';

type hasPermissionFunctionType = (
  userWithPermission: UserWithPermission
) => boolean;

export type SubMenuType = {
  isOldPlm: boolean;
  label: string;
  path: string;
  permissoes: Array<string | hasPermissionFunctionType>;
};

export type MenuType = {
  isOldPlm?: boolean;
  label: string;
  path?: string;
  submenus: SubMenuType[];
};

const menus: MenuType[] = [
  {
    label: 'Estilo',
    submenus: [
      { label: 'OTB', permissoes: ['Otb'], isOldPlm: true, path: 'otb' },
      {
        label: 'Quadradinhos',
        permissoes: ['Quadradinhos'],
        isOldPlm: true,
        path: 'quadradinhos',
      },
      {
        label: 'Estampas',
        permissoes: ['Estampas'],
        isOldPlm: true,
        path: 'estampas',
      },
      {
        label: 'Painel de Produto',
        permissoes: ['Quadradinhos'],
        isOldPlm: true,
        path: 'painel_produto',
      },
    ],
  },

  {
    label: 'Criação',
    submenus: [
      {
        label: 'Pedidos de Pilotagem',
        permissoes: ['Pilotagem - Pedido'],
        isOldPlm: true,
        path: 'pedido_pilotagem',
      },
      {
        label: 'Compra de Pilotagem',
        permissoes: ['Pilotagem - Compra'],
        isOldPlm: true,
        path: 'compra_pilotagem',
      },
      {
        label: 'Estoque de Pilotagem',
        permissoes: ['Pilotagem - Estoque'],
        isOldPlm: true,
        path: 'estoque_pilotagem',
      },
      {
        label: 'Consumo de Pilotagem',
        permissoes: ['Pilotagem - Almoxarifado'],
        isOldPlm: true,
        path: 'almox_pilotagem',
      },
      {
        label: 'Modelagens',
        permissoes: ['Modelagens'],
        isOldPlm: true,
        path: 'modelagens',
      },
    ],
  },
  {
    label: 'Produção',
    submenus: [
      {
        label: '1º Gasto',
        permissoes: ['Simulados'],
        isOldPlm: true,
        path: 'simulados',
      },
      {
        label: 'Ampliação',
        permissoes: ['Ampliação'],
        isOldPlm: true,
        path: 'ampliacao',
      },
      {
        label: 'Ficha Técnica',
        permissoes: ['Ficha Técnica'],
        isOldPlm: true,
        path: 'ft',
      },
      {
        label: 'Alocação',
        permissoes: ['Alocação'],
        isOldPlm: true,
        path: 'alocacao',
      },
      {
        label: 'Contra Amostras',
        permissoes: ['Contra Amostras'],
        isOldPlm: true,
        path: 'contra_amostras',
      },
      {
        label: 'Amostras Tecido',
        permissoes: ['Amostras'],
        isOldPlm: true,
        path: 'amostras',
      },
      {
        label: 'Encaixes',
        permissoes: ['Encaixes'],
        isOldPlm: true,
        path: 'Encaixes',
      },
      { label: 'S&OE', permissoes: ['SEOE'], isOldPlm: true, path: 'seoe' },
      {
        label: 'Controle Produção',
        permissoes: ['FollowUp'],
        isOldPlm: true,
        path: 'follow_up',
      },
      {
        label: 'Ranking de Forcenedores',
        permissoes: ['FollowUp'],
        isOldPlm: true,
        path: 'ranking_fornecedores',
      },
      {
        label: 'Compliance',
        permissoes: [
          (userWithPermission: UserWithPermission) =>
            userWithPermission.isAdmin || userWithPermission.id_grupo === 32,
        ],
        isOldPlm: true,
        path: 'compliance',
      },
    ],
  },
  {
    label: 'Divergências',
    isOldPlm: false,
    path: '/divergencias',
    submenus: [],
  },
  {
    label: 'Caça-Costura',
    submenus: [
      {
        label: 'Caça-Costura',
        permissoes: [
          (userWithPermission: UserWithPermission) =>
            userWithPermission.isAdmin ||
            userWithPermission.id_grupo === 8 ||
            userWithPermission.grupoAreas.includes('caca_costura'),
        ],
        isOldPlm: true,
        path: 'caca-costura',
      },
      {
        label: 'Admin Caça-Costura',
        permissoes: [
          (userWithPermission: UserWithPermission) =>
            userWithPermission.isAdmin || userWithPermission.id_grupo === 8,
        ],
        isOldPlm: true,
        path: 'admin-leilao',
      },
    ],
  },
  {
    label: 'Configurações',
    submenus: [
      {
        label: 'Estilo',
        permissoes: ['Quadradinhos', 'Configurações'],
        isOldPlm: true,
        path: 'configuracoes/estilo',
      },
      {
        label: 'Geral',
        permissoes: [
          (userWithPermission: UserWithPermission) =>
            !!userWithPermission.isSuperUser &&
            userWithPermission.grupoAreas.includes('Configurações'),
        ],
        isOldPlm: true,
        path: 'configuracoes/geral',
      },
      {
        label: 'Modelagens',
        permissoes: ['Modelagens', 'Configurações'],
        isOldPlm: true,
        path: 'configuracoes/modelagens',
      },
      {
        label: 'Encaixes E FT',
        permissoes: ['Encaixes', 'Configurações'],
        isOldPlm: true,
        path: 'configuracoes/encaixes',
      },
      {
        label: 'PCP',
        permissoes: ['Alocação', 'Configurações'],
        isOldPlm: true,
        path: 'configuracoes/pcp',
      },
    ],
  },
];

export default menus;
