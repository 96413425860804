import { useQuery } from '@apollo/client';
import Skeleton from '@material-ui/lab/Skeleton';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import LabelValueBase from '../../nova-divergencia/components/LabelValueBase';
import LabelValueWithSubtitle from '../../nova-divergencia/components/LabelValueWithSubtitle';
import TipoDivergenciasInfoKey, {
  TipoDivergenciaTranslate,
} from '../../nova-divergencia/TipoDivergenciasInfoKey.';
import { DivergenciaInfo, Material } from '../DivergenciaDetailTypes';

const query = loader('../../queries/GetMaterial.gql');

const tiposMateriais = [
  TipoDivergenciasInfoKey.AVIAMENTO,
  TipoDivergenciasInfoKey.TECIDO,
];

const InfoComponent: FunctionComponent<{ info: DivergenciaInfo }> = ({
  info,
}) => {
  const isMaterial = tiposMateriais.includes(
    info.tipo as TipoDivergenciasInfoKey
  );
  const { loading, data } = useQuery<{
    findOneMaterial: Material;
  }>(query, {
    skip: !isMaterial,
    variables: {
      idMaterial: Number(info.value),
    },
  });

  const label =
    TipoDivergenciaTranslate[
      info.tipo as keyof typeof TipoDivergenciaTranslate
    ];

  const material = data?.findOneMaterial;
  const descMaterial = material?.desc_material_limpo;
  const materialNumber = material?.id_linx_mp;
  const value = descMaterial || info.value;
  return loading ? (
    <LabelValueBase label={label}>
      <Skeleton variant='text' width={125} height={24} />
    </LabelValueBase>
  ) : (
    <LabelValueWithSubtitle
      label={label}
      value={value}
      subtitle={materialNumber || ''}
    ></LabelValueWithSubtitle>
  );
};

export default InfoComponent;
