// FeedbackSnackbar.tsx

import React from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export type FeedbackType = {
  show: boolean;
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
};

type FeedbackSnackbarProps = {
  feedback: FeedbackType;
  onClose: () => void;
};

const FeedbackSnackbar: React.FC<FeedbackSnackbarProps> = ({ feedback, onClose }) => (
  <Snackbar
    open={feedback.show}
    autoHideDuration={6000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert onClose={onClose} severity={feedback.type}>
      {feedback.message}
    </Alert>
  </Snackbar>
);

export default FeedbackSnackbar;
