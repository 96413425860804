import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@material-ui/core';
import React, { FunctionComponent, useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import MateriaisPopover from '../../detail/components/MateriaisPopover';
import ContinueOrBackActions from '../components/ContinueOrBackActions';
import NovaDivergenciaProgressBar from '../components/NovaDivergenciaProgressBar';
import StepperHeader from '../components/StepperHeader';
import { Step } from '../NovaDivergenciaStepHandler';
import {
  TipoAdicionalInfo,
  TipoAdicionalInfoAbreviacoes,
} from '../TipoDivergenciasInfoKey.';
import {
  TipoDivergenciaComponent,
  TipoDivergenciaComponentProps,
} from '../TiposDeDivergencias';
import { MaterialSelectItem } from './UseGetMateriais';
import { transforSelectItemToDivergenciaInfo } from './utils';
import {
  DivergenceContext,
  DivergenceContextType
} from '../../../../../context'

export interface TecidoProps extends TipoDivergenciaComponentProps {
  payload: { tecidos: MaterialSelectItem[] };
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      decimalScale={2}
      decimalSeparator=','
      getInputRef={inputRef}
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name.split('.').join(','),
            value: values.value.split('.').join(','),
          },
        });
      }}
      suffix={TipoAdicionalInfoAbreviacoes.QUANTIDADE_METROS}
    />
  );
}

const TecidoQuantidadeStepTemplate: FunctionComponent<
  TecidoProps & {
    nextStepComponent: TipoDivergenciaComponent;
    onBack: () => void;
    onSetStep: (step: Step) => void;
  }
> = ({ onSetStep, onBack, payload, nextStepComponent }) => {
  const [disabledButton, setDisabledButton] = useState(true);
  const { setAviamentos } = useContext<DivergenceContextType>(DivergenceContext)

  const checkDisableButton = () => {
    let disabled = false;
    payload.tecidos.forEach((tecido) => {
      if (!tecido.adicionalInfo || tecido.adicionalInfo.length === 0) {
        disabled = true;
      } else {
        tecido.adicionalInfo.forEach((info) => {
          if (!info.value) {
            disabled = true;
          }
        });
      }
    });
    setDisabledButton(disabled);
  };

  const handleContinue = () => {
    const nextPayload = {
      tecidos: payload.tecidos,
    };
    setAviamentos(null);
    setAviamentos({ aviamentos: nextPayload.tecidos });
    onSetStep({
      component: nextStepComponent,
      payload: nextPayload,
    });
  };

  const handleChange = (newValue: string, tecido: MaterialSelectItem) => {
    tecido.adicionalInfo = [
      {
        value: newValue,
        tipo: TipoAdicionalInfo.QUANTIDADE_METROS,
      },
    ];
    checkDisableButton();
  };
  const stepNumber = '3';
  const stepSubTitle =
    'Digite abaixo a quantidade (em metros) que precisa de cada tecido.';
  const stepTitle = 'Quanto de tecido precisa?';
  return (
    <React.Fragment>
      <NovaDivergenciaProgressBar value={50}></NovaDivergenciaProgressBar>
      <DialogContent
        className='backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth'
        style={{
          padding: '0 32px',
        }}
      >
        <StepperHeader
          stepNumber={stepNumber}
          stepSubTitle={stepSubTitle}
          stepTitle={stepTitle}
        ></StepperHeader>
        <div className='px16'>
          <form
            autoComplete='off'
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}
          >
            {payload.tecidos.map((tecido, index) => (
              <TextField
                className='mb16'
                key={`tecido-${index}`}
                label={tecido.text}
                fullWidth
                InputProps={{ inputComponent: NumberFormatCustom as any }}
                name='numberformat'
                onChange={(event) => {
                  handleChange(event.target.value, tecido);
                }}
                placeholder='Digite quantos metros você precisa a mais'
                variant='outlined'
              />
            ))}
          </form>
        </div>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className='divergenciaMaxWidth'
        style={{ padding: '30px 32px' }}
      >
        <ContinueOrBackActions
          continueMessage='Continuar'
          disabled={disabledButton}
          disabledMessage='Continuar'
          onBack={onBack}
          onContinue={handleContinue}
        />
      </DialogActions>
    </React.Fragment>
  );
};

export default TecidoQuantidadeStepTemplate;

export function buildMultiploMaterialInfoComponent(payload: {
  tecidos: MaterialSelectItem[];
}) {
  return [
    <MateriaisPopover
      materiais={payload.tecidos.map(transforSelectItemToDivergenciaInfo)}
    ></MateriaisPopover>,
  ];
}
