import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { FilterComponent } from '../../../support/components/inputs/FilterComponent';
import {
  destructorColecaoString,
  getColecaoAtual,
  useQueryString,
} from '../../../support/Utils';

const fornecedorQuery = loader('../queries/fornecedorSelectQuery.gql');
const colecaoAnoQuery = loader('../queries/colecaoAnoQuery.gql');
const responsavelQuery = loader('../queries/responsavelQuery.gql');
const canaisQuery = loader('../queries/canaisQuery.gql');
const todosOption = {
  title: 'Todos',
  value: '',
};
export const SimpleFilters: FunctionComponent = () => {
  let queryString = useQueryString();
  const colecaoString = queryString.get('colecao');
  let { anoColecao, grupoColecao, colecaoGrupo } = getColecaoAtual();

  if (colecaoString) {
    const destructor = destructorColecaoString(colecaoString);
    anoColecao = destructor.anoColecao;
    grupoColecao = destructor.grupoColecao;
  }

  const {
    data: fornecedorData = { findManyFornecedor: [] },
    loading: fornecedorLoading,
  } = useQuery(fornecedorQuery, {
    variables: {
      ano: anoColecao,
      grupo_colecao: grupoColecao,
    },
  });
  const fornecedorOptions = buildFornecedorOptions(
    fornecedorData.findManyFornecedor
  );

  const {
    data: colecaoAnoData = {
      findManyColecaoAno: [{ colecao_ano: colecaoGrupo }],
    },
    loading: colecaoAnoLoading,
  } = useQuery(colecaoAnoQuery);
  let colecaoAnoOptions = colecaoAnoData.findManyColecaoAno.map(
    (item: { colecao_ano: string }) => ({
      title: item.colecao_ano,
      value: item.colecao_ano,
    })
  );
  colecaoAnoOptions = [
    {
      title: 'Todas',
      value: 'todas',
    },
    ...colecaoAnoOptions,
  ];
  const {
    data: responsavelData = {
      findManyAssistente_Pcp: [],
    },
    loading: responsavelLoading,
  } = useQuery(responsavelQuery);
  const responsavelOptions = [todosOption];
  responsavelOptions.push(
    ...responsavelData.findManyAssistente_Pcp.map(
      (item: { id_assistente_pcp: number; responsavel_pcp: string }) => ({
        title: item.responsavel_pcp,
        value: item.id_assistente_pcp.toString(),
      })
    )
  );

  const {
    data: canaisData = {
      findManyProgramacaoDistinct: [],
    },
    loading: canaisLoading,
  } = useQuery(canaisQuery);
  const canaisOptions = [todosOption];
  canaisOptions.push(
    ...canaisData.findManyProgramacaoDistinct.map(
      (item: { tipo_programacao: string }) => ({
        title: item.tipo_programacao,
        value: item.tipo_programacao,
      })
    )
  );

  const pushToProducts = '/produtos';

  return (
    <Box paddingX={2} display='flex'>
      <FilterComponent
        boxWidth={150}
        defaultValue={{ title: colecaoGrupo, value: colecaoGrupo }}
        label='Coleção'
        loading={colecaoAnoLoading}
        options={colecaoAnoOptions}
        pushPathname={pushToProducts}
        queryKey='colecao'
      />
      <FilterComponent
        boxWidth={250}
        defaultValue={todosOption}
        label='Fornecedor'
        loading={fornecedorLoading}
        options={fornecedorOptions}
        pushPathname={pushToProducts}
        queryKey='fornecedor'
      />
      <FilterComponent
        boxWidth={220}
        defaultValue={todosOption}
        label='Canal'
        loading={canaisLoading}
        options={canaisOptions}
        pushPathname={pushToProducts}
        queryKey='canal'
      />
      <FilterComponent
        boxWidth={210}
        defaultValue={todosOption}
        label='Responsavel'
        loading={responsavelLoading}
        options={responsavelOptions}
        pushPathname={pushToProducts}
        queryKey='responsavel'
      />
    </Box>
  );
};

function buildFornecedorOptions(
  data: { nome: string; id_fornecedor: string; fornecedor: string }[]
) {
  const fornecedorOptions = [
    {
      title: 'Todos',
      value: '',
    },
  ];
  fornecedorOptions.push(
    ...data.map(
      (item: { nome: string; id_fornecedor: string; fornecedor: string }) => ({
        title: item.fornecedor?.trim() || item.nome?.trim(),
        value: item.id_fornecedor.toString(),
      })
    )
  );
  return fornecedorOptions;
}
