import AreaTipoDivergenciaEnum from "../../AreaTipoDivergenciaEnum";
import { useBuildFinalStepTemplate } from "../../template/FinalStepTemplate";
import useBuildSubmitHandler from "../../template/UseBuildSubmitHandler";
import TipoDivergenciasKey from "../../TipoDivergenciasKey";
import { TipoDivergenciaComponent } from "../../TiposDeDivergencias";

const ProblemaFtProducao: TipoDivergenciaComponent = ({
  onBack,
  onSetStep,
}) => {
  const key = TipoDivergenciasKey.PROBLEMA_FICHA_TECNICA_PRODUCAO;
  const { loadingSave, loadingTipoDivergencia, handleSubmit } =
    useBuildSubmitHandler(key, onSetStep);
  return useBuildFinalStepTemplate({
    area: AreaTipoDivergenciaEnum.CAD_ATELIER,
    loading: loadingSave || loadingTipoDivergencia,
    onBack,
    onSetStep: handleSubmit,
    tipoDivergencia: "Problema de ficha técnica de produção",
  });
};

export default ProblemaFtProducao;
