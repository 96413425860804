import React, { useContext } from 'react';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import {
  AviamentoProps,
  buildMultiploMaterialInfoComponent,
} from '../../../template/AviamentoQuntidadeStepTemplate';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import { createBackHandler } from '../../../template/utils';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import QualidadeDoAviamento from './QualidadeDoAviamento';
import { ListItem, ListItemText, ListItemIcon, IconButton, Typography } from '@material-ui/core';
import { PictureAsPdf as PdfIcon, InsertDriveFile as FileIcon } from '@material-ui/icons';
import { DivergenceContext, DivergenceContextType, FileProps } from '../../../../../../../context';
import { DELETE_FILE_MUTATION } from '../../../template/UploadStepTemplate';
import { useMutation } from '@apollo/client';

const QualidadeDoAviamentoFinalStep: TipoDivergenciaComponent<AviamentoProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {
  const onBackHandler = createBackHandler(onBack, QualidadeDoAviamento);
  
  const { files: filesDivergencia, truncateString, formatFileSize, setFiles, files } = useContext<DivergenceContextType>(DivergenceContext);
  const [deleteFile] = useMutation(DELETE_FILE_MUTATION);

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.CONTROLE_PCP,
      'Qualidade do aviamento'
    ),
    ...buildMultiploMaterialInfoComponent(payload),
    ...filesDivergencia.map((file: FileProps, index) => (
      <ListItem key={index} button>
        <ListItemIcon>
          {file.type === 'pdf' ? <PdfIcon /> : <FileIcon />}
        </ListItemIcon>
        <ListItemText
          primary={truncateString(file.name as string, 18)}
          secondary={formatFileSize(file.size ? file.size : 0)}
        />
        <IconButton edge="end">
          <Typography variant="caption" color="primary" onClick={() => {
            const variables = { variables: { filename: file.fileId } };
            
            deleteFile(variables)
             .then((message) => {
              setFiles(files.filter((fileOld) => fileOld.path !== file.path))
             })
             .catch((error) => console.log(error))
          }}>
            Deletar
          </Typography>
        </IconButton>
      </ListItem>
    ))
  ];
  const key = TipoDivergenciasKey.MP_QUALIDADE_DO_AVIAMENTO;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);
  return (
    <FinalStepTemplate
      infoMessage={
        <span>
          Envie uma foto em anexo para obter uma resposta mais objetiva.
        </span>
      }
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(
          step,
          payload.aviamentos.map((item) => ({
            tipo: TipoDivergenciasInfoKey.AVIAMENTO,
            value: item.id.toString(),
            filesDivergencia: filesDivergencia
          }))
        );
      }}
      progressValue={90}
      stepNumber={'3'}
      infoComponents={infoComponents}
    />
  );
};

export default QualidadeDoAviamentoFinalStep;
