import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql } from 'graphql.macro';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import SubgruposDivergencias from '../../../../../../domain/entities/subgruposDivergencias';
import {
  buildUserName,
  momentFormatShortDayWithHour,
} from '../../../../../support/Utils';
import { StatusEnum } from '../../components/DivergenciasTable';
import { Log, TipoLog } from '../DivergenciaDetailTypes';
const useStyles = makeStyles({
  alert: {
    alignItems: 'center',
    background: 'transparent',
    border: '2px solid #F4F3F3',
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '16px 24px',
  },
  icon: {
    color: '#949394',
  },
});

const LogInfo: FunctionComponent<{ log: Log }> = ({ log }) => {
  const classes = useStyles();

  const componentType: { [key: string]: Function } = {};
  componentType[TipoLog.MUDANCA_STATUS] = LogTipoMudancaStatus;
  componentType[
    TipoLog.FINALIZACAO_DIVERGENCIA
  ] = LogTipoFinalizacaoDivergencia;
  componentType[TipoLog.MUDANCA_TIPO] = LogTipoMudancaTipo;
  componentType[TipoLog.MUDANCA_SITUACAO] = LogSituacaoMudancaTipo;
  componentType[TipoLog.MUDANCA_SUBGRUPO_DIVERGENCIA] = LogSubgrupoDivergencia;
  componentType[TipoLog.MUDANCA_AREA_RESP_SECUNDARIA] = LogAreaRespSecundaria;
  componentType[TipoLog.MUDANCA_CATEGORIA_AREA_RESP_SECUNDARIA] = LogCategoriaAreaRespSecundaria;

  const Component = componentType[log.tipo];
  return (
    <div className={classes.alert}>
      <InfoOutlinedIcon className={classes.icon}></InfoOutlinedIcon>
      <div className='body2 medium neutral500 ml32'>
        <Component log={log}></Component>
      </div>
    </div>
  );
};

export default LogInfo;

type QueryResultData = {
  getAll_PLM_Colaborativo_Subgrupos_Divergencias: SubgruposDivergencias[];
};

const LogSubgrupoDivergencia: FunctionComponent<{ log: Log }> = (props) => {
  const getAllSubgruposDivergencias = gql`
    query {
      getAll_PLM_Colaborativo_Subgrupos_Divergencias {
        id_subgrupo_divergencia
        subgrupo
        area_tipo
        created_at
        updated_at
      }
    }
  `;

  const {
    data: queryResultData = {
      getAll_PLM_Colaborativo_Subgrupos_Divergencias: [],
    },
  } = useQuery<QueryResultData>(getAllSubgruposDivergencias);
  const subgruposDivergencias =
    queryResultData.getAll_PLM_Colaborativo_Subgrupos_Divergencias;

  const extractSubgroupBasedOnId = (
    subgrupos: SubgruposDivergencias[],
    idSubgrupo: number
  ) => {
    const subgrupo = subgrupos.find(
      (item) => item.id_subgrupo_divergencia === idSubgrupo
    );
    return subgrupo?.subgrupo || "";
  };

  return (
    <div>
      <span className="bold">{buildUserName(props.log.AlteradoPor.login)}</span>
      <span> alterou o status de "</span>
      <span className="bold">
        {extractSubgroupBasedOnId(subgruposDivergencias, Number(props.log.de))}"
      </span>
      <span> para "</span>
      <span className="bold">
        {extractSubgroupBasedOnId(
          subgruposDivergencias,
          Number(props.log.para)
        )}
        "
      </span>
      <span> em </span>
      <span className="bold">
        {moment(props.log.created_at).format(momentFormatShortDayWithHour)}
      </span>
    </div>
  );
};

const LogTipoMudancaStatus: FunctionComponent<{ log: Log }> = (props) => (
  <div>
    <span className='bold'>{buildUserName(props.log.AlteradoPor.login)}</span>
    <span> alterou o status de "</span>
    <span className='bold'>
      {StatusEnum[props.log.de as keyof typeof StatusEnum]}"
    </span>
    <span> para "</span>
    <span className='bold'>
      {StatusEnum[props.log.para as keyof typeof StatusEnum]}"
    </span>
    <span> em </span>
    <span className='bold'>
      {moment(props.log.created_at).format(momentFormatShortDayWithHour)}
    </span>
  </div>
);

const LogTipoFinalizacaoDivergencia: FunctionComponent<{ log: Log }> = (
  props
) => (
  <div>
    <span className='bold'>{buildUserName(props.log.AlteradoPor.login)}</span>
    <span> finalizou a divergência em </span>
    <span className='bold'>
      {moment(props.log.created_at).format(momentFormatShortDayWithHour)}
    </span>
  </div>
);

const query = gql`
  query getTipo($idTipo: Int!) {
    findOnePLM_Colaborativo_Tipos_Divergencias(
      where: { id_tipo_divergencia: $idTipo }
    ) {
      id_tipo_divergencia
      tipo
    }
  }
`;

type TipoType = {
  findOnePLM_Colaborativo_Tipos_Divergencias: {
    tipo: string;
  };
};
const LogTipoMudancaTipo: FunctionComponent<{ log: Log }> = (props) => {
  const { loading: loadingDe, data: dataDe } = useQuery<TipoType>(query, {
    variables: { idTipo: Number(props.log.de) },
  });

  const { loading: loadingPara, data: dataPara } = useQuery(query, {
    variables: { idTipo: Number(props.log.para) },
  });

  return (
    <div>
      <span className='bold'>{buildUserName(props.log.AlteradoPor.login)}</span>
      <span> alterou o tipo da divergência de "</span>
      <span className='bold'>
        {loadingDe ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataDe?.findOnePLM_Colaborativo_Tipos_Divergencias.tipo
        )}
        "
      </span>
      <span> para "</span>
      <span className='bold'>
        {loadingPara ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataPara?.findOnePLM_Colaborativo_Tipos_Divergencias.tipo
        )}
        "
      </span>
      <span> em </span>
      <span className='bold'>
        {moment(props.log.created_at).format(momentFormatShortDayWithHour)}
      </span>
    </div>
  );
};

//situação da divergencia
const querySituacao = gql`
  query getSituacao($idSituacao: Int!) {
    findOnePLM_Colaborativo_Situacao_Divergencias(
      id_situacao_divergencia: $idSituacao
    ) {
      id_situacao_divergencia
      situacao
    }
  }
`;

type TipoSituacao = {
  findOnePLM_Colaborativo_Situacao_Divergencias: {
    situacao: string;
  };
};
const LogSituacaoMudancaTipo: FunctionComponent<{ log: Log }> = (props) => {
  const { loading: loadingDe, data: dataDe } = useQuery<TipoSituacao>(querySituacao, {
    variables: { idSituacao: Number(props.log.de) },
  });

  const { loading: loadingPara, data: dataPara } = useQuery(querySituacao, {
    variables: { idSituacao: Number(props.log.para) },
  });

  return (
    <div>
      <span className='bold'>{buildUserName(props.log.AlteradoPor.login)}</span>
      <span> alterou a situação da divergência de "</span>
      <span className='bold'>
        {loadingDe ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataDe?.findOnePLM_Colaborativo_Situacao_Divergencias ? dataDe?.findOnePLM_Colaborativo_Situacao_Divergencias.situacao : ' '
        )}
        "
      </span>
      <span> para "</span>
      <span className='bold'>
        {loadingPara ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataPara?.findOnePLM_Colaborativo_Situacao_Divergencias ? dataPara?.findOnePLM_Colaborativo_Situacao_Divergencias.situacao : ' '

        )}
        "
      </span>
      <span> em </span>
      <span className='bold'>
        {moment(props.log.created_at).format(momentFormatShortDayWithHour)}
      </span>
    </div>
  );
};

//area responsavel secundaria da divergencia
const queryAreaRespSecundaria = gql`
  query getAreaRespSecundaria($idArea: Int!) {
    findOnePLM_Colaborativo_Area_Resp_Secundaria(
      id_area_secundaria_divergencia: $idArea
    ) {
      id_area
      area
    }
  }
`;

type AreaRespSecundaria = {
  findOnePLM_Colaborativo_Area_Resp_Secundaria: {
    area: string;
  };
};
const LogAreaRespSecundaria: FunctionComponent<{ log: Log }> = (props) => {
  const { loading: loadingDe, data: dataDe } = useQuery<AreaRespSecundaria>(queryAreaRespSecundaria, {
    variables: { idArea: Number(props.log.de) },
  });

  const { loading: loadingPara, data: dataPara } = useQuery(queryAreaRespSecundaria, {
    variables: { idArea: Number(props.log.para) },
  });

  return (
    <div>
      <span className='bold'>{buildUserName(props.log.AlteradoPor.login)}</span>
      <span> alterou a área responsável secundária da divergência de "</span>
      <span className='bold'>
        {loadingDe ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataDe?.findOnePLM_Colaborativo_Area_Resp_Secundaria ? dataDe?.findOnePLM_Colaborativo_Area_Resp_Secundaria.area : ' '
        )}
        "
      </span>
      <span> para "</span>
      <span className='bold'>
        {loadingPara ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataPara?.findOnePLM_Colaborativo_Area_Resp_Secundaria ? dataPara?.findOnePLM_Colaborativo_Area_Resp_Secundaria.area : ' '

        )}
        "
      </span>
      <span> em </span>
      <span className='bold'>
        {moment(props.log.created_at).format(momentFormatShortDayWithHour)}
      </span>
    </div>
  );
};

//categoria da area responsavel secundaria da divergencia
const queryCategoriaAreaRespSecundaria = gql`
  query getCategoriaAreaRespSecundaria($idCategoria: Int!) {
    findOnePLM_Colaborativo_Categoria_Area_Resp_Secundaria(
      id_categoria_area_secundaria_divergencia: $idCategoria
    ) {
      id_categoria
      categoria
    }
  }
`;

type CategoriaAreaRespSecundaria = {
  findOnePLM_Colaborativo_Categoria_Area_Resp_Secundaria: {
    categoria: string;
  };
};
const LogCategoriaAreaRespSecundaria: FunctionComponent<{ log: Log }> = (props) => {
  const { loading: loadingDe, data: dataDe } = useQuery<CategoriaAreaRespSecundaria>(queryCategoriaAreaRespSecundaria, {
    variables: { idCategoria: Number(props.log.de) },
  });

  const { loading: loadingPara, data: dataPara } = useQuery(queryCategoriaAreaRespSecundaria, {
    variables: { idCategoria: Number(props.log.para) },
  });

  return (
    <div>
      <span className='bold'>{buildUserName(props.log.AlteradoPor.login)}</span>
      <span> alterou a categoria da área responsável secundária da divergência de "</span>
      <span className='bold'>
        {loadingDe ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataDe?.findOnePLM_Colaborativo_Categoria_Area_Resp_Secundaria ? dataDe?.findOnePLM_Colaborativo_Categoria_Area_Resp_Secundaria.categoria : ' '
        )}
        "
      </span>
      <span> para "</span>
      <span className='bold'>
        {loadingPara ? (
          <span>
            <Skeleton
              style={{ display: 'inline-block' }}
              width={125}
            ></Skeleton>
          </span>
        ) : (
          dataPara?.findOnePLM_Colaborativo_Categoria_Area_Resp_Secundaria ? dataPara?.findOnePLM_Colaborativo_Categoria_Area_Resp_Secundaria.categoria : ' '

        )}
        "
      </span>
      <span> em </span>
      <span className='bold'>
        {moment(props.log.created_at).format(momentFormatShortDayWithHour)}
      </span>
    </div>
  );
};