// libs
import React from 'react'
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup
} from '@material-ui/core'

// shared
// import { TextStyles } from 'shared'

// assets
// import { ColorsOptions } from 'config'

interface YesNoRadioProps {
  label?: string
  name: string
  value?: boolean
  onChange?: (evt: boolean) => void
  error?: boolean
  helperText?: string
  onBlur?: any
  // labelColor?: ColorsOptions
  labelBolder?: boolean
  [propName: string]: any
}

function YesNoRadio({
  // name,
  label,
  value,
  onChange,
  helperText,
  error = false,
  labelColor = 'tableText',
  labelBolder = false,
  // onBlur,
  ...props
}: YesNoRadioProps) {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const evtValue = evt.target.value
    if (onChange) onChange(evtValue === 'yes')
  }

  return (
    <FormControl
      component="fieldset"
      fullWidth
      error={error}
      {...props}
    >
      {label ? (
        <FormLabel
          component="legend"
          style={{
            color: labelColor,
            fontSize: 14,
            fontWeight: labelBolder ? 7 : undefined
          }}
        >
          {label}
        </FormLabel>
      ) : null}
      <RadioGroup
        row
        aria-label="gender"
        value={value !== undefined ? (value ? 'yes' : 'no') : null}
        onChange={handleChange}
        {...props}
        data-testid="test-ynRadioGroup"
      >
        <FormControlLabel
          value="yes"
          control={<Radio {...props} />}
          label="Sim"
          {...props}

        />
        <FormControlLabel
          value="no"
          control={<Radio {...props} />}
          label="Não"
          {...props}
        />
      </RadioGroup>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}

export { YesNoRadio }
