import { gql, useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useQueryString } from '../support/Utils';

const mutation = gql`
  mutation createDivergenciasExcelReport(
    $cor: String
    $status: StatusDivergencia
    $filtered: FilteredInput
  ) {
    createDivergenciasExcelReport(cor: $cor, status: $status , filtered: $filtered) {
      fileId
    }
  }
`;

const ExportExcelButton: FunctionComponent = () => {
  const [generate, { loading }] = useMutation(mutation);
  const queryString = useQueryString();

  return (
    <Button
      className='backgroundPrimary500'
      style={{
        border: 'none',
        height: '',
        width: '120px',
      }}
      onClick={() => {
        if (loading) {
          return;
        }

        const idFornecedor = queryString.get('fornecedor') ?? null
        const idResponsavel = queryString.get('responsavelId') ?? null
        const idSituacaoDivergencia = queryString.get('idSituacaoDivergencia') ?? null
        const idTipoDivergencia = queryString.get('idTipoDivergencia') ?? null
        const status = queryString.get('status');
        const cor = queryString.get('cor');
        const canal =  queryString.get('canal') ?? null;

        generate({
          variables: {
            cor: cor !== null && cor.trim() !== '' ? cor : null,
            status: status !== null && status.trim() !== '' ? status : null,
            filtered: {
              id_fornecedor: parseInt(idFornecedor!),
              canal: canal && canal.trim() !== '' ? canal : null,
              id_responsavel: parseInt(idResponsavel!),
              idSituacaoDivergencia: idSituacaoDivergencia,
              idTipoDivergencia: idTipoDivergencia
            }
          },
        }).then(({ data }) => {
          const fileId = data.createDivergenciasExcelReport.fileId;
          const a = document.createElement('a');
          a.rel = 'noopener';
          a.href = `${process.env.REACT_APP_API_GRAPHQL_URL?.replace(
            '/graphql',
            ''
          )}/download/${fileId}`;
          a.target = '_blank';

          a.click();
        });
      }}
    >
      {loading ? (
        <CircularProgress color='inherit' size={25} />
      ) : (
        <span className='primary100'>Exportar</span>
      )}
    </Button>
  );
};
export default ExportExcelButton;
