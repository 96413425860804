import React from 'react';
import MateriaisPopover from '../../../../detail/components/MateriaisPopover';
import AreaTipoDivergenciaEnum from '../../../AreaTipoDivergenciaEnum';
import FinalStepTemplate, {
  useBuildDefaultInfoComponent,
} from '../../../template/FinalStepTemplate';
import useBuildSubmitHandler from '../../../template/UseBuildSubmitHandler';
import {
  transforSelectItemToDivergenciaInfo,
} from '../../../template/utils';
import TipoDivergenciasKey from '../../../TipoDivergenciasKey';
import {
  TipoDivergenciaComponent
} from '../../../TiposDeDivergencias';
import { CancelamentoOPFaltaMPObservacaoStep  } from './CancelamentoOPFaltaMPObservacaoStep';
import LabelValueWithSubtitle from '../../../components/LabelValueWithSubtitle';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { PayloadMateriaisProblemProps } from '../substituicao-mp/SubstituicaoMPObservacaoStep';

const CancelamentoOPFaltaMPFinalStep: TipoDivergenciaComponent<PayloadMateriaisProblemProps> = ({
  onSetStep,
  onBack,
  payload,
}) => {

  const onBackHandler = () => {
    onBack({
      component: CancelamentoOPFaltaMPObservacaoStep as TipoDivergenciaComponent,
      payload: {
        materiais: payload.materiais,
      },
    });
  };

  const infoComponents = [
    ...useBuildDefaultInfoComponent(
      AreaTipoDivergenciaEnum.AJUSTE_MATERIA_PRIMA,
      'Cancelamento de ordem de produção por falta de mp'
    ),
    <MateriaisPopover
      materiais={payload.materiais.map(transforSelectItemToDivergenciaInfo)}
    ></MateriaisPopover>,
    <LabelValueWithSubtitle
      label='Descrição do problema'
      value={`${payload.problemDescription}`}
      subtitle={''}
    />,
  ];

  const key = TipoDivergenciasKey.CANCELAMENTO_DE_OP_POR_FALTA_DE_MP;
  const {
    loadingSave,
    loadingTipoDivergencia,
    handleSubmit,
  } = useBuildSubmitHandler(key, onSetStep);

  return (
    <FinalStepTemplate
      loading={loadingSave || loadingTipoDivergencia}
      onBack={onBackHandler}
      onSetStep={(step) => {
        handleSubmit(
          step, [
            ...payload.materiais.map((item) => ({
              tipo: item.tipo,
              value: item.id.toString(),
              material: item.material
            })),
            {
              tipo: TipoDivergenciasInfoKey.AJUSTE_MP,
              value: payload.problemDescription,
            }
          ]
        );
      }}
      progressValue={90}
      stepNumber={'4'}
      infoComponents={infoComponents}
    />
  );
};

export default CancelamentoOPFaltaMPFinalStep;
