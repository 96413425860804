import React, { FunctionComponent } from 'react';
import ReactHtmlParser from 'react-html-parser';
import sanitizeHtml from 'sanitize-html';

const MensagemValue: FunctionComponent<{ mensagem: string }> = ({
  mensagem,
}) => {
  const regex = /(@\[.*?\))/gm;

  const splited = mensagem.split(new RegExp(regex));

  return (
    <p>
      {splited.map((part, index) => {
        const regexTest = new RegExp(regex);
        if (!regexTest.test(part)) {
          const withBreakLines = part.split('\n').join('<br/>');
          const clean = sanitizeHtml(withBreakLines, {
            allowedTags: ['br'],
          });
          return <span key={`part${index}`}>{ReactHtmlParser(clean)}</span>;
        }

        const regexCapture = new RegExp(/\((.*)\)/gm);

        const splitUsername = regexCapture.exec(part);
        let username = '';
        if (splitUsername) {
          username = splitUsername[1];
        }
        return (
          <span key={`part${index}`} style={{ color: '#97877A' }}>
            @{username}
          </span>
        );
      })}
    </p>
  );
};

export default MensagemValue;
