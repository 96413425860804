import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
} from '@material-ui/core';
import IconRight from '@material-ui/icons/ChevronRight';
import { loader } from 'graphql.macro';
import _get from 'lodash.get';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { BackToProductList } from '../../../components/BackToProductList';
import PaginationEnum from '../../../support/enums/PaginationEnum';
import {
  destructorColecaoString,
  getColecaoAtual,
  loaderTheme,
  useQueryString,
} from '../../../support/Utils';
import { coalesce } from '../coalesce';
import { ProductPagination } from '../components/ProductPagination';
import { LabelDateCell } from './LabelDateCell';
import { LabelValueCell } from './LabelValueCell';
import { ProductInfoTableColumn } from './ProductInfoTableColumn';

const query = loader('../queries/productListQuery.gql');

function buildWhere(queryString: URLSearchParams) {
  const searched = queryString.get('search');
  const colecao = queryString.get('colecao');
  const fornecedor = queryString.get('fornecedor');
  const canal = queryString.get('canal');
  const resposavel = queryString.get('responsavel');

  let fornecedorWhere = null;
  if (fornecedor) {
    fornecedorWhere = parseInt(fornecedor, 10);
  }

  let responsavelWhere = null;
  if (resposavel) {
    responsavelWhere = parseInt(resposavel, 10);
  }

  let searchWhere = null;
  if (searched) {
    searchWhere = searched;
  }

  let canalWhere = null;
  if (canal) {
    canalWhere = canal;
  }
  let ano,
    grupo = null;
  let { anoColecao, grupoColecao } = getColecaoAtual();
  if (colecao) {
    const destructor = destructorColecaoString(colecao);
    anoColecao = destructor.anoColecao;
    grupoColecao = destructor.grupoColecao;
  }

  if (colecao !== 'todas') {
    ano = anoColecao;
    grupo = grupoColecao;
  }

  return {
    anoColecao: ano,
    canal: canalWhere,
    colecao: grupo,
    fornecedorId: fornecedorWhere,
    responsavelId: responsavelWhere,
    search: searchWhere,
  };
}

function getDiasProducaoParada(row: any): String {
  const diasParada = _get(row, 'data_producao_parada');
  if (diasParada) {
    const parse = moment.parseZone(diasParada);
    const now = moment();
    const diffDays = parse.diff(now, 'day');

    return `há ${Math.abs(diffDays)} dias`;
  }
  return '';
}

const ProductTable: FunctionComponent<RouteComponentProps> = (props) => {
  let queryString = useQueryString();

  const page = parseInt(queryString.get('page') || '1', 10);
  const where = buildWhere(queryString);

  const { data, loading } = useQuery(query, {
    variables: {
      skip: (page - 1) * PaginationEnum.perPage,
      ...where,
    },
  });

  let rows = [];
  let totalRows = 0;
  if (data) {
    rows = data.listProducao_Prog_Prod;
    totalRows = data.listProducao_Prog_ProdCount;
  }

  const headerClass = 'caption bold neutral500 uppercase';

  return !loading && !rows.length ? (
    <Grid container item justify='center'>
      <Grid item className='text-center'>
        <h4 className='headline neutral800' style={{ marginBottom: '24px' }}>
          Nenhum resultado foi encontrado.
        </h4>
        <BackToProductList hideArrow></BackToProductList>
      </Grid>
    </Grid>
  ) : (
    <Grid container item>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow className='tableHeader'>
            <TableCell className={headerClass}>Produto</TableCell>
            <TableCell className={headerClass}>OP</TableCell>
            <TableCell className={headerClass}>Fornecedor</TableCell>
            <TableCell className={headerClass}>Categoria</TableCell>
            <TableCell className={headerClass}>Data alvo</TableCell>
            <TableCell className={headerClass}>Entrega</TableCell>
            <TableCell className={headerClass}>Status</TableCell>
            <TableCell className={headerClass}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={7}>
                <Box width='100%' display='flex' justifyContent='center'>
                  <ThemeProvider theme={loaderTheme}>
                    <CircularProgress color='primary'></CircularProgress>
                  </ThemeProvider>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row: any) => {
              const productUrl = `/produtos/${row.id_producao_prog_prod}/${row.Producao_PPP_OP[0].id_producao_ppp_op}/divergencias`;
              return (
                <TableRow
                  key={row.id_producao_prog_prod}
                  className='tableRowHover pointer'
                  onClickCapture={() => {
                    props.history.push(productUrl);
                  }}
                >
                  <TableCell>
                    <ProductInfoTableColumn
                      color={_get(
                        row,
                        'Producao_PPP_OP[0].FarolOneBeat.buffer_penetration_color',
                        'Light Gray'
                      )}
                      image={_get(
                        row,
                        'ProdutoEstiloArquivos[0].nome_arquivo'
                      )}
                      subTitle={_get(row, 'Produto_Cor.desc_cor_produto')}
                      title={_get(row, 'Produto_Cor.Produto.desc_produto')}
                      number={_get(row, 'Produto_Cor.Produto.produto')}
                    />
                  </TableCell>
                  <TableCell>
                    <LabelValueCell
                      value={coalesce(
                        row,
                        'Producao_PPP_OP[0].Ordem_Producao.ordem_producao'
                      )}
                      label='OP'
                    ></LabelValueCell>
                  </TableCell>
                  <TableCell>
                    <LabelValueCell
                      value={
                        coalesce(
                          row,
                          'Producao_PPP_OP[0].Ordem_Producao.Fornecedor.fornecedor'
                        ) ||
                        coalesce(
                          row,
                          'Producao_PPP_OP[0].Ordem_Producao.Fornecedor.nome'
                        )
                      }
                      label='Fornecedor'
                    />
                  </TableCell>
                  <TableCell>
                    <LabelValueCell
                      value={coalesce(row, 'categoria_computed')}
                      label='Categoria'
                    ></LabelValueCell>
                  </TableCell>
                  <TableCell>
                    <LabelDateCell
                      value={_get(row, 'data_alvo_computed', null)}
                      label='Data Alvo'
                    ></LabelDateCell>
                  </TableCell>
                  <TableCell>
                    <LabelDateCell
                      value={_get(
                        row,
                        'Producao_PPP_OP[0].Ordem_Producao.os_entrega_ajustada',
                        null
                      )}
                      label='Entrega'
                    ></LabelDateCell>
                  </TableCell>
                  <TableCell>
                    <LabelValueCell
                      value={getStatusProducao(row)}
                      label={getDiasProducaoParada(row)}
                    ></LabelValueCell>
                  </TableCell>
                  <TableCell>
                    <Link to={productUrl}>
                      <IconRight className='neutral300'></IconRight>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      {!loading && (
        <ProductPagination totalRows={totalRows}></ProductPagination>
      )}
    </Grid>
  );
};

export default withRouter(ProductTable);

function getStatusProducao(row: any): String | null | undefined {
  const status = coalesce(row, 'Status_Producao.status_producao');
  const exploded = status.split('.');
  const first = exploded[0];
  const second = exploded[1];

  return `${first.replace(/[0-9]/g, '')} ${second || ''}`;
}
