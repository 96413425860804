import React from 'react';
import SelectStepTemplate from '../../../template/SelectStepTemplate';
import useGetMateriais, {
  buildOutrosTecidos,
} from '../../../template/UseGetMateriais';
import TipoDivergenciasInfoKey from '../../../TipoDivergenciasInfoKey.';
import { TipoDivergenciaComponent } from '../../../TiposDeDivergencias';
import AjusteDeGastoTecidoQuantidadeStep from './AjusteDeGastoTecidoQuantidadeStep';

const AjusteDeGastoTecido: TipoDivergenciaComponent = ({
  onSetStep,
  onBack,
}) => {
  const { loading, materiais } = useGetMateriais(
    TipoDivergenciasInfoKey.TECIDO
  );
  const newMateriais = [...materiais, buildOutrosTecidos()];

  const stepNumber = '2';
  const stepSubTitle = 'Selecione o tecido que precisa de ajuste de gasto.';
  const stepTitle = 'Qual tecido precisa de ajuste de gasto?';
  return (
    <SelectStepTemplate
      data={newMateriais}
      loading={loading}
      nextStepComponent={
        AjusteDeGastoTecidoQuantidadeStep as TipoDivergenciaComponent
      }
      onBack={onBack}
      onSetStep={onSetStep}
      payloadKey='tecidos'
      stepNumber={stepNumber}
      stepSubTitle={stepSubTitle}
      stepTitle={stepTitle}
    />
  );
};

export default AjusteDeGastoTecido;
