import {
  Box,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { buildDivergenciaNumber, loaderTheme } from '../../../../support/Utils';
import { DivergenciaRow } from '../Divergencia';
import ColaboradorResponsavelComponent from './ColaboradorResponsavelComponent';

export enum StatusEnum {
  NOVO = 'Novo',
  EM_ANDAMENTO = 'Em andamento',
  AGUARDANDO_FINALIZACAO = 'Aguardando finalização',
  FINALIZADO = 'Finalizado',
}

const useStyles = makeStyles({
  default: {
    borderRadius: '2px',
    padding: '8px 16px',
    whiteSpace: 'nowrap',
  },
  NOVO: {
    background: 'rgba(7, 198, 4, 0.05)',
    color: '#07c604',
  },
  EM_ANDAMENTO: {
    background: 'rgba(255, 184, 0, 0.05)',
    color: '#FFB800',
  },
  AGUARDANDO_FINALIZACAO: {
    background: 'rgba(7, 198, 4, 0.05)',
    color: '#07C604',
  },
  FINALIZADO: {
    background: '#F4F3F3',
    color: '#949394',
  },
});

export const StatusColumn: FunctionComponent<{ status: string }> = (props) => {
  const classes = useStyles();
  const statusClass = clsx(
    'caption',
    'bold',
    classes.default,
    classes[props.status as keyof typeof classes]
  );

  return (
    <span className={statusClass}>
      {StatusEnum[props.status as keyof typeof StatusEnum]}
    </span>
  );
};

const headerClass = 'caption bold neutral500 uppercase';

const DivergenciasList: FunctionComponent<
  RouteComponentProps & {
    rows: DivergenciaRow[];
    loading?: boolean;
  }
> = ({ rows, history, loading = false }) => {
  const subtitleClass = 'caption bold neutral500';
  const titleClass = 'body2 medium neutral800';

  return (
    <Table aria-label='simple table'>
      <TableHead>
        <TableRow className='tableHeader'>
          <TableCell className={headerClass}>TIPO E Nº CHAMADO</TableCell>
          <TableCell className={headerClass}>STATUS</TableCell>
          <TableCell className={headerClass}>RESPONSÁVEL</TableCell>
          <TableCell className={headerClass}>DATA DE CRIAÇÃO</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className='backgroundNeutral50'>
        {loading && (
          <TableRow>
            <TableCell colSpan={4} style={{ padding: 0 }}>
              <ThemeProvider theme={loaderTheme}>
                <LinearProgress />
              </ThemeProvider>
            </TableCell>
          </TableRow>
        )}
        {rows.map((row) => (
          <TableRow
            key={row.number}
            className='tableRowHover pointer'
            onClickCapture={() => {
              history.push({
                search: `open=${row.number}`,
              });
            }}
          >
            <TableCell width='25%'>
              <div className={titleClass}>{row.type}</div>
              <div className={subtitleClass}>
                {buildDivergenciaNumber(row.number)}
              </div>
            </TableCell>
            <TableCell width='25%'>
              <StatusColumn status={row.status}></StatusColumn>
            </TableCell>
            <TableCell width='25%'>
              <ColaboradorResponsavelComponent
                hideLabel
                nome={row.responsavel}
              />
            </TableCell>
            <TableCell width='25%'>{row.createdAt}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const DivergenciasListWithRouter = withRouter(DivergenciasList);

const DivergenciasTable: FunctionComponent<{
  rows: DivergenciaRow[];
  loading?: boolean;
}> = ({ rows, loading }) => {
  return (
    <div>
      <DivergenciasListWithRouter
        loading={loading}
        rows={rows}
      ></DivergenciasListWithRouter>
    </div>
  );
};

export default DivergenciasTable;

export const DivergenciasTableLoading: FunctionComponent = () => (
  <Table aria-label='simple table'>
    <TableHead>
      <TableRow className='tableHeader'>
        <TableCell className={headerClass}>TIPO E TICKET</TableCell>
        <TableCell className={headerClass}>STATUS</TableCell>
        <TableCell className={headerClass}>RESPONSÁVEL</TableCell>
        <TableCell className={headerClass}>DATA DE CRIAÇÃO</TableCell>
      </TableRow>
    </TableHead>
    <TableBody className='backgroundNeutral50'>
      <TableRow>
        <TableCell colSpan={4}>
          <Box width='100%' display='flex' justifyContent='center'>
            <ThemeProvider theme={loaderTheme}>
              <CircularProgress color='primary'></CircularProgress>
            </ThemeProvider>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);
